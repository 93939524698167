@charset "UTF-8";
/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import "../node_modules/font-awesome/css/font-awesome.css";
@import "../node_modules/dragula/dist/dragula.min.css";
@import "../node_modules/ngx-toastr/toastr.css";
@import "../node_modules/leaflet/dist/leaflet.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
@font-face {
  font-family: "MoonHouse";
  src: url("./assets/fonts/moonhouse.eot");
  src: url("./assets/fonts/moonhouse.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/moonhouse.ttf") format("truetype");
}
/**** General ****/
/* perfect-scrollbar */
.ps {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
  position: relative;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 7px;
  bottom: 0px;
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 7px;
  right: 0;
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
  background-color: #eee;
  opacity: 0.9;
}

.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 4px;
  bottom: 1px;
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 4px;
  right: 1px;
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x {
  background-color: #999;
  height: 7px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
  background-color: #999;
  width: 7px;
}

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
.ng2-smart-pagination-nav {
  text-align: center;
  margin-top: 20px;
  width: 100%;
}

.ng2-smart-pagination .page-link-next,
.ng2-smart-pagination .page-link-prev {
  font-size: 12px !important;
}

.datatable-checkbox {
  padding: 0;
}

.datatable-checkbox input[type=checkbox]:before {
  border-color: #999;
}

.datatable-checkbox input[type=checkbox]:checked:before {
  border-color: #378D3B;
}

.sebm-google-map-container {
  height: 500px;
}

#leaflet-map {
  width: 100%;
  height: 500px;
}

.cal-event-action {
  color: #f5f5f5;
}

.widget-controls {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  padding: 1rem 1.25rem;
  font-size: 14px;
}
.widget-controls a {
  font-size: 12px;
  margin-left: 7px;
  line-height: 1;
  vertical-align: top;
  display: inline-block;
  outline: none;
}
.widget-controls a.dropdown-toggle:after {
  display: none;
}
.widget-controls a .fa-times {
  font-size: 14px;
}
.widget-controls ul.dropdown-menu {
  min-width: 9rem;
  padding: 0;
  border-radius: 0;
}
.widget-controls ul.dropdown-menu li {
  padding: 4px;
  overflow: hidden;
}
.widget-controls ul.dropdown-menu li a {
  color: #000;
  font-size: 13px;
  width: 100%;
}
.widget-controls ul.dropdown-menu li:hover {
  background-color: blue;
}
.widget-controls ul.dropdown-menu li:hover a {
  color: #555;
}

.custom-multiselect .dropdown .input-group-addon {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  color: #495057;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  line-height: 1.4;
}
.custom-multiselect .dropdown .input-group-btn > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

html, body {
  height: 100%;
  font-size: 14px;
}

* {
  margin: 0;
  padding: 0;
}

a {
  color: #3E50B4;
}

a:hover, a:focus {
  text-decoration: none;
  color: #2F3E9E;
}

input {
  box-shadow: none !important;
}

.app {
  background-color: #f5f5f5;
  height: 100%;
}
.app .wrapper {
  height: 100%;
}
.app.light {
  /*Card*/
  /*Text*/
  /*Breadcrumb*/
  /*Buttons*/
  /*Badge*/
  /*Alerts*/
  /* MODALS */
  /*BG*/
  /*Progress*/
  /*Tabs*/
  /*List group*/
  /*DropDown*/
  /* actual dropdown animation */
  /*Forms*/
  /*Tables*/
  /*Popovers*/
  /*Top Navbar*/
  /*Side-Chat*/
  /*Sidebar*/
  /*Horizontal-menu*/
  /*Footer*/
  /*Login & register & error*/
  /*Global classes*/
  /*Libs */
}
.app.light .card .card-header {
  background-color: #f3f3f3;
}
.app.light .card .card-header.transparent {
  background-color: transparent;
}
.app.light .card.card-primary {
  background-color: #3E50B4;
  border-color: #2F3E9E;
}
.app.light .card.card-primary .card-header {
  background-color: #2F3E9E;
}
.app.light .card.card-primary .card-footer {
  background-color: rgba(47, 62, 158, 0.5);
}
.app.light .card.card-success {
  background-color: #4BAE4F;
  border-color: #378D3B;
}
.app.light .card.card-success .card-header {
  background-color: #378D3B;
}
.app.light .card.card-success .card-footer {
  background-color: rgba(55, 141, 59, 0.5);
}
.app.light .card.card-info {
  background-color: #00BBD3;
  border-color: #0096A6;
}
.app.light .card.card-info .card-header {
  background-color: #0096A6;
}
.app.light .card.card-info .card-footer {
  background-color: rgba(0, 150, 166, 0.5);
}
.app.light .card.card-warning {
  background-color: #FE9700;
  border-color: #F47B00;
}
.app.light .card.card-warning .card-header {
  background-color: #F47B00;
}
.app.light .card.card-warning .card-footer {
  background-color: rgba(244, 123, 0, 0.5);
}
.app.light .card.card-danger {
  background-color: #F34235;
  border-color: #D22E2E;
}
.app.light .card.card-danger .card-header {
  background-color: #D22E2E;
}
.app.light .card.card-danger .card-footer {
  background-color: rgba(210, 46, 46, 0.5);
}
.app.light .card.card-outline-primary {
  background-color: transparent;
  border: 1px solid #3E50B4;
}
.app.light .card.card-outline-success {
  background-color: transparent;
  border: 1px solid #4BAE4F;
}
.app.light .card.card-outline-info {
  background-color: transparent;
  border: 1px solid #00BBD3;
}
.app.light .card.card-outline-warning {
  background-color: transparent;
  border: 1px solid #FE9700;
}
.app.light .card.card-outline-danger {
  background-color: transparent;
  border: 1px solid #F34235;
}
.app.light .card-img-overlay {
  color: #262626;
}
.app.light .card-inverse .card-link,
.app.light .card-inverse .card-text,
.app.light .card-inverse .card-subtitle,
.app.light .card-inverse .card-blockquote .blockquote-footer {
  color: #f5f5f5;
}
.app.light .overlay-primary {
  background-color: rgba(62, 80, 180, 0.8);
}
.app.light .overlay-success {
  background-color: rgba(75, 174, 79, 0.8);
}
.app.light .overlay-info {
  background-color: rgba(0, 187, 211, 0.8);
}
.app.light .overlay-warning {
  background-color: rgba(254, 151, 0, 0.8);
}
.app.light .overlay-danger {
  background-color: rgba(243, 66, 53, 0.8);
}
.app.light .text-primary {
  color: #3E50B4 !important;
}
.app.light a.text-primary:focus, .app.light a.text-primary:hover {
  color: #2F3E9E !important;
}
.app.light .text-success {
  color: #4BAE4F !important;
}
.app.light a.text-success:focus, .app.light a.text-success:hover {
  color: #378D3B !important;
}
.app.light .text-info {
  color: #00BBD3 !important;
}
.app.light a.text-info:focus, .app.light a.text-info:hover {
  color: #0096A6 !important;
}
.app.light .text-warning {
  color: #FE9700 !important;
}
.app.light a.text-warning:focus, .app.light a.text-warning:hover {
  color: #F47B00 !important;
}
.app.light .text-danger {
  color: #F34235 !important;
}
.app.light a.text-danger:focus, .app.light a.text-danger:hover {
  color: #D22E2E !important;
}
.app.light .text-gray {
  color: #9D9D9D !important;
}
.app.light a.text-gray:focus, .app.light a.text-gray:hover {
  color: #606060 !important;
}
.app.light .text-inverse {
  color: #292B2C !important;
}
.app.light a.text-inverse:focus, .app.light a.text-inverse:hover {
  color: #1C1E1F !important;
}
.app.light .breadcrumb {
  padding: 0.5rem 1rem;
}
.app.light .breadcrumb a {
  color: #3E50B4;
}
.app.light .btn-primary {
  color: #fff;
  background-color: #3E50B4;
  border-color: #3E50B4;
}
.app.light .btn-primary:hover, .app.light .btn-primary:active, .app.light .btn-primary.active {
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.light .btn-primary:focus, .app.light .btn-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.light .show > .btn-primary.dropdown-toggle {
  background-color: #2F3E9E;
}
.app.light .btn-success {
  color: #fff;
  background-color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.light .btn-success:hover, .app.light .btn-success:active, .app.light .btn-success.active {
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.light .btn-success:focus, .app.light .btn-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.light .show > .btn-success.dropdown-toggle {
  background-color: #378D3B;
}
.app.light .btn-info {
  color: #fff;
  background-color: #00BBD3;
  border-color: #00BBD3;
}
.app.light .btn-info:hover, .app.light .btn-info:active, .app.light .btn-info.active {
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.light .btn-info:focus, .app.light .btn-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.light .show > .btn-info.dropdown-toggle {
  background-color: #0096A6;
}
.app.light .btn-warning {
  color: #fff;
  background-color: #FE9700;
  border-color: #FE9700;
}
.app.light .btn-warning:hover, .app.light .btn-warning:active, .app.light .btn-warning.active {
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.light .btn-warning:focus, .app.light .btn-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.light .show > .btn-warning.dropdown-toggle {
  background-color: #F47B00;
}
.app.light .btn-danger {
  color: #fff;
  background-color: #F34235;
  border-color: #F34235;
}
.app.light .btn-danger:hover, .app.light .btn-danger:active, .app.light .btn-danger.active {
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.light .btn-danger:focus, .app.light .btn-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.light .show > .btn-danger.dropdown-toggle {
  background-color: #D22E2E;
}
.app.light .btn-gray {
  color: #fff;
  background-color: #9D9D9D;
}
.app.light .btn-gray:hover, .app.light .btn-gray:active, .app.light .btn-gray.active {
  background-color: #606060;
}
.app.light .btn-gray:focus, .app.light .btn-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.light .show > .btn-gray.dropdown-toggle {
  background-color: #606060;
}
.app.light .btn-inverse {
  color: #fff;
  background-color: #292B2C;
}
.app.light .btn-inverse:hover, .app.light .btn-inverse:active, .app.light .btn-inverse.active {
  background-color: #1C1E1F;
}
.app.light .btn-inverse:focus, .app.light .btn-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.light .show > .btn-inverse.dropdown-toggle {
  background-color: #1C1E1F;
}
.app.light .btn-rounded {
  border-radius: 5rem;
}
.app.light .btn-outline-primary {
  color: #3E50B4;
  border-color: #3E50B4;
}
.app.light .btn-outline-primary:hover, .app.light .btn-outline-primary:active, .app.light .btn-outline-primary.active {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.light .btn-outline-primary:focus, .app.light .btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.light .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.light .btn-outline-success {
  color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.light .btn-outline-success:hover, .app.light .btn-outline-success:active, .app.light .btn-outline-success.active {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.light .btn-outline-success:focus, .app.light .btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.light .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.light .btn-outline-info {
  color: #00BBD3;
  border-color: #00BBD3;
}
.app.light .btn-outline-info:hover, .app.light .btn-outline-info:active, .app.light .btn-outline-info.active {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.light .btn-outline-info:focus, .app.light .btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.light .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.light .btn-outline-warning {
  color: #FE9700;
  border-color: #FE9700;
}
.app.light .btn-outline-warning:hover, .app.light .btn-outline-warning:active, .app.light .btn-outline-warning.active {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.light .btn-outline-warning:focus, .app.light .btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.light .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.light .btn-outline-danger {
  color: #F34235;
  border-color: #F34235;
}
.app.light .btn-outline-danger:hover, .app.light .btn-outline-danger:active, .app.light .btn-outline-danger.active {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.light .btn-outline-danger:focus, .app.light .btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.light .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.light .btn-outline-gray {
  color: #9D9D9D;
  border-color: #9D9D9D;
  background-color: transparent;
}
.app.light .btn-outline-gray:hover, .app.light .btn-outline-gray:active, .app.light .btn-outline-gray.active {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.light .btn-outline-gray:focus, .app.light .btn-outline-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.light .show > .btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.light .btn-outline-inverse {
  color: #292B2C;
  border-color: #292B2C;
  background-color: transparent;
}
.app.light .btn-outline-inverse:hover, .app.light .btn-outline-inverse:active, .app.light .btn-outline-inverse.active {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.light .btn-outline-inverse:focus, .app.light .btn-outline-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.light .show > .btn-outline-inverse.dropdown-toggle {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.light .btn-link {
  border: 1px solid transparent;
}
.app.light .badge {
  padding: 4px 6px;
}
.app.light .badge-primary {
  background-color: #3E50B4;
}
.app.light .badge-success {
  background-color: #4BAE4F;
}
.app.light .badge-info {
  background-color: #00BBD3;
}
.app.light .badge-warning {
  background-color: #FE9700;
}
.app.light .badge-danger {
  background-color: #F34235;
}
.app.light .badge-gray {
  background-color: #9D9D9D;
}
.app.light .badge-white {
  background-color: #fff;
}
.app.light .alert-primary {
  background-color: #b1c3ff;
  border-color: #97a9ff;
  color: #3E50B4;
}
.app.light .alert-success {
  background-color: #beffc2;
  border-color: #8bee8f;
  color: #4BAE4F;
}
.app.light .alert-info {
  background-color: #a6ffff;
  border-color: #40fbff;
  color: #00BBD3;
}
.app.light .alert-warning {
  background-color: #ffffa6;
  border-color: #fff059;
  color: #FE9700;
}
.app.light .alert-danger {
  background-color: #ffb5a8;
  border-color: #ff9b8e;
  color: #F34235;
}
.app.light .alert-gray {
  background-color: #f3f3f3;
  border-color: #dddddd;
  color: #9D9D9D;
}
.app.light .modal {
  z-index: 999999;
  padding-top: 5%;
}
.app.light .modal-dialog .modal-content .modal-header {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  padding: 10px 15px;
}
.app.light .modal-dialog .modal-content .modal-header.modal-primary {
  color: #fff;
  background-color: #3E50B4;
}
.app.light .modal-dialog .modal-content .modal-header.modal-success {
  color: #fff;
  background-color: #4BAE4F;
}
.app.light .modal-dialog .modal-content .modal-header.modal-info {
  color: #fff;
  background-color: #00BBD3;
}
.app.light .modal-dialog .modal-content .modal-header.modal-warning {
  color: #fff;
  background-color: #FE9700;
}
.app.light .modal-dialog .modal-content .modal-header.modal-danger {
  color: #fff;
  background-color: #F34235;
}
.app.light .modal-dialog .modal-content .modal-header i {
  margin-right: 10px;
}
.app.light .bg-primary {
  background-color: #3E50B4 !important;
}
.app.light .bg-success {
  background-color: #4BAE4F !important;
}
.app.light .bg-info {
  background-color: #00BBD3 !important;
}
.app.light .bg-warning {
  background-color: #FE9700 !important;
}
.app.light .bg-danger {
  background-color: #F34235 !important;
}
.app.light .bg-gray {
  background-color: #9D9D9D !important;
}
.app.light .bg-light {
  background-color: #f5f5f5 !important;
}
.app.light .progress-xl {
  height: 2rem;
}
.app.light .progress-lg {
  height: 1.5rem;
}
.app.light .progress-md {
  height: 1rem;
}
.app.light .progress-sm {
  height: 0.5rem;
}
.app.light .progress-xs {
  height: 0.25rem;
}
.app.light .nav-tabs.custom {
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
  width: 100%;
}
.app.light .nav-tabs.custom .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border: none;
  cursor: default;
}
.app.light .nav-tabs.custom .nav-item .nav-link.active {
  background-color: #fff;
  color: #606060;
}
.app.light .nav-tabs.custom .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.light .nav-tabs.custom.top {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.app.light .nav-tabs.custom.bottom {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.app.light .nav-tabs.custom.bottom .nav-item {
  margin-bottom: 0;
  margin-top: -1px;
}
.app.light .nav-tabs.custom.bottom .nav-item .nav-link {
  border-radius: 0 0 0.3rem 0.3rem;
}
.app.light .nav-tabs.custom.tabs-primary {
  background-color: rgba(62, 80, 180, 0.4);
  border: 1px solid #3E50B4;
}
.app.light .nav-tabs.custom.tabs-primary .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.light .nav-tabs.custom.tabs-primary .nav-item .nav-link.active {
  background-color: #3E50B4;
  color: #fff;
}
.app.light .nav-tabs.custom.tabs-primary .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.light .nav-tabs.custom.tabs-success {
  background-color: rgba(75, 174, 79, 0.4);
  border: 1px solid #4BAE4F;
}
.app.light .nav-tabs.custom.tabs-success .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.light .nav-tabs.custom.tabs-success .nav-item .nav-link.active {
  background-color: #4BAE4F;
  color: #fff;
}
.app.light .nav-tabs.custom.tabs-success .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.light .nav-tabs.custom.tabs-info {
  background-color: rgba(0, 187, 211, 0.4);
  border: 1px solid #00BBD3;
}
.app.light .nav-tabs.custom.tabs-info .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.light .nav-tabs.custom.tabs-info .nav-item .nav-link.active {
  background-color: #00BBD3;
  color: #fff;
}
.app.light .nav-tabs.custom.tabs-info .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.light .nav-tabs.custom.tabs-warning {
  background-color: rgba(254, 151, 0, 0.4);
  border: 1px solid #FE9700;
}
.app.light .nav-tabs.custom.tabs-warning .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.light .nav-tabs.custom.tabs-warning .nav-item .nav-link.active {
  background-color: #FE9700;
  color: #fff;
}
.app.light .nav-tabs.custom.tabs-warning .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.light .nav-tabs.custom.tabs-danger {
  background-color: rgba(243, 66, 53, 0.4);
  border: 1px solid #F34235;
}
.app.light .nav-tabs.custom.tabs-danger .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.light .nav-tabs.custom.tabs-danger .nav-item .nav-link.active {
  background-color: #F34235;
  color: #fff;
}
.app.light .nav-tabs.custom.tabs-danger .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.light .tab-content {
  position: relative;
  z-index: 1;
  border: 1px solid #9D9D9D;
  background: #fff;
}
.app.light .tab-content .tab-pane {
  padding: 1rem;
}
.app.light .tab-content.tab-content-primary {
  background-color: #3E50B4;
  color: #fff;
  border-color: #3E50B4;
}
.app.light .tab-content.tab-content-success {
  background-color: #4BAE4F;
  color: #fff;
  border-color: #4BAE4F;
}
.app.light .tab-content.tab-content-info {
  background-color: #00BBD3;
  color: #fff;
  border-color: #00BBD3;
}
.app.light .tab-content.tab-content-warning {
  background-color: #FE9700;
  color: #fff;
  border-color: #FE9700;
}
.app.light .tab-content.tab-content-danger {
  background-color: #F34235;
  color: #fff;
  border-color: #F34235;
}
.app.light .tab-content.top {
  border-top: none;
}
.app.light .tab-content.bottom {
  border-bottom: none;
}
.app.light .vertical-tabs .nav {
  padding-right: 0;
  overflow: hidden;
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
}
.app.light .vertical-tabs .nav.left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-right: none;
  margin-right: -1px;
  z-index: 2;
}
.app.light .vertical-tabs .nav.right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-left: none;
  margin-left: -1px;
  z-index: 2;
}
.app.light .vertical-tabs .nav .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border-radius: 0;
  cursor: default;
}
.app.light .vertical-tabs .nav .nav-item .nav-link.active {
  background-color: #fff;
}
.app.light .vertical-tabs .nav .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.light .vertical-tabs .nav .nav-item .nav-link.disabled {
  color: rgba(96, 96, 96, 0.6);
  cursor: not-allowed !important;
}
.app.light .list-group-item-primary {
  color: #3E50B4;
  background-color: #b1c3ff;
}
.app.light .list-group-item-success {
  color: #4BAE4F;
  background-color: #beffc2;
}
.app.light .list-group-item-info {
  color: #00BBD3;
  background-color: #a6ffff;
}
.app.light .list-group-item-warning {
  color: #FE9700;
  background-color: #ffffa6;
}
.app.light .list-group-item-danger {
  color: #F34235;
  background-color: #ffb5a8;
}
.app.light .no-caret.dropdown-toggle::after {
  content: none;
}
.app.light .dropdown-item.active, .app.light .dropdown-item:active {
  background-color: #ccc;
}
.app.light .dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.app.light .dropdown.show .dropdown-menu {
  max-height: 300px;
  opacity: 1;
  pointer-events: auto;
}
.app.light .dropdown.open .dropdown-menu {
  opacity: 1;
  pointer-events: auto;
}
.app.light .dropdown-menu-right {
  right: 0;
  left: auto;
}
.app.light .form-group label {
  margin-bottom: 0.2rem;
}
.app.light .form-control-rounded {
  border-radius: 16px;
}
.app.light .form-control:focus {
  border-color: rgba(102, 102, 102, 0.6);
}
.app.light input[type=color].form-control {
  padding: 0;
}
.app.light .has-success .form-control {
  border-color: #4BAE4F;
}
.app.light .has-success .form-control:focus {
  border-color: #378D3B;
}
.app.light .has-success .form-control-feedback, .app.light .has-success .form-control-label, .app.light .has-success .form-check-label, .app.light .has-success .form-check-inline, .app.light .has-success .custom-control {
  color: #4BAE4F;
}
.app.light .has-warning .form-control {
  border-color: #FE9700;
}
.app.light .has-warning .form-control:focus {
  border-color: #F47B00;
}
.app.light .has-warning .form-control-feedback, .app.light .has-warning .form-control-label, .app.light .has-warning .form-check-label, .app.light .has-warning .form-check-inline, .app.light .has-warning .custom-control {
  color: #FE9700;
}
.app.light .has-danger .form-control {
  border-color: #F34235;
}
.app.light .has-danger .form-control:focus {
  border-color: #D22E2E;
}
.app.light .has-danger .form-control-feedback, .app.light .has-danger .form-control-label, .app.light .has-danger .form-check-label, .app.light .has-danger .form-check-inline, .app.light .has-danger .custom-control {
  color: #F34235;
}
.app.light .has-success .input-group-prepend,
.app.light .has-success .input-group-append,
.app.light .has-success .input-group-prepend .input-group-text,
.app.light .has-success .input-group-append .input-group-text {
  color: #4BAE4F;
  border-color: #4BAE4F;
  background-color: #eaf6ea;
}
.app.light .has-danger .input-group-prepend,
.app.light .has-danger .input-group-append,
.app.light .has-danger .input-group-prepend .input-group-text,
.app.light .has-danger .input-group-append .input-group-text {
  color: #F34235;
  border-color: #F34235;
  background-color: #fdf7f7;
}
.app.light .form-control.validation-field.ng-touched {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.625rem;
  background-size: 1.25rem 1.25rem;
}
.app.light .form-control.validation-field.ng-touched.ng-invalid {
  border-color: #F34235;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23F34235' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
}
.app.light .form-control.validation-field.ng-touched.ng-valid {
  border-color: #4BAE4F;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%234BAE4F' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}
.app.light .custom-control-label::before {
  width: 14px;
  height: 14px;
  top: 0.3rem;
}
.app.light .custom-control-label::after {
  width: 14px;
  height: 14px;
  top: 0.3rem;
  background-size: 60% 60%;
}
.app.light .custom-checkbox.checkbox-circle .custom-control-label::before,
.app.light .custom-checkbox.checkbox-circle .custom-control-label::after {
  border-radius: 50%;
}
.app.light .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.light .custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.light .custom-checkbox .custom-control-input.checkbox-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.light .custom-checkbox .custom-control-input.checkbox-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.light .custom-checkbox .custom-control-input.checkbox-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.light .custom-checkbox .custom-control-input.checkbox-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.light .custom-checkbox .custom-control-input.checkbox-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.light .custom-checkbox .custom-control-input.checkbox-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.light .custom-checkbox .custom-control-input.checkbox-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.light .custom-checkbox .custom-control-input.checkbox-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.light .custom-checkbox .custom-control-input.checkbox-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.light .custom-checkbox .custom-control-input.checkbox-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.light .custom-checkbox .custom-control-input.checkbox-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.light .custom-checkbox .custom-control-input.checkbox-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.light .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.light .custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.light .custom-radio .custom-control-input.radio-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.light .custom-radio .custom-control-input.radio-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.light .custom-radio .custom-control-input.radio-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.light .custom-radio .custom-control-input.radio-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.light .custom-radio .custom-control-input.radio-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.light .custom-radio .custom-control-input.radio-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.light .custom-radio .custom-control-input.radio-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.light .custom-radio .custom-control-input.radio-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.light .custom-radio .custom-control-input.radio-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.light .custom-radio .custom-control-input.radio-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.light .custom-radio .custom-control-input.radio-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.light .custom-radio .custom-control-input.radio-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.light .table-success,
.app.light .table-success > th,
.app.light .table-success > td {
  background-color: #4BAE4F;
}
.app.light .table-hover .table-success:hover {
  background-color: #439c47;
}
.app.light .table-hover .table-success:hover > td,
.app.light .table-hover .table-success:hover > th {
  background-color: #439c47;
}
.app.light .table-info,
.app.light .table-info > th,
.app.light .table-info > td {
  background-color: #00BBD3;
}
.app.light .table-hover .table-info:hover {
  background-color: #00a4ba;
}
.app.light .table-hover .table-info:hover > td,
.app.light .table-hover .table-info:hover > th {
  background-color: #00a4ba;
}
.app.light .table-warning,
.app.light .table-warning > th,
.app.light .table-warning > td {
  background-color: #FE9700;
}
.app.light .table-hover .table-warning:hover {
  background-color: #e58800;
}
.app.light .table-hover .table-warning:hover > td,
.app.light .table-hover .table-warning:hover > th {
  background-color: #e58800;
}
.app.light .table-danger,
.app.light .table-danger > th,
.app.light .table-danger > td {
  background-color: #F34235;
}
.app.light .table-hover .table-danger:hover {
  background-color: #f22b1d;
}
.app.light .table-hover .table-danger:hover > td,
.app.light .table-hover .table-danger:hover > th {
  background-color: #f22b1d;
}
.app.light .rounded-0 {
  border-radius: 0 !important;
}
.app.light .popover.bs-popover-top .arrow,
.app.light .popover.bs-popover-auto[x-placement^=top] .arrow,
.app.light .popover.bs-popover-auto[x-placement^=bottom] .arrow,
.app.light .popover.bs-popover-bottom .arrow {
  left: 50%;
}
.app.light .popover.bs-popover-auto[x-placement^=right] .arrow,
.app.light .popover.bs-popover-right .arrow,
.app.light .popover.bs-popover-auto[x-placement^=left] .arrow,
.app.light .popover.bs-popover-left .arrow {
  top: 50%;
}
.app.light .popover.bs-popover-bottom .arrow::after,
.app.light .popover.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #f7f7f7;
}
.app.light .top-navbar {
  background-color: #fff;
  color: #a6a6a6;
}
.app.light .top-navbar .app-search {
  border-bottom: 1px solid #a6a6a6;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.light .top-navbar .app-search .form-control {
  color: #a6a6a6;
}
.app.light .top-navbar .app-search input[type=text]::-webkit-input-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.light .top-navbar .app-search input[type=text]::-moz-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.light .top-navbar .app-search input[type=text]:-moz-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.light .top-navbar .app-search input[type=text]:-ms-input-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.light .top-navbar .app-search textarea::-webkit-input-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.light .top-navbar .app-search textarea::-moz-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.light .top-navbar .app-search textarea:-moz-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.light .top-navbar .app-search textarea:-ms-input-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.light .top-navbar .app-search i {
  color: #a6a6a6;
}
.app.light .top-navbar a {
  color: #a6a6a6;
}
.app.light .logo {
  color: #000 !important;
}
.app.light .logo .ng {
  color: #DD1B16;
}
.app.light .menu-icon .line {
  background-color: #a6a6a6;
}
.app.light #info-content-icon .triangle {
  border-color: #a6a6a6 transparent transparent transparent;
}
.app.light #info-content-icon.show {
  background: #ececec;
  box-shadow: 0px 0px 0px 8px #ececec, 0px -16px 0px 8px #ececec;
}
.app.light #info-content-icon.show .triangle {
  border-color: #a6a6a6 transparent transparent transparent;
}
.app.light .menu-wrapper .card a {
  color: #666;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
}
.app.light .menu-wrapper .card a:hover {
  color: #000;
  background-color: #fff !important;
}
.app.light .menu-wrapper .card a.active-link {
  background-color: #dd1b16 !important;
  color: #fff;
}
.app.light .menu-wrapper .card .card a {
  padding-left: 15px;
  background-color: rgba(153, 153, 153, 0.3);
}
.app.light .menu-wrapper .card .card .card a {
  padding-left: 30px;
  background-color: rgba(153, 153, 153, 0.4);
}
.app.light .menu-wrapper .card .card .card .card a {
  padding-left: 45px;
  background-color: rgba(153, 153, 153, 0.5);
}
.app.light .menu-wrapper .card .card .card .card .card a {
  padding-left: 60px;
  background-color: rgba(153, 153, 153, 0.6);
}
.app.light .menu-wrapper .card .card .card .card .card .card a {
  padding-left: 75px;
  background-color: rgba(153, 153, 153, 0.7);
}
.app.light .menu-wrapper .card .card .card .card .card .card .card a {
  padding-left: 90px;
  background-color: rgba(153, 153, 153, 0.8);
}
.app.light .menu-wrapper .card .card .card .card .card .card .card .card a {
  padding-left: 105px;
  background-color: rgba(153, 153, 153, 0.9);
}
.app.light .menu-wrapper .card .card .card .card .card .card .card .card .card a {
  padding-left: 120px;
  background-color: #999999;
}
.app.light .menu-wrapper .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 135px;
  background-color: #999999;
}
.app.light .menu-wrapper .card .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 150px;
  background-color: #999999;
}
.app.light .wrapper, .app.light .wrapper-inner {
  background-color: #f5f5f5;
  color: #565656;
}
.app.light #info-content {
  background-color: #ececec;
}
.app.light #info-content a.close {
  color: #a6a6a6;
}
.app.light #info-content .info {
  color: #a6a6a6;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.light #info-content .info .form-control {
  background-color: #ececec;
  border-color: rgba(166, 166, 166, 0.4);
  color: #a6a6a6;
}
.app.light #info-content .info input[type=text]::-webkit-input-placeholder {
  color: rgba(166, 166, 166, 0.7);
}
.app.light #info-content .info input[type=text]::-moz-placeholder {
  color: rgba(166, 166, 166, 0.7);
}
.app.light #info-content .info input[type=text]:-moz-placeholder {
  color: rgba(166, 166, 166, 0.7);
}
.app.light #info-content .info input[type=text]:-ms-input-placeholder {
  color: rgba(166, 166, 166, 0.7);
}
.app.light #info-content .info textarea::-webkit-input-placeholder {
  color: rgba(166, 166, 166, 0.7);
}
.app.light #info-content .info textarea::-moz-placeholder {
  color: rgba(166, 166, 166, 0.7);
}
.app.light #info-content .info textarea:-moz-placeholder {
  color: rgba(166, 166, 166, 0.7);
}
.app.light #info-content .info textarea:-ms-input-placeholder {
  color: rgba(166, 166, 166, 0.7);
}
.app.light #info-content .left-border {
  border-left: 1px solid rgba(166, 166, 166, 0.3);
}
.app.light #messagesDropDown .dropdown-menu .nav-link.active {
  border-bottom: 4px solid #ccc;
}
.app.light .dropdown-menu .user-menu .user-info {
  background-color: #eceeef;
  color: #262626;
}
.app.light .favorites-container {
  border-left: 1px solid rgba(166, 166, 166, 0.4);
  border-right: 1px solid rgba(166, 166, 166, 0.4);
}
.app.light #favorites .dropdown .btn-block {
  background-color: #fff;
  color: #a6a6a6;
}
.app.light .side-chat .media {
  color: #666666;
  background-color: #c2c2c2;
  border-bottom: 1px solid #f5f5f5;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.light .side-chat .media input[type=text]::-webkit-input-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.light .side-chat .media input[type=text]::-moz-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.light .side-chat .media input[type=text]:-moz-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.light .side-chat .media input[type=text]:-ms-input-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.light .side-chat .media textarea::-webkit-input-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.light .side-chat .media textarea::-moz-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.light .side-chat .media textarea:-moz-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.light .side-chat .media textarea:-ms-input-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.light .side-chat .chat-window {
  color: #666666;
  background-color: #c2c2c2;
}
.app.light .side-chat .chat-window .header {
  border-bottom: 1px solid #666666;
}
.app.light .sidebar-outer {
  background-color: #ececec;
}
.app.light .sidebar-outer .logo {
  background-color: #fff;
}
.app.light .vertical-menu-tooltip-place .tooltip {
  opacity: 1;
}
.app.light .vertical-menu-tooltip-place .tooltip.bs-tooltip-right .arrow::before, .app.light .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #262626;
}
.app.light .vertical-menu-tooltip-place .tooltip.bs-tooltip-bottom .arrow::before, .app.light .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #262626;
}
.app.light .vertical-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #262626;
}
.app.light .horizontal-menu-outer {
  background-color: #ececec;
}
.app.light #navigation .menu li {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.app.light #navigation .menu li a {
  color: #666;
}
.app.light #navigation .menu li a:hover {
  color: #000;
  background-color: #fff !important;
}
.app.light #navigation .menu li a.active-link {
  background-color: #dd1b16 !important;
  color: #fff;
}
.app.light #navigation .menu li:first-child {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.app.light #navigation .menu .sub-menu {
  background-color: #ececec;
}
.app.light #navigation ul.menu ul a,
.app.light #navigation .menu ul ul a {
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.1);
}
.app.light .horizontal-menu-tooltip-place .tooltip {
  opacity: 1;
  z-index: 999999;
}
.app.light .horizontal-menu-tooltip-place .tooltip.bs-tooltip-top .arrow::before, .app.light .horizontal-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #000;
}
.app.light .horizontal-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #000 !important;
}
.app.light .app-footer {
  background-color: #fff;
  color: #a6a6a6;
}
.app.light .login-container .card, .app.light .register-container .card, .app.light .error-container .card {
  background-color: #ececec;
  color: #696969;
}
.app.light .login-container .card .card-header, .app.light .register-container .card .card-header, .app.light .error-container .card .card-header {
  background-color: #9e9e9e;
}
.app.light .login-container .card .btn, .app.light .register-container .card .btn, .app.light .error-container .card .btn {
  background-color: #9e9e9e;
  color: #696969;
}
.app.light .box-shadow {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
}
.app.light .widget-controls a {
  color: #a6a6a6;
}
.app.light .widget-controls a:hover {
  color: rgba(166, 166, 166, 0.7);
}
.app.dark {
  /*Card*/
  /*Text*/
  /*Breadcrumb*/
  /*Buttons*/
  /*Badge*/
  /*Alerts*/
  /* MODALS */
  /*BG*/
  /*Progress*/
  /*Tabs*/
  /*List group*/
  /*DropDown*/
  /* actual dropdown animation */
  /*Forms*/
  /*Tables*/
  /*Popovers*/
  /*Top Navbar*/
  /*Side-Chat*/
  /*Sidebar*/
  /*Horizontal-menu*/
  /*Footer*/
  /*Login & register & error*/
  /*Global classes*/
  /*Libs */
}
.app.dark .card .card-header {
  background-color: #f3f3f3;
}
.app.dark .card .card-header.transparent {
  background-color: transparent;
}
.app.dark .card.card-primary {
  background-color: #3E50B4;
  border-color: #2F3E9E;
}
.app.dark .card.card-primary .card-header {
  background-color: #2F3E9E;
}
.app.dark .card.card-primary .card-footer {
  background-color: rgba(47, 62, 158, 0.5);
}
.app.dark .card.card-success {
  background-color: #4BAE4F;
  border-color: #378D3B;
}
.app.dark .card.card-success .card-header {
  background-color: #378D3B;
}
.app.dark .card.card-success .card-footer {
  background-color: rgba(55, 141, 59, 0.5);
}
.app.dark .card.card-info {
  background-color: #00BBD3;
  border-color: #0096A6;
}
.app.dark .card.card-info .card-header {
  background-color: #0096A6;
}
.app.dark .card.card-info .card-footer {
  background-color: rgba(0, 150, 166, 0.5);
}
.app.dark .card.card-warning {
  background-color: #FE9700;
  border-color: #F47B00;
}
.app.dark .card.card-warning .card-header {
  background-color: #F47B00;
}
.app.dark .card.card-warning .card-footer {
  background-color: rgba(244, 123, 0, 0.5);
}
.app.dark .card.card-danger {
  background-color: #F34235;
  border-color: #D22E2E;
}
.app.dark .card.card-danger .card-header {
  background-color: #D22E2E;
}
.app.dark .card.card-danger .card-footer {
  background-color: rgba(210, 46, 46, 0.5);
}
.app.dark .card.card-outline-primary {
  background-color: transparent;
  border: 1px solid #3E50B4;
}
.app.dark .card.card-outline-success {
  background-color: transparent;
  border: 1px solid #4BAE4F;
}
.app.dark .card.card-outline-info {
  background-color: transparent;
  border: 1px solid #00BBD3;
}
.app.dark .card.card-outline-warning {
  background-color: transparent;
  border: 1px solid #FE9700;
}
.app.dark .card.card-outline-danger {
  background-color: transparent;
  border: 1px solid #F34235;
}
.app.dark .card-img-overlay {
  color: #262626;
}
.app.dark .card-inverse .card-link,
.app.dark .card-inverse .card-text,
.app.dark .card-inverse .card-subtitle,
.app.dark .card-inverse .card-blockquote .blockquote-footer {
  color: #f5f5f5;
}
.app.dark .overlay-primary {
  background-color: rgba(62, 80, 180, 0.8);
}
.app.dark .overlay-success {
  background-color: rgba(75, 174, 79, 0.8);
}
.app.dark .overlay-info {
  background-color: rgba(0, 187, 211, 0.8);
}
.app.dark .overlay-warning {
  background-color: rgba(254, 151, 0, 0.8);
}
.app.dark .overlay-danger {
  background-color: rgba(243, 66, 53, 0.8);
}
.app.dark .text-primary {
  color: #3E50B4 !important;
}
.app.dark a.text-primary:focus, .app.dark a.text-primary:hover {
  color: #2F3E9E !important;
}
.app.dark .text-success {
  color: #4BAE4F !important;
}
.app.dark a.text-success:focus, .app.dark a.text-success:hover {
  color: #378D3B !important;
}
.app.dark .text-info {
  color: #00BBD3 !important;
}
.app.dark a.text-info:focus, .app.dark a.text-info:hover {
  color: #0096A6 !important;
}
.app.dark .text-warning {
  color: #FE9700 !important;
}
.app.dark a.text-warning:focus, .app.dark a.text-warning:hover {
  color: #F47B00 !important;
}
.app.dark .text-danger {
  color: #F34235 !important;
}
.app.dark a.text-danger:focus, .app.dark a.text-danger:hover {
  color: #D22E2E !important;
}
.app.dark .text-gray {
  color: #9D9D9D !important;
}
.app.dark a.text-gray:focus, .app.dark a.text-gray:hover {
  color: #606060 !important;
}
.app.dark .text-inverse {
  color: #292B2C !important;
}
.app.dark a.text-inverse:focus, .app.dark a.text-inverse:hover {
  color: #1C1E1F !important;
}
.app.dark .breadcrumb {
  padding: 0.5rem 1rem;
}
.app.dark .breadcrumb a {
  color: #3E50B4;
}
.app.dark .btn-primary {
  color: #fff;
  background-color: #3E50B4;
  border-color: #3E50B4;
}
.app.dark .btn-primary:hover, .app.dark .btn-primary:active, .app.dark .btn-primary.active {
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.dark .btn-primary:focus, .app.dark .btn-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.dark .show > .btn-primary.dropdown-toggle {
  background-color: #2F3E9E;
}
.app.dark .btn-success {
  color: #fff;
  background-color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.dark .btn-success:hover, .app.dark .btn-success:active, .app.dark .btn-success.active {
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.dark .btn-success:focus, .app.dark .btn-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.dark .show > .btn-success.dropdown-toggle {
  background-color: #378D3B;
}
.app.dark .btn-info {
  color: #fff;
  background-color: #00BBD3;
  border-color: #00BBD3;
}
.app.dark .btn-info:hover, .app.dark .btn-info:active, .app.dark .btn-info.active {
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.dark .btn-info:focus, .app.dark .btn-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.dark .show > .btn-info.dropdown-toggle {
  background-color: #0096A6;
}
.app.dark .btn-warning {
  color: #fff;
  background-color: #FE9700;
  border-color: #FE9700;
}
.app.dark .btn-warning:hover, .app.dark .btn-warning:active, .app.dark .btn-warning.active {
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.dark .btn-warning:focus, .app.dark .btn-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.dark .show > .btn-warning.dropdown-toggle {
  background-color: #F47B00;
}
.app.dark .btn-danger {
  color: #fff;
  background-color: #F34235;
  border-color: #F34235;
}
.app.dark .btn-danger:hover, .app.dark .btn-danger:active, .app.dark .btn-danger.active {
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.dark .btn-danger:focus, .app.dark .btn-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.dark .show > .btn-danger.dropdown-toggle {
  background-color: #D22E2E;
}
.app.dark .btn-gray {
  color: #fff;
  background-color: #9D9D9D;
}
.app.dark .btn-gray:hover, .app.dark .btn-gray:active, .app.dark .btn-gray.active {
  background-color: #606060;
}
.app.dark .btn-gray:focus, .app.dark .btn-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.dark .show > .btn-gray.dropdown-toggle {
  background-color: #606060;
}
.app.dark .btn-inverse {
  color: #fff;
  background-color: #292B2C;
}
.app.dark .btn-inverse:hover, .app.dark .btn-inverse:active, .app.dark .btn-inverse.active {
  background-color: #1C1E1F;
}
.app.dark .btn-inverse:focus, .app.dark .btn-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.dark .show > .btn-inverse.dropdown-toggle {
  background-color: #1C1E1F;
}
.app.dark .btn-rounded {
  border-radius: 5rem;
}
.app.dark .btn-outline-primary {
  color: #3E50B4;
  border-color: #3E50B4;
}
.app.dark .btn-outline-primary:hover, .app.dark .btn-outline-primary:active, .app.dark .btn-outline-primary.active {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.dark .btn-outline-primary:focus, .app.dark .btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.dark .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.dark .btn-outline-success {
  color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.dark .btn-outline-success:hover, .app.dark .btn-outline-success:active, .app.dark .btn-outline-success.active {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.dark .btn-outline-success:focus, .app.dark .btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.dark .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.dark .btn-outline-info {
  color: #00BBD3;
  border-color: #00BBD3;
}
.app.dark .btn-outline-info:hover, .app.dark .btn-outline-info:active, .app.dark .btn-outline-info.active {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.dark .btn-outline-info:focus, .app.dark .btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.dark .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.dark .btn-outline-warning {
  color: #FE9700;
  border-color: #FE9700;
}
.app.dark .btn-outline-warning:hover, .app.dark .btn-outline-warning:active, .app.dark .btn-outline-warning.active {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.dark .btn-outline-warning:focus, .app.dark .btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.dark .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.dark .btn-outline-danger {
  color: #F34235;
  border-color: #F34235;
}
.app.dark .btn-outline-danger:hover, .app.dark .btn-outline-danger:active, .app.dark .btn-outline-danger.active {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.dark .btn-outline-danger:focus, .app.dark .btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.dark .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.dark .btn-outline-gray {
  color: #9D9D9D;
  border-color: #9D9D9D;
  background-color: transparent;
}
.app.dark .btn-outline-gray:hover, .app.dark .btn-outline-gray:active, .app.dark .btn-outline-gray.active {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.dark .btn-outline-gray:focus, .app.dark .btn-outline-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.dark .show > .btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.dark .btn-outline-inverse {
  color: #292B2C;
  border-color: #292B2C;
  background-color: transparent;
}
.app.dark .btn-outline-inverse:hover, .app.dark .btn-outline-inverse:active, .app.dark .btn-outline-inverse.active {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.dark .btn-outline-inverse:focus, .app.dark .btn-outline-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.dark .show > .btn-outline-inverse.dropdown-toggle {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.dark .btn-link {
  border: 1px solid transparent;
}
.app.dark .badge {
  padding: 4px 6px;
}
.app.dark .badge-primary {
  background-color: #3E50B4;
}
.app.dark .badge-success {
  background-color: #4BAE4F;
}
.app.dark .badge-info {
  background-color: #00BBD3;
}
.app.dark .badge-warning {
  background-color: #FE9700;
}
.app.dark .badge-danger {
  background-color: #F34235;
}
.app.dark .badge-gray {
  background-color: #9D9D9D;
}
.app.dark .badge-white {
  background-color: #fff;
}
.app.dark .alert-primary {
  background-color: #b1c3ff;
  border-color: #97a9ff;
  color: #3E50B4;
}
.app.dark .alert-success {
  background-color: #beffc2;
  border-color: #8bee8f;
  color: #4BAE4F;
}
.app.dark .alert-info {
  background-color: #a6ffff;
  border-color: #40fbff;
  color: #00BBD3;
}
.app.dark .alert-warning {
  background-color: #ffffa6;
  border-color: #fff059;
  color: #FE9700;
}
.app.dark .alert-danger {
  background-color: #ffb5a8;
  border-color: #ff9b8e;
  color: #F34235;
}
.app.dark .alert-gray {
  background-color: #f3f3f3;
  border-color: #dddddd;
  color: #9D9D9D;
}
.app.dark .modal {
  z-index: 999999;
  padding-top: 5%;
}
.app.dark .modal-dialog .modal-content .modal-header {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  padding: 10px 15px;
}
.app.dark .modal-dialog .modal-content .modal-header.modal-primary {
  color: #fff;
  background-color: #3E50B4;
}
.app.dark .modal-dialog .modal-content .modal-header.modal-success {
  color: #fff;
  background-color: #4BAE4F;
}
.app.dark .modal-dialog .modal-content .modal-header.modal-info {
  color: #fff;
  background-color: #00BBD3;
}
.app.dark .modal-dialog .modal-content .modal-header.modal-warning {
  color: #fff;
  background-color: #FE9700;
}
.app.dark .modal-dialog .modal-content .modal-header.modal-danger {
  color: #fff;
  background-color: #F34235;
}
.app.dark .modal-dialog .modal-content .modal-header i {
  margin-right: 10px;
}
.app.dark .bg-primary {
  background-color: #3E50B4 !important;
}
.app.dark .bg-success {
  background-color: #4BAE4F !important;
}
.app.dark .bg-info {
  background-color: #00BBD3 !important;
}
.app.dark .bg-warning {
  background-color: #FE9700 !important;
}
.app.dark .bg-danger {
  background-color: #F34235 !important;
}
.app.dark .bg-gray {
  background-color: #9D9D9D !important;
}
.app.dark .bg-light {
  background-color: #f5f5f5 !important;
}
.app.dark .progress-xl {
  height: 2rem;
}
.app.dark .progress-lg {
  height: 1.5rem;
}
.app.dark .progress-md {
  height: 1rem;
}
.app.dark .progress-sm {
  height: 0.5rem;
}
.app.dark .progress-xs {
  height: 0.25rem;
}
.app.dark .nav-tabs.custom {
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
  width: 100%;
}
.app.dark .nav-tabs.custom .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border: none;
  cursor: default;
}
.app.dark .nav-tabs.custom .nav-item .nav-link.active {
  background-color: #fff;
  color: #606060;
}
.app.dark .nav-tabs.custom .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.dark .nav-tabs.custom.top {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.app.dark .nav-tabs.custom.bottom {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.app.dark .nav-tabs.custom.bottom .nav-item {
  margin-bottom: 0;
  margin-top: -1px;
}
.app.dark .nav-tabs.custom.bottom .nav-item .nav-link {
  border-radius: 0 0 0.3rem 0.3rem;
}
.app.dark .nav-tabs.custom.tabs-primary {
  background-color: rgba(62, 80, 180, 0.4);
  border: 1px solid #3E50B4;
}
.app.dark .nav-tabs.custom.tabs-primary .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.dark .nav-tabs.custom.tabs-primary .nav-item .nav-link.active {
  background-color: #3E50B4;
  color: #fff;
}
.app.dark .nav-tabs.custom.tabs-primary .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.dark .nav-tabs.custom.tabs-success {
  background-color: rgba(75, 174, 79, 0.4);
  border: 1px solid #4BAE4F;
}
.app.dark .nav-tabs.custom.tabs-success .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.dark .nav-tabs.custom.tabs-success .nav-item .nav-link.active {
  background-color: #4BAE4F;
  color: #fff;
}
.app.dark .nav-tabs.custom.tabs-success .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.dark .nav-tabs.custom.tabs-info {
  background-color: rgba(0, 187, 211, 0.4);
  border: 1px solid #00BBD3;
}
.app.dark .nav-tabs.custom.tabs-info .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.dark .nav-tabs.custom.tabs-info .nav-item .nav-link.active {
  background-color: #00BBD3;
  color: #fff;
}
.app.dark .nav-tabs.custom.tabs-info .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.dark .nav-tabs.custom.tabs-warning {
  background-color: rgba(254, 151, 0, 0.4);
  border: 1px solid #FE9700;
}
.app.dark .nav-tabs.custom.tabs-warning .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.dark .nav-tabs.custom.tabs-warning .nav-item .nav-link.active {
  background-color: #FE9700;
  color: #fff;
}
.app.dark .nav-tabs.custom.tabs-warning .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.dark .nav-tabs.custom.tabs-danger {
  background-color: rgba(243, 66, 53, 0.4);
  border: 1px solid #F34235;
}
.app.dark .nav-tabs.custom.tabs-danger .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.dark .nav-tabs.custom.tabs-danger .nav-item .nav-link.active {
  background-color: #F34235;
  color: #fff;
}
.app.dark .nav-tabs.custom.tabs-danger .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.dark .tab-content {
  position: relative;
  z-index: 1;
  border: 1px solid #9D9D9D;
  background: #fff;
}
.app.dark .tab-content .tab-pane {
  padding: 1rem;
}
.app.dark .tab-content.tab-content-primary {
  background-color: #3E50B4;
  color: #fff;
  border-color: #3E50B4;
}
.app.dark .tab-content.tab-content-success {
  background-color: #4BAE4F;
  color: #fff;
  border-color: #4BAE4F;
}
.app.dark .tab-content.tab-content-info {
  background-color: #00BBD3;
  color: #fff;
  border-color: #00BBD3;
}
.app.dark .tab-content.tab-content-warning {
  background-color: #FE9700;
  color: #fff;
  border-color: #FE9700;
}
.app.dark .tab-content.tab-content-danger {
  background-color: #F34235;
  color: #fff;
  border-color: #F34235;
}
.app.dark .tab-content.top {
  border-top: none;
}
.app.dark .tab-content.bottom {
  border-bottom: none;
}
.app.dark .vertical-tabs .nav {
  padding-right: 0;
  overflow: hidden;
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
}
.app.dark .vertical-tabs .nav.left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-right: none;
  margin-right: -1px;
  z-index: 2;
}
.app.dark .vertical-tabs .nav.right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-left: none;
  margin-left: -1px;
  z-index: 2;
}
.app.dark .vertical-tabs .nav .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border-radius: 0;
  cursor: default;
}
.app.dark .vertical-tabs .nav .nav-item .nav-link.active {
  background-color: #fff;
}
.app.dark .vertical-tabs .nav .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.dark .vertical-tabs .nav .nav-item .nav-link.disabled {
  color: rgba(96, 96, 96, 0.6);
  cursor: not-allowed !important;
}
.app.dark .list-group-item-primary {
  color: #3E50B4;
  background-color: #b1c3ff;
}
.app.dark .list-group-item-success {
  color: #4BAE4F;
  background-color: #beffc2;
}
.app.dark .list-group-item-info {
  color: #00BBD3;
  background-color: #a6ffff;
}
.app.dark .list-group-item-warning {
  color: #FE9700;
  background-color: #ffffa6;
}
.app.dark .list-group-item-danger {
  color: #F34235;
  background-color: #ffb5a8;
}
.app.dark .no-caret.dropdown-toggle::after {
  content: none;
}
.app.dark .dropdown-item.active, .app.dark .dropdown-item:active {
  background-color: #ccc;
}
.app.dark .dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.app.dark .dropdown.show .dropdown-menu {
  max-height: 300px;
  opacity: 1;
  pointer-events: auto;
}
.app.dark .dropdown.open .dropdown-menu {
  opacity: 1;
  pointer-events: auto;
}
.app.dark .dropdown-menu-right {
  right: 0;
  left: auto;
}
.app.dark .form-group label {
  margin-bottom: 0.2rem;
}
.app.dark .form-control-rounded {
  border-radius: 16px;
}
.app.dark .form-control:focus {
  border-color: rgba(38, 38, 38, 0.6);
}
.app.dark input[type=color].form-control {
  padding: 0;
}
.app.dark .has-success .form-control {
  border-color: #4BAE4F;
}
.app.dark .has-success .form-control:focus {
  border-color: #378D3B;
}
.app.dark .has-success .form-control-feedback, .app.dark .has-success .form-control-label, .app.dark .has-success .form-check-label, .app.dark .has-success .form-check-inline, .app.dark .has-success .custom-control {
  color: #4BAE4F;
}
.app.dark .has-warning .form-control {
  border-color: #FE9700;
}
.app.dark .has-warning .form-control:focus {
  border-color: #F47B00;
}
.app.dark .has-warning .form-control-feedback, .app.dark .has-warning .form-control-label, .app.dark .has-warning .form-check-label, .app.dark .has-warning .form-check-inline, .app.dark .has-warning .custom-control {
  color: #FE9700;
}
.app.dark .has-danger .form-control {
  border-color: #F34235;
}
.app.dark .has-danger .form-control:focus {
  border-color: #D22E2E;
}
.app.dark .has-danger .form-control-feedback, .app.dark .has-danger .form-control-label, .app.dark .has-danger .form-check-label, .app.dark .has-danger .form-check-inline, .app.dark .has-danger .custom-control {
  color: #F34235;
}
.app.dark .has-success .input-group-prepend,
.app.dark .has-success .input-group-append,
.app.dark .has-success .input-group-prepend .input-group-text,
.app.dark .has-success .input-group-append .input-group-text {
  color: #4BAE4F;
  border-color: #4BAE4F;
  background-color: #eaf6ea;
}
.app.dark .has-danger .input-group-prepend,
.app.dark .has-danger .input-group-append,
.app.dark .has-danger .input-group-prepend .input-group-text,
.app.dark .has-danger .input-group-append .input-group-text {
  color: #F34235;
  border-color: #F34235;
  background-color: #fdf7f7;
}
.app.dark .form-control.validation-field.ng-touched {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.625rem;
  background-size: 1.25rem 1.25rem;
}
.app.dark .form-control.validation-field.ng-touched.ng-invalid {
  border-color: #F34235;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23F34235' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
}
.app.dark .form-control.validation-field.ng-touched.ng-valid {
  border-color: #4BAE4F;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%234BAE4F' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}
.app.dark .custom-control-label::before {
  width: 14px;
  height: 14px;
  top: 0.3rem;
}
.app.dark .custom-control-label::after {
  width: 14px;
  height: 14px;
  top: 0.3rem;
  background-size: 60% 60%;
}
.app.dark .custom-checkbox.checkbox-circle .custom-control-label::before,
.app.dark .custom-checkbox.checkbox-circle .custom-control-label::after {
  border-radius: 50%;
}
.app.dark .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.dark .custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.dark .custom-checkbox .custom-control-input.checkbox-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.dark .custom-checkbox .custom-control-input.checkbox-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.dark .custom-checkbox .custom-control-input.checkbox-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.dark .custom-checkbox .custom-control-input.checkbox-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.dark .custom-checkbox .custom-control-input.checkbox-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.dark .custom-checkbox .custom-control-input.checkbox-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.dark .custom-checkbox .custom-control-input.checkbox-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.dark .custom-checkbox .custom-control-input.checkbox-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.dark .custom-checkbox .custom-control-input.checkbox-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.dark .custom-checkbox .custom-control-input.checkbox-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.dark .custom-checkbox .custom-control-input.checkbox-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.dark .custom-checkbox .custom-control-input.checkbox-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.dark .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.dark .custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.dark .custom-radio .custom-control-input.radio-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.dark .custom-radio .custom-control-input.radio-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.dark .custom-radio .custom-control-input.radio-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.dark .custom-radio .custom-control-input.radio-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.dark .custom-radio .custom-control-input.radio-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.dark .custom-radio .custom-control-input.radio-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.dark .custom-radio .custom-control-input.radio-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.dark .custom-radio .custom-control-input.radio-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.dark .custom-radio .custom-control-input.radio-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.dark .custom-radio .custom-control-input.radio-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.dark .custom-radio .custom-control-input.radio-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.dark .custom-radio .custom-control-input.radio-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.dark .table-success,
.app.dark .table-success > th,
.app.dark .table-success > td {
  background-color: #4BAE4F;
}
.app.dark .table-hover .table-success:hover {
  background-color: #439c47;
}
.app.dark .table-hover .table-success:hover > td,
.app.dark .table-hover .table-success:hover > th {
  background-color: #439c47;
}
.app.dark .table-info,
.app.dark .table-info > th,
.app.dark .table-info > td {
  background-color: #00BBD3;
}
.app.dark .table-hover .table-info:hover {
  background-color: #00a4ba;
}
.app.dark .table-hover .table-info:hover > td,
.app.dark .table-hover .table-info:hover > th {
  background-color: #00a4ba;
}
.app.dark .table-warning,
.app.dark .table-warning > th,
.app.dark .table-warning > td {
  background-color: #FE9700;
}
.app.dark .table-hover .table-warning:hover {
  background-color: #e58800;
}
.app.dark .table-hover .table-warning:hover > td,
.app.dark .table-hover .table-warning:hover > th {
  background-color: #e58800;
}
.app.dark .table-danger,
.app.dark .table-danger > th,
.app.dark .table-danger > td {
  background-color: #F34235;
}
.app.dark .table-hover .table-danger:hover {
  background-color: #f22b1d;
}
.app.dark .table-hover .table-danger:hover > td,
.app.dark .table-hover .table-danger:hover > th {
  background-color: #f22b1d;
}
.app.dark .rounded-0 {
  border-radius: 0 !important;
}
.app.dark .popover.bs-popover-top .arrow,
.app.dark .popover.bs-popover-auto[x-placement^=top] .arrow,
.app.dark .popover.bs-popover-auto[x-placement^=bottom] .arrow,
.app.dark .popover.bs-popover-bottom .arrow {
  left: 50%;
}
.app.dark .popover.bs-popover-auto[x-placement^=right] .arrow,
.app.dark .popover.bs-popover-right .arrow,
.app.dark .popover.bs-popover-auto[x-placement^=left] .arrow,
.app.dark .popover.bs-popover-left .arrow {
  top: 50%;
}
.app.dark .popover.bs-popover-bottom .arrow::after,
.app.dark .popover.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #f7f7f7;
}
.app.dark .top-navbar {
  background-color: #171717;
  color: #8a8a8a;
}
.app.dark .top-navbar .app-search {
  border-bottom: 1px solid #8a8a8a;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.dark .top-navbar .app-search .form-control {
  color: #8a8a8a;
}
.app.dark .top-navbar .app-search input[type=text]::-webkit-input-placeholder {
  color: rgba(138, 138, 138, 0.8);
}
.app.dark .top-navbar .app-search input[type=text]::-moz-placeholder {
  color: rgba(138, 138, 138, 0.8);
}
.app.dark .top-navbar .app-search input[type=text]:-moz-placeholder {
  color: rgba(138, 138, 138, 0.8);
}
.app.dark .top-navbar .app-search input[type=text]:-ms-input-placeholder {
  color: rgba(138, 138, 138, 0.8);
}
.app.dark .top-navbar .app-search textarea::-webkit-input-placeholder {
  color: rgba(138, 138, 138, 0.8);
}
.app.dark .top-navbar .app-search textarea::-moz-placeholder {
  color: rgba(138, 138, 138, 0.8);
}
.app.dark .top-navbar .app-search textarea:-moz-placeholder {
  color: rgba(138, 138, 138, 0.8);
}
.app.dark .top-navbar .app-search textarea:-ms-input-placeholder {
  color: rgba(138, 138, 138, 0.8);
}
.app.dark .top-navbar .app-search i {
  color: #8a8a8a;
}
.app.dark .top-navbar a {
  color: #8a8a8a;
}
.app.dark .logo {
  color: #fff !important;
}
.app.dark .logo .ng {
  color: #DD1B16;
}
.app.dark .menu-icon .line {
  background-color: #8a8a8a;
}
.app.dark #info-content-icon .triangle {
  border-color: #8a8a8a transparent transparent transparent;
}
.app.dark #info-content-icon.show {
  background: #262626;
  box-shadow: 0px 0px 0px 8px #262626, 0px -16px 0px 8px #262626;
}
.app.dark #info-content-icon.show .triangle {
  border-color: #fff transparent transparent transparent;
}
.app.dark .menu-wrapper .card a {
  color: #979797;
  border-bottom: 1px solid rgba(23, 23, 23, 0.5);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1);
}
.app.dark .menu-wrapper .card a:hover {
  color: #fff;
  background-color: #171717 !important;
}
.app.dark .menu-wrapper .card a.active-link {
  background-color: #dd1b16 !important;
  color: #fff;
}
.app.dark .menu-wrapper .card .card a {
  padding-left: 15px;
  background-color: rgba(0, 0, 0, 0.3);
}
.app.dark .menu-wrapper .card .card .card a {
  padding-left: 30px;
  background-color: rgba(0, 0, 0, 0.4);
}
.app.dark .menu-wrapper .card .card .card .card a {
  padding-left: 45px;
  background-color: rgba(0, 0, 0, 0.5);
}
.app.dark .menu-wrapper .card .card .card .card .card a {
  padding-left: 60px;
  background-color: rgba(0, 0, 0, 0.6);
}
.app.dark .menu-wrapper .card .card .card .card .card .card a {
  padding-left: 75px;
  background-color: rgba(0, 0, 0, 0.7);
}
.app.dark .menu-wrapper .card .card .card .card .card .card .card a {
  padding-left: 90px;
  background-color: rgba(0, 0, 0, 0.8);
}
.app.dark .menu-wrapper .card .card .card .card .card .card .card .card a {
  padding-left: 105px;
  background-color: rgba(0, 0, 0, 0.9);
}
.app.dark .menu-wrapper .card .card .card .card .card .card .card .card .card a {
  padding-left: 120px;
  background-color: black;
}
.app.dark .menu-wrapper .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 135px;
  background-color: black;
}
.app.dark .menu-wrapper .card .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 150px;
  background-color: black;
}
.app.dark .wrapper, .app.dark .wrapper-inner {
  background-color: #f5f5f5;
  color: #565656;
}
.app.dark #info-content {
  background-color: #262626;
}
.app.dark #info-content a.close {
  color: #8a8a8a;
}
.app.dark #info-content .info {
  color: #8a8a8a;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.dark #info-content .info .form-control {
  background-color: #262626;
  border-color: rgba(138, 138, 138, 0.4);
  color: #8a8a8a;
}
.app.dark #info-content .info input[type=text]::-webkit-input-placeholder {
  color: rgba(138, 138, 138, 0.7);
}
.app.dark #info-content .info input[type=text]::-moz-placeholder {
  color: rgba(138, 138, 138, 0.7);
}
.app.dark #info-content .info input[type=text]:-moz-placeholder {
  color: rgba(138, 138, 138, 0.7);
}
.app.dark #info-content .info input[type=text]:-ms-input-placeholder {
  color: rgba(138, 138, 138, 0.7);
}
.app.dark #info-content .info textarea::-webkit-input-placeholder {
  color: rgba(138, 138, 138, 0.7);
}
.app.dark #info-content .info textarea::-moz-placeholder {
  color: rgba(138, 138, 138, 0.7);
}
.app.dark #info-content .info textarea:-moz-placeholder {
  color: rgba(138, 138, 138, 0.7);
}
.app.dark #info-content .info textarea:-ms-input-placeholder {
  color: rgba(138, 138, 138, 0.7);
}
.app.dark #info-content .left-border {
  border-left: 1px solid rgba(138, 138, 138, 0.3);
}
.app.dark #messagesDropDown .dropdown-menu .nav-link.active {
  border-bottom: 4px solid #171717;
}
.app.dark .dropdown-menu .user-menu .user-info {
  background-color: #171717;
  color: #8a8a8a;
}
.app.dark .favorites-container {
  border-left: 1px solid rgba(138, 138, 138, 0.4);
  border-right: 1px solid rgba(138, 138, 138, 0.4);
}
.app.dark #favorites .dropdown .btn-block {
  background-color: #171717;
  color: #8a8a8a;
}
.app.dark .side-chat .media {
  color: #979797;
  background-color: #262626;
  border-bottom: 1px solid #f5f5f5;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.dark .side-chat .media input[type=text]::-webkit-input-placeholder {
  color: rgba(138, 138, 138, 0.8);
}
.app.dark .side-chat .media input[type=text]::-moz-placeholder {
  color: rgba(138, 138, 138, 0.8);
}
.app.dark .side-chat .media input[type=text]:-moz-placeholder {
  color: rgba(138, 138, 138, 0.8);
}
.app.dark .side-chat .media input[type=text]:-ms-input-placeholder {
  color: rgba(138, 138, 138, 0.8);
}
.app.dark .side-chat .media textarea::-webkit-input-placeholder {
  color: rgba(138, 138, 138, 0.8);
}
.app.dark .side-chat .media textarea::-moz-placeholder {
  color: rgba(138, 138, 138, 0.8);
}
.app.dark .side-chat .media textarea:-moz-placeholder {
  color: rgba(138, 138, 138, 0.8);
}
.app.dark .side-chat .media textarea:-ms-input-placeholder {
  color: rgba(138, 138, 138, 0.8);
}
.app.dark .side-chat .chat-window {
  color: #979797;
  background-color: #262626;
}
.app.dark .side-chat .chat-window .header {
  border-bottom: 1px solid #979797;
}
.app.dark .sidebar-outer {
  background-color: #262626;
}
.app.dark .sidebar-outer .logo {
  background-color: #171717;
}
.app.dark .vertical-menu-tooltip-place .tooltip {
  opacity: 1;
}
.app.dark .vertical-menu-tooltip-place .tooltip.bs-tooltip-right .arrow::before, .app.dark .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #262626;
}
.app.dark .vertical-menu-tooltip-place .tooltip.bs-tooltip-bottom .arrow::before, .app.dark .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #262626;
}
.app.dark .vertical-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #262626;
}
.app.dark .horizontal-menu-outer {
  background-color: #262626;
}
.app.dark #navigation .menu li {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.app.dark #navigation .menu li a {
  color: #979797;
}
.app.dark #navigation .menu li a:hover {
  color: #fff;
  background-color: #171717 !important;
}
.app.dark #navigation .menu li a.active-link {
  background-color: #dd1b16 !important;
  color: #fff;
}
.app.dark #navigation .menu li:first-child {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.app.dark #navigation .menu .sub-menu {
  background-color: #262626;
}
.app.dark #navigation ul.menu ul a,
.app.dark #navigation .menu ul ul a {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
}
.app.dark .horizontal-menu-tooltip-place .tooltip {
  opacity: 1;
  z-index: 999999;
}
.app.dark .horizontal-menu-tooltip-place .tooltip.bs-tooltip-top .arrow::before, .app.dark .horizontal-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #000;
}
.app.dark .horizontal-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #000 !important;
}
.app.dark .app-footer {
  background-color: #171717;
  color: #8a8a8a;
}
.app.dark .login-container .card, .app.dark .register-container .card, .app.dark .error-container .card {
  background-color: #262626;
  color: #fff;
}
.app.dark .login-container .card .card-header, .app.dark .register-container .card .card-header, .app.dark .error-container .card .card-header {
  background-color: #DD1B16;
}
.app.dark .login-container .card .btn, .app.dark .register-container .card .btn, .app.dark .error-container .card .btn {
  background-color: #DD1B16;
  color: #fff;
}
.app.dark .box-shadow {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
}
.app.dark .widget-controls a {
  color: #a6a6a6;
}
.app.dark .widget-controls a:hover {
  color: rgba(166, 166, 166, 0.7);
}
.app.blue {
  /*Card*/
  /*Text*/
  /*Breadcrumb*/
  /*Buttons*/
  /*Badge*/
  /*Alerts*/
  /* MODALS */
  /*BG*/
  /*Progress*/
  /*Tabs*/
  /*List group*/
  /*DropDown*/
  /* actual dropdown animation */
  /*Forms*/
  /*Tables*/
  /*Popovers*/
  /*Top Navbar*/
  /*Side-Chat*/
  /*Sidebar*/
  /*Horizontal-menu*/
  /*Footer*/
  /*Login & register & error*/
  /*Global classes*/
  /*Libs */
}
.app.blue .card .card-header {
  background-color: #f3f3f3;
}
.app.blue .card .card-header.transparent {
  background-color: transparent;
}
.app.blue .card.card-primary {
  background-color: #3E50B4;
  border-color: #2F3E9E;
}
.app.blue .card.card-primary .card-header {
  background-color: #2F3E9E;
}
.app.blue .card.card-primary .card-footer {
  background-color: rgba(47, 62, 158, 0.5);
}
.app.blue .card.card-success {
  background-color: #4BAE4F;
  border-color: #378D3B;
}
.app.blue .card.card-success .card-header {
  background-color: #378D3B;
}
.app.blue .card.card-success .card-footer {
  background-color: rgba(55, 141, 59, 0.5);
}
.app.blue .card.card-info {
  background-color: #00BBD3;
  border-color: #0096A6;
}
.app.blue .card.card-info .card-header {
  background-color: #0096A6;
}
.app.blue .card.card-info .card-footer {
  background-color: rgba(0, 150, 166, 0.5);
}
.app.blue .card.card-warning {
  background-color: #FE9700;
  border-color: #F47B00;
}
.app.blue .card.card-warning .card-header {
  background-color: #F47B00;
}
.app.blue .card.card-warning .card-footer {
  background-color: rgba(244, 123, 0, 0.5);
}
.app.blue .card.card-danger {
  background-color: #F34235;
  border-color: #D22E2E;
}
.app.blue .card.card-danger .card-header {
  background-color: #D22E2E;
}
.app.blue .card.card-danger .card-footer {
  background-color: rgba(210, 46, 46, 0.5);
}
.app.blue .card.card-outline-primary {
  background-color: transparent;
  border: 1px solid #3E50B4;
}
.app.blue .card.card-outline-success {
  background-color: transparent;
  border: 1px solid #4BAE4F;
}
.app.blue .card.card-outline-info {
  background-color: transparent;
  border: 1px solid #00BBD3;
}
.app.blue .card.card-outline-warning {
  background-color: transparent;
  border: 1px solid #FE9700;
}
.app.blue .card.card-outline-danger {
  background-color: transparent;
  border: 1px solid #F34235;
}
.app.blue .card-img-overlay {
  color: #262626;
}
.app.blue .card-inverse .card-link,
.app.blue .card-inverse .card-text,
.app.blue .card-inverse .card-subtitle,
.app.blue .card-inverse .card-blockquote .blockquote-footer {
  color: #f5f5f5;
}
.app.blue .overlay-primary {
  background-color: rgba(62, 80, 180, 0.8);
}
.app.blue .overlay-success {
  background-color: rgba(75, 174, 79, 0.8);
}
.app.blue .overlay-info {
  background-color: rgba(0, 187, 211, 0.8);
}
.app.blue .overlay-warning {
  background-color: rgba(254, 151, 0, 0.8);
}
.app.blue .overlay-danger {
  background-color: rgba(243, 66, 53, 0.8);
}
.app.blue .text-primary {
  color: #3E50B4 !important;
}
.app.blue a.text-primary:focus, .app.blue a.text-primary:hover {
  color: #2F3E9E !important;
}
.app.blue .text-success {
  color: #4BAE4F !important;
}
.app.blue a.text-success:focus, .app.blue a.text-success:hover {
  color: #378D3B !important;
}
.app.blue .text-info {
  color: #00BBD3 !important;
}
.app.blue a.text-info:focus, .app.blue a.text-info:hover {
  color: #0096A6 !important;
}
.app.blue .text-warning {
  color: #FE9700 !important;
}
.app.blue a.text-warning:focus, .app.blue a.text-warning:hover {
  color: #F47B00 !important;
}
.app.blue .text-danger {
  color: #F34235 !important;
}
.app.blue a.text-danger:focus, .app.blue a.text-danger:hover {
  color: #D22E2E !important;
}
.app.blue .text-gray {
  color: #9D9D9D !important;
}
.app.blue a.text-gray:focus, .app.blue a.text-gray:hover {
  color: #606060 !important;
}
.app.blue .text-inverse {
  color: #292B2C !important;
}
.app.blue a.text-inverse:focus, .app.blue a.text-inverse:hover {
  color: #1C1E1F !important;
}
.app.blue .breadcrumb {
  padding: 0.5rem 1rem;
}
.app.blue .breadcrumb a {
  color: #3E50B4;
}
.app.blue .btn-primary {
  color: #fff;
  background-color: #3E50B4;
  border-color: #3E50B4;
}
.app.blue .btn-primary:hover, .app.blue .btn-primary:active, .app.blue .btn-primary.active {
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.blue .btn-primary:focus, .app.blue .btn-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.blue .show > .btn-primary.dropdown-toggle {
  background-color: #2F3E9E;
}
.app.blue .btn-success {
  color: #fff;
  background-color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.blue .btn-success:hover, .app.blue .btn-success:active, .app.blue .btn-success.active {
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.blue .btn-success:focus, .app.blue .btn-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.blue .show > .btn-success.dropdown-toggle {
  background-color: #378D3B;
}
.app.blue .btn-info {
  color: #fff;
  background-color: #00BBD3;
  border-color: #00BBD3;
}
.app.blue .btn-info:hover, .app.blue .btn-info:active, .app.blue .btn-info.active {
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.blue .btn-info:focus, .app.blue .btn-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.blue .show > .btn-info.dropdown-toggle {
  background-color: #0096A6;
}
.app.blue .btn-warning {
  color: #fff;
  background-color: #FE9700;
  border-color: #FE9700;
}
.app.blue .btn-warning:hover, .app.blue .btn-warning:active, .app.blue .btn-warning.active {
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.blue .btn-warning:focus, .app.blue .btn-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.blue .show > .btn-warning.dropdown-toggle {
  background-color: #F47B00;
}
.app.blue .btn-danger {
  color: #fff;
  background-color: #F34235;
  border-color: #F34235;
}
.app.blue .btn-danger:hover, .app.blue .btn-danger:active, .app.blue .btn-danger.active {
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.blue .btn-danger:focus, .app.blue .btn-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.blue .show > .btn-danger.dropdown-toggle {
  background-color: #D22E2E;
}
.app.blue .btn-gray {
  color: #fff;
  background-color: #9D9D9D;
}
.app.blue .btn-gray:hover, .app.blue .btn-gray:active, .app.blue .btn-gray.active {
  background-color: #606060;
}
.app.blue .btn-gray:focus, .app.blue .btn-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.blue .show > .btn-gray.dropdown-toggle {
  background-color: #606060;
}
.app.blue .btn-inverse {
  color: #fff;
  background-color: #292B2C;
}
.app.blue .btn-inverse:hover, .app.blue .btn-inverse:active, .app.blue .btn-inverse.active {
  background-color: #1C1E1F;
}
.app.blue .btn-inverse:focus, .app.blue .btn-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.blue .show > .btn-inverse.dropdown-toggle {
  background-color: #1C1E1F;
}
.app.blue .btn-rounded {
  border-radius: 5rem;
}
.app.blue .btn-outline-primary {
  color: #3E50B4;
  border-color: #3E50B4;
}
.app.blue .btn-outline-primary:hover, .app.blue .btn-outline-primary:active, .app.blue .btn-outline-primary.active {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.blue .btn-outline-primary:focus, .app.blue .btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.blue .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.blue .btn-outline-success {
  color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.blue .btn-outline-success:hover, .app.blue .btn-outline-success:active, .app.blue .btn-outline-success.active {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.blue .btn-outline-success:focus, .app.blue .btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.blue .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.blue .btn-outline-info {
  color: #00BBD3;
  border-color: #00BBD3;
}
.app.blue .btn-outline-info:hover, .app.blue .btn-outline-info:active, .app.blue .btn-outline-info.active {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.blue .btn-outline-info:focus, .app.blue .btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.blue .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.blue .btn-outline-warning {
  color: #FE9700;
  border-color: #FE9700;
}
.app.blue .btn-outline-warning:hover, .app.blue .btn-outline-warning:active, .app.blue .btn-outline-warning.active {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.blue .btn-outline-warning:focus, .app.blue .btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.blue .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.blue .btn-outline-danger {
  color: #F34235;
  border-color: #F34235;
}
.app.blue .btn-outline-danger:hover, .app.blue .btn-outline-danger:active, .app.blue .btn-outline-danger.active {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.blue .btn-outline-danger:focus, .app.blue .btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.blue .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.blue .btn-outline-gray {
  color: #9D9D9D;
  border-color: #9D9D9D;
  background-color: transparent;
}
.app.blue .btn-outline-gray:hover, .app.blue .btn-outline-gray:active, .app.blue .btn-outline-gray.active {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.blue .btn-outline-gray:focus, .app.blue .btn-outline-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.blue .show > .btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.blue .btn-outline-inverse {
  color: #292B2C;
  border-color: #292B2C;
  background-color: transparent;
}
.app.blue .btn-outline-inverse:hover, .app.blue .btn-outline-inverse:active, .app.blue .btn-outline-inverse.active {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.blue .btn-outline-inverse:focus, .app.blue .btn-outline-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.blue .show > .btn-outline-inverse.dropdown-toggle {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.blue .btn-link {
  border: 1px solid transparent;
}
.app.blue .badge {
  padding: 4px 6px;
}
.app.blue .badge-primary {
  background-color: #3E50B4;
}
.app.blue .badge-success {
  background-color: #4BAE4F;
}
.app.blue .badge-info {
  background-color: #00BBD3;
}
.app.blue .badge-warning {
  background-color: #FE9700;
}
.app.blue .badge-danger {
  background-color: #F34235;
}
.app.blue .badge-gray {
  background-color: #9D9D9D;
}
.app.blue .badge-white {
  background-color: #fff;
}
.app.blue .alert-primary {
  background-color: #b1c3ff;
  border-color: #97a9ff;
  color: #3E50B4;
}
.app.blue .alert-success {
  background-color: #beffc2;
  border-color: #8bee8f;
  color: #4BAE4F;
}
.app.blue .alert-info {
  background-color: #a6ffff;
  border-color: #40fbff;
  color: #00BBD3;
}
.app.blue .alert-warning {
  background-color: #ffffa6;
  border-color: #fff059;
  color: #FE9700;
}
.app.blue .alert-danger {
  background-color: #ffb5a8;
  border-color: #ff9b8e;
  color: #F34235;
}
.app.blue .alert-gray {
  background-color: #f3f3f3;
  border-color: #dddddd;
  color: #9D9D9D;
}
.app.blue .modal {
  z-index: 999999;
  padding-top: 5%;
}
.app.blue .modal-dialog .modal-content .modal-header {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  padding: 10px 15px;
}
.app.blue .modal-dialog .modal-content .modal-header.modal-primary {
  color: #fff;
  background-color: #3E50B4;
}
.app.blue .modal-dialog .modal-content .modal-header.modal-success {
  color: #fff;
  background-color: #4BAE4F;
}
.app.blue .modal-dialog .modal-content .modal-header.modal-info {
  color: #fff;
  background-color: #00BBD3;
}
.app.blue .modal-dialog .modal-content .modal-header.modal-warning {
  color: #fff;
  background-color: #FE9700;
}
.app.blue .modal-dialog .modal-content .modal-header.modal-danger {
  color: #fff;
  background-color: #F34235;
}
.app.blue .modal-dialog .modal-content .modal-header i {
  margin-right: 10px;
}
.app.blue .bg-primary {
  background-color: #3E50B4 !important;
}
.app.blue .bg-success {
  background-color: #4BAE4F !important;
}
.app.blue .bg-info {
  background-color: #00BBD3 !important;
}
.app.blue .bg-warning {
  background-color: #FE9700 !important;
}
.app.blue .bg-danger {
  background-color: #F34235 !important;
}
.app.blue .bg-gray {
  background-color: #9D9D9D !important;
}
.app.blue .bg-light {
  background-color: #f5f5f5 !important;
}
.app.blue .progress-xl {
  height: 2rem;
}
.app.blue .progress-lg {
  height: 1.5rem;
}
.app.blue .progress-md {
  height: 1rem;
}
.app.blue .progress-sm {
  height: 0.5rem;
}
.app.blue .progress-xs {
  height: 0.25rem;
}
.app.blue .nav-tabs.custom {
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
  width: 100%;
}
.app.blue .nav-tabs.custom .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border: none;
  cursor: default;
}
.app.blue .nav-tabs.custom .nav-item .nav-link.active {
  background-color: #fff;
  color: #606060;
}
.app.blue .nav-tabs.custom .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.blue .nav-tabs.custom.top {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.app.blue .nav-tabs.custom.bottom {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.app.blue .nav-tabs.custom.bottom .nav-item {
  margin-bottom: 0;
  margin-top: -1px;
}
.app.blue .nav-tabs.custom.bottom .nav-item .nav-link {
  border-radius: 0 0 0.3rem 0.3rem;
}
.app.blue .nav-tabs.custom.tabs-primary {
  background-color: rgba(62, 80, 180, 0.4);
  border: 1px solid #3E50B4;
}
.app.blue .nav-tabs.custom.tabs-primary .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.blue .nav-tabs.custom.tabs-primary .nav-item .nav-link.active {
  background-color: #3E50B4;
  color: #fff;
}
.app.blue .nav-tabs.custom.tabs-primary .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.blue .nav-tabs.custom.tabs-success {
  background-color: rgba(75, 174, 79, 0.4);
  border: 1px solid #4BAE4F;
}
.app.blue .nav-tabs.custom.tabs-success .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.blue .nav-tabs.custom.tabs-success .nav-item .nav-link.active {
  background-color: #4BAE4F;
  color: #fff;
}
.app.blue .nav-tabs.custom.tabs-success .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.blue .nav-tabs.custom.tabs-info {
  background-color: rgba(0, 187, 211, 0.4);
  border: 1px solid #00BBD3;
}
.app.blue .nav-tabs.custom.tabs-info .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.blue .nav-tabs.custom.tabs-info .nav-item .nav-link.active {
  background-color: #00BBD3;
  color: #fff;
}
.app.blue .nav-tabs.custom.tabs-info .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.blue .nav-tabs.custom.tabs-warning {
  background-color: rgba(254, 151, 0, 0.4);
  border: 1px solid #FE9700;
}
.app.blue .nav-tabs.custom.tabs-warning .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.blue .nav-tabs.custom.tabs-warning .nav-item .nav-link.active {
  background-color: #FE9700;
  color: #fff;
}
.app.blue .nav-tabs.custom.tabs-warning .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.blue .nav-tabs.custom.tabs-danger {
  background-color: rgba(243, 66, 53, 0.4);
  border: 1px solid #F34235;
}
.app.blue .nav-tabs.custom.tabs-danger .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.blue .nav-tabs.custom.tabs-danger .nav-item .nav-link.active {
  background-color: #F34235;
  color: #fff;
}
.app.blue .nav-tabs.custom.tabs-danger .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.blue .tab-content {
  position: relative;
  z-index: 1;
  border: 1px solid #9D9D9D;
  background: #fff;
}
.app.blue .tab-content .tab-pane {
  padding: 1rem;
}
.app.blue .tab-content.tab-content-primary {
  background-color: #3E50B4;
  color: #fff;
  border-color: #3E50B4;
}
.app.blue .tab-content.tab-content-success {
  background-color: #4BAE4F;
  color: #fff;
  border-color: #4BAE4F;
}
.app.blue .tab-content.tab-content-info {
  background-color: #00BBD3;
  color: #fff;
  border-color: #00BBD3;
}
.app.blue .tab-content.tab-content-warning {
  background-color: #FE9700;
  color: #fff;
  border-color: #FE9700;
}
.app.blue .tab-content.tab-content-danger {
  background-color: #F34235;
  color: #fff;
  border-color: #F34235;
}
.app.blue .tab-content.top {
  border-top: none;
}
.app.blue .tab-content.bottom {
  border-bottom: none;
}
.app.blue .vertical-tabs .nav {
  padding-right: 0;
  overflow: hidden;
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
}
.app.blue .vertical-tabs .nav.left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-right: none;
  margin-right: -1px;
  z-index: 2;
}
.app.blue .vertical-tabs .nav.right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-left: none;
  margin-left: -1px;
  z-index: 2;
}
.app.blue .vertical-tabs .nav .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border-radius: 0;
  cursor: default;
}
.app.blue .vertical-tabs .nav .nav-item .nav-link.active {
  background-color: #fff;
}
.app.blue .vertical-tabs .nav .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.blue .vertical-tabs .nav .nav-item .nav-link.disabled {
  color: rgba(96, 96, 96, 0.6);
  cursor: not-allowed !important;
}
.app.blue .list-group-item-primary {
  color: #3E50B4;
  background-color: #b1c3ff;
}
.app.blue .list-group-item-success {
  color: #4BAE4F;
  background-color: #beffc2;
}
.app.blue .list-group-item-info {
  color: #00BBD3;
  background-color: #a6ffff;
}
.app.blue .list-group-item-warning {
  color: #FE9700;
  background-color: #ffffa6;
}
.app.blue .list-group-item-danger {
  color: #F34235;
  background-color: #ffb5a8;
}
.app.blue .no-caret.dropdown-toggle::after {
  content: none;
}
.app.blue .dropdown-item.active, .app.blue .dropdown-item:active {
  background-color: #ccc;
}
.app.blue .dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.app.blue .dropdown.show .dropdown-menu {
  max-height: 300px;
  opacity: 1;
  pointer-events: auto;
}
.app.blue .dropdown.open .dropdown-menu {
  opacity: 1;
  pointer-events: auto;
}
.app.blue .dropdown-menu-right {
  right: 0;
  left: auto;
}
.app.blue .form-group label {
  margin-bottom: 0.2rem;
}
.app.blue .form-control-rounded {
  border-radius: 16px;
}
.app.blue .form-control:focus {
  border-color: rgba(62, 80, 180, 0.6);
}
.app.blue input[type=color].form-control {
  padding: 0;
}
.app.blue .has-success .form-control {
  border-color: #4BAE4F;
}
.app.blue .has-success .form-control:focus {
  border-color: #378D3B;
}
.app.blue .has-success .form-control-feedback, .app.blue .has-success .form-control-label, .app.blue .has-success .form-check-label, .app.blue .has-success .form-check-inline, .app.blue .has-success .custom-control {
  color: #4BAE4F;
}
.app.blue .has-warning .form-control {
  border-color: #FE9700;
}
.app.blue .has-warning .form-control:focus {
  border-color: #F47B00;
}
.app.blue .has-warning .form-control-feedback, .app.blue .has-warning .form-control-label, .app.blue .has-warning .form-check-label, .app.blue .has-warning .form-check-inline, .app.blue .has-warning .custom-control {
  color: #FE9700;
}
.app.blue .has-danger .form-control {
  border-color: #F34235;
}
.app.blue .has-danger .form-control:focus {
  border-color: #D22E2E;
}
.app.blue .has-danger .form-control-feedback, .app.blue .has-danger .form-control-label, .app.blue .has-danger .form-check-label, .app.blue .has-danger .form-check-inline, .app.blue .has-danger .custom-control {
  color: #F34235;
}
.app.blue .has-success .input-group-prepend,
.app.blue .has-success .input-group-append,
.app.blue .has-success .input-group-prepend .input-group-text,
.app.blue .has-success .input-group-append .input-group-text {
  color: #4BAE4F;
  border-color: #4BAE4F;
  background-color: #eaf6ea;
}
.app.blue .has-danger .input-group-prepend,
.app.blue .has-danger .input-group-append,
.app.blue .has-danger .input-group-prepend .input-group-text,
.app.blue .has-danger .input-group-append .input-group-text {
  color: #F34235;
  border-color: #F34235;
  background-color: #fdf7f7;
}
.app.blue .form-control.validation-field.ng-touched {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.625rem;
  background-size: 1.25rem 1.25rem;
}
.app.blue .form-control.validation-field.ng-touched.ng-invalid {
  border-color: #F34235;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23F34235' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
}
.app.blue .form-control.validation-field.ng-touched.ng-valid {
  border-color: #4BAE4F;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%234BAE4F' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}
.app.blue .custom-control-label::before {
  width: 14px;
  height: 14px;
  top: 0.3rem;
}
.app.blue .custom-control-label::after {
  width: 14px;
  height: 14px;
  top: 0.3rem;
  background-size: 60% 60%;
}
.app.blue .custom-checkbox.checkbox-circle .custom-control-label::before,
.app.blue .custom-checkbox.checkbox-circle .custom-control-label::after {
  border-radius: 50%;
}
.app.blue .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.blue .custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.blue .custom-checkbox .custom-control-input.checkbox-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.blue .custom-checkbox .custom-control-input.checkbox-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.blue .custom-checkbox .custom-control-input.checkbox-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.blue .custom-checkbox .custom-control-input.checkbox-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.blue .custom-checkbox .custom-control-input.checkbox-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.blue .custom-checkbox .custom-control-input.checkbox-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.blue .custom-checkbox .custom-control-input.checkbox-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.blue .custom-checkbox .custom-control-input.checkbox-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.blue .custom-checkbox .custom-control-input.checkbox-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.blue .custom-checkbox .custom-control-input.checkbox-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.blue .custom-checkbox .custom-control-input.checkbox-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.blue .custom-checkbox .custom-control-input.checkbox-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.blue .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.blue .custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.blue .custom-radio .custom-control-input.radio-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.blue .custom-radio .custom-control-input.radio-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.blue .custom-radio .custom-control-input.radio-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.blue .custom-radio .custom-control-input.radio-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.blue .custom-radio .custom-control-input.radio-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.blue .custom-radio .custom-control-input.radio-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.blue .custom-radio .custom-control-input.radio-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.blue .custom-radio .custom-control-input.radio-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.blue .custom-radio .custom-control-input.radio-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.blue .custom-radio .custom-control-input.radio-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.blue .custom-radio .custom-control-input.radio-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.blue .custom-radio .custom-control-input.radio-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.blue .table-success,
.app.blue .table-success > th,
.app.blue .table-success > td {
  background-color: #4BAE4F;
}
.app.blue .table-hover .table-success:hover {
  background-color: #439c47;
}
.app.blue .table-hover .table-success:hover > td,
.app.blue .table-hover .table-success:hover > th {
  background-color: #439c47;
}
.app.blue .table-info,
.app.blue .table-info > th,
.app.blue .table-info > td {
  background-color: #00BBD3;
}
.app.blue .table-hover .table-info:hover {
  background-color: #00a4ba;
}
.app.blue .table-hover .table-info:hover > td,
.app.blue .table-hover .table-info:hover > th {
  background-color: #00a4ba;
}
.app.blue .table-warning,
.app.blue .table-warning > th,
.app.blue .table-warning > td {
  background-color: #FE9700;
}
.app.blue .table-hover .table-warning:hover {
  background-color: #e58800;
}
.app.blue .table-hover .table-warning:hover > td,
.app.blue .table-hover .table-warning:hover > th {
  background-color: #e58800;
}
.app.blue .table-danger,
.app.blue .table-danger > th,
.app.blue .table-danger > td {
  background-color: #F34235;
}
.app.blue .table-hover .table-danger:hover {
  background-color: #f22b1d;
}
.app.blue .table-hover .table-danger:hover > td,
.app.blue .table-hover .table-danger:hover > th {
  background-color: #f22b1d;
}
.app.blue .rounded-0 {
  border-radius: 0 !important;
}
.app.blue .popover.bs-popover-top .arrow,
.app.blue .popover.bs-popover-auto[x-placement^=top] .arrow,
.app.blue .popover.bs-popover-auto[x-placement^=bottom] .arrow,
.app.blue .popover.bs-popover-bottom .arrow {
  left: 50%;
}
.app.blue .popover.bs-popover-auto[x-placement^=right] .arrow,
.app.blue .popover.bs-popover-right .arrow,
.app.blue .popover.bs-popover-auto[x-placement^=left] .arrow,
.app.blue .popover.bs-popover-left .arrow {
  top: 50%;
}
.app.blue .popover.bs-popover-bottom .arrow::after,
.app.blue .popover.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #f7f7f7;
}
.app.blue .top-navbar {
  background-color: #2F3E9E;
  color: #C4C9E8;
}
.app.blue .top-navbar .app-search {
  border-bottom: 1px solid #C4C9E8;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.blue .top-navbar .app-search .form-control {
  color: #C4C9E8;
}
.app.blue .top-navbar .app-search input[type=text]::-webkit-input-placeholder {
  color: rgba(196, 201, 232, 0.8);
}
.app.blue .top-navbar .app-search input[type=text]::-moz-placeholder {
  color: rgba(196, 201, 232, 0.8);
}
.app.blue .top-navbar .app-search input[type=text]:-moz-placeholder {
  color: rgba(196, 201, 232, 0.8);
}
.app.blue .top-navbar .app-search input[type=text]:-ms-input-placeholder {
  color: rgba(196, 201, 232, 0.8);
}
.app.blue .top-navbar .app-search textarea::-webkit-input-placeholder {
  color: rgba(196, 201, 232, 0.8);
}
.app.blue .top-navbar .app-search textarea::-moz-placeholder {
  color: rgba(196, 201, 232, 0.8);
}
.app.blue .top-navbar .app-search textarea:-moz-placeholder {
  color: rgba(196, 201, 232, 0.8);
}
.app.blue .top-navbar .app-search textarea:-ms-input-placeholder {
  color: rgba(196, 201, 232, 0.8);
}
.app.blue .top-navbar .app-search i {
  color: #C4C9E8;
}
.app.blue .top-navbar a {
  color: #C4C9E8;
}
.app.blue .logo {
  color: #fff !important;
}
.app.blue .logo .ng {
  color: #fff;
}
.app.blue .menu-icon .line {
  background-color: #C4C9E8;
}
.app.blue #info-content-icon .triangle {
  border-color: #C4C9E8 transparent transparent transparent;
}
.app.blue #info-content-icon.show {
  background: #3E50B4;
  box-shadow: 0px 0px 0px 8px #3E50B4, 0px -16px 0px 8px #3E50B4;
}
.app.blue #info-content-icon.show .triangle {
  border-color: #C4C9E8 transparent transparent transparent;
}
.app.blue .menu-wrapper .card a {
  color: #C4C9E8;
  border-bottom: 1px solid rgba(47, 62, 158, 0.5);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1);
}
.app.blue .menu-wrapper .card a:hover {
  color: #fff;
  background-color: #2F3E9E !important;
}
.app.blue .menu-wrapper .card a.active-link {
  background-color: #526CFD !important;
  color: #fff;
}
.app.blue .menu-wrapper .card .card a {
  padding-left: 15px;
  background-color: rgba(0, 0, 0, 0.3);
}
.app.blue .menu-wrapper .card .card .card a {
  padding-left: 30px;
  background-color: rgba(0, 0, 0, 0.4);
}
.app.blue .menu-wrapper .card .card .card .card a {
  padding-left: 45px;
  background-color: rgba(0, 0, 0, 0.5);
}
.app.blue .menu-wrapper .card .card .card .card .card a {
  padding-left: 60px;
  background-color: rgba(0, 0, 0, 0.6);
}
.app.blue .menu-wrapper .card .card .card .card .card .card a {
  padding-left: 75px;
  background-color: rgba(0, 0, 0, 0.7);
}
.app.blue .menu-wrapper .card .card .card .card .card .card .card a {
  padding-left: 90px;
  background-color: rgba(0, 0, 0, 0.8);
}
.app.blue .menu-wrapper .card .card .card .card .card .card .card .card a {
  padding-left: 105px;
  background-color: rgba(0, 0, 0, 0.9);
}
.app.blue .menu-wrapper .card .card .card .card .card .card .card .card .card a {
  padding-left: 120px;
  background-color: black;
}
.app.blue .menu-wrapper .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 135px;
  background-color: black;
}
.app.blue .menu-wrapper .card .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 150px;
  background-color: black;
}
.app.blue .wrapper, .app.blue .wrapper-inner {
  background-color: #f5f5f5;
  color: #565656;
}
.app.blue #info-content {
  background-color: #3E50B4;
}
.app.blue #info-content a.close {
  color: #C4C9E8;
}
.app.blue #info-content .info {
  color: #C4C9E8;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.blue #info-content .info .form-control {
  background-color: #3E50B4;
  border-color: rgba(196, 201, 232, 0.4);
  color: #C4C9E8;
}
.app.blue #info-content .info input[type=text]::-webkit-input-placeholder {
  color: rgba(196, 201, 232, 0.7);
}
.app.blue #info-content .info input[type=text]::-moz-placeholder {
  color: rgba(196, 201, 232, 0.7);
}
.app.blue #info-content .info input[type=text]:-moz-placeholder {
  color: rgba(196, 201, 232, 0.7);
}
.app.blue #info-content .info input[type=text]:-ms-input-placeholder {
  color: rgba(196, 201, 232, 0.7);
}
.app.blue #info-content .info textarea::-webkit-input-placeholder {
  color: rgba(196, 201, 232, 0.7);
}
.app.blue #info-content .info textarea::-moz-placeholder {
  color: rgba(196, 201, 232, 0.7);
}
.app.blue #info-content .info textarea:-moz-placeholder {
  color: rgba(196, 201, 232, 0.7);
}
.app.blue #info-content .info textarea:-ms-input-placeholder {
  color: rgba(196, 201, 232, 0.7);
}
.app.blue #info-content .left-border {
  border-left: 1px solid rgba(196, 201, 232, 0.3);
}
.app.blue #messagesDropDown .dropdown-menu .nav-link.active {
  border-bottom: 4px solid #2F3E9E;
}
.app.blue .dropdown-menu .user-menu .user-info {
  background-color: #2F3E9E;
  color: #C4C9E8;
}
.app.blue .favorites-container {
  border-left: 1px solid rgba(196, 201, 232, 0.4);
  border-right: 1px solid rgba(196, 201, 232, 0.4);
}
.app.blue #favorites .dropdown .btn-block {
  background-color: #2F3E9E;
  color: #C4C9E8;
}
.app.blue .side-chat .media {
  color: #C4C9E8;
  background-color: #3E50B4;
  border-bottom: 1px solid #f5f5f5;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.blue .side-chat .media input[type=text]::-webkit-input-placeholder {
  color: rgba(196, 201, 232, 0.8);
}
.app.blue .side-chat .media input[type=text]::-moz-placeholder {
  color: rgba(196, 201, 232, 0.8);
}
.app.blue .side-chat .media input[type=text]:-moz-placeholder {
  color: rgba(196, 201, 232, 0.8);
}
.app.blue .side-chat .media input[type=text]:-ms-input-placeholder {
  color: rgba(196, 201, 232, 0.8);
}
.app.blue .side-chat .media textarea::-webkit-input-placeholder {
  color: rgba(196, 201, 232, 0.8);
}
.app.blue .side-chat .media textarea::-moz-placeholder {
  color: rgba(196, 201, 232, 0.8);
}
.app.blue .side-chat .media textarea:-moz-placeholder {
  color: rgba(196, 201, 232, 0.8);
}
.app.blue .side-chat .media textarea:-ms-input-placeholder {
  color: rgba(196, 201, 232, 0.8);
}
.app.blue .side-chat .chat-window {
  color: #C4C9E8;
  background-color: #3E50B4;
}
.app.blue .side-chat .chat-window .header {
  border-bottom: 1px solid #C4C9E8;
}
.app.blue .sidebar-outer {
  background-color: #3E50B4;
}
.app.blue .sidebar-outer .logo {
  background-color: #2F3E9E;
}
.app.blue .vertical-menu-tooltip-place .tooltip {
  opacity: 1;
}
.app.blue .vertical-menu-tooltip-place .tooltip.bs-tooltip-right .arrow::before, .app.blue .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #3E50B4;
}
.app.blue .vertical-menu-tooltip-place .tooltip.bs-tooltip-bottom .arrow::before, .app.blue .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #3E50B4;
}
.app.blue .vertical-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #3E50B4;
}
.app.blue .horizontal-menu-outer {
  background-color: #3E50B4;
}
.app.blue #navigation .menu li {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.app.blue #navigation .menu li a {
  color: #C4C9E8;
}
.app.blue #navigation .menu li a:hover {
  color: #fff;
  background-color: #2F3E9E !important;
}
.app.blue #navigation .menu li a.active-link {
  background-color: #526CFD !important;
  color: #fff;
}
.app.blue #navigation .menu li:first-child {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.app.blue #navigation .menu .sub-menu {
  background-color: #3E50B4;
}
.app.blue #navigation ul.menu ul a,
.app.blue #navigation .menu ul ul a {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
}
.app.blue .horizontal-menu-tooltip-place .tooltip {
  opacity: 1;
  z-index: 999999;
}
.app.blue .horizontal-menu-tooltip-place .tooltip.bs-tooltip-top .arrow::before, .app.blue .horizontal-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #000;
}
.app.blue .horizontal-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #000 !important;
}
.app.blue .app-footer {
  background-color: #2F3E9E;
  color: #C4C9E8;
}
.app.blue .login-container .card, .app.blue .register-container .card, .app.blue .error-container .card {
  background-color: #526CFD;
  color: #F5F5F5;
}
.app.blue .login-container .card .card-header, .app.blue .register-container .card .card-header, .app.blue .error-container .card .card-header {
  background-color: #2F3E9E;
}
.app.blue .login-container .card .btn, .app.blue .register-container .card .btn, .app.blue .error-container .card .btn {
  background-color: #2F3E9E;
  color: #F5F5F5;
}
.app.blue .box-shadow {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(62, 80, 180, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(62, 80, 180, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(62, 80, 180, 0.3);
}
.app.blue .widget-controls a {
  color: #a6a6a6;
}
.app.blue .widget-controls a:hover {
  color: rgba(166, 166, 166, 0.7);
}
.app.green {
  /*Card*/
  /*Text*/
  /*Breadcrumb*/
  /*Buttons*/
  /*Badge*/
  /*Alerts*/
  /* MODALS */
  /*BG*/
  /*Progress*/
  /*Tabs*/
  /*List group*/
  /*DropDown*/
  /* actual dropdown animation */
  /*Forms*/
  /*Tables*/
  /*Popovers*/
  /*Top Navbar*/
  /*Side-Chat*/
  /*Sidebar*/
  /*Horizontal-menu*/
  /*Footer*/
  /*Login & register & error*/
  /*Global classes*/
  /*Libs */
}
.app.green .card .card-header {
  background-color: #f3f3f3;
}
.app.green .card .card-header.transparent {
  background-color: transparent;
}
.app.green .card.card-primary {
  background-color: #3E50B4;
  border-color: #2F3E9E;
}
.app.green .card.card-primary .card-header {
  background-color: #2F3E9E;
}
.app.green .card.card-primary .card-footer {
  background-color: rgba(47, 62, 158, 0.5);
}
.app.green .card.card-success {
  background-color: #4BAE4F;
  border-color: #378D3B;
}
.app.green .card.card-success .card-header {
  background-color: #378D3B;
}
.app.green .card.card-success .card-footer {
  background-color: rgba(55, 141, 59, 0.5);
}
.app.green .card.card-info {
  background-color: #00BBD3;
  border-color: #0096A6;
}
.app.green .card.card-info .card-header {
  background-color: #0096A6;
}
.app.green .card.card-info .card-footer {
  background-color: rgba(0, 150, 166, 0.5);
}
.app.green .card.card-warning {
  background-color: #FE9700;
  border-color: #F47B00;
}
.app.green .card.card-warning .card-header {
  background-color: #F47B00;
}
.app.green .card.card-warning .card-footer {
  background-color: rgba(244, 123, 0, 0.5);
}
.app.green .card.card-danger {
  background-color: #F34235;
  border-color: #D22E2E;
}
.app.green .card.card-danger .card-header {
  background-color: #D22E2E;
}
.app.green .card.card-danger .card-footer {
  background-color: rgba(210, 46, 46, 0.5);
}
.app.green .card.card-outline-primary {
  background-color: transparent;
  border: 1px solid #3E50B4;
}
.app.green .card.card-outline-success {
  background-color: transparent;
  border: 1px solid #4BAE4F;
}
.app.green .card.card-outline-info {
  background-color: transparent;
  border: 1px solid #00BBD3;
}
.app.green .card.card-outline-warning {
  background-color: transparent;
  border: 1px solid #FE9700;
}
.app.green .card.card-outline-danger {
  background-color: transparent;
  border: 1px solid #F34235;
}
.app.green .card-img-overlay {
  color: #262626;
}
.app.green .card-inverse .card-link,
.app.green .card-inverse .card-text,
.app.green .card-inverse .card-subtitle,
.app.green .card-inverse .card-blockquote .blockquote-footer {
  color: #f5f5f5;
}
.app.green .overlay-primary {
  background-color: rgba(62, 80, 180, 0.8);
}
.app.green .overlay-success {
  background-color: rgba(75, 174, 79, 0.8);
}
.app.green .overlay-info {
  background-color: rgba(0, 187, 211, 0.8);
}
.app.green .overlay-warning {
  background-color: rgba(254, 151, 0, 0.8);
}
.app.green .overlay-danger {
  background-color: rgba(243, 66, 53, 0.8);
}
.app.green .text-primary {
  color: #3E50B4 !important;
}
.app.green a.text-primary:focus, .app.green a.text-primary:hover {
  color: #2F3E9E !important;
}
.app.green .text-success {
  color: #4BAE4F !important;
}
.app.green a.text-success:focus, .app.green a.text-success:hover {
  color: #378D3B !important;
}
.app.green .text-info {
  color: #00BBD3 !important;
}
.app.green a.text-info:focus, .app.green a.text-info:hover {
  color: #0096A6 !important;
}
.app.green .text-warning {
  color: #FE9700 !important;
}
.app.green a.text-warning:focus, .app.green a.text-warning:hover {
  color: #F47B00 !important;
}
.app.green .text-danger {
  color: #F34235 !important;
}
.app.green a.text-danger:focus, .app.green a.text-danger:hover {
  color: #D22E2E !important;
}
.app.green .text-gray {
  color: #9D9D9D !important;
}
.app.green a.text-gray:focus, .app.green a.text-gray:hover {
  color: #606060 !important;
}
.app.green .text-inverse {
  color: #292B2C !important;
}
.app.green a.text-inverse:focus, .app.green a.text-inverse:hover {
  color: #1C1E1F !important;
}
.app.green .breadcrumb {
  padding: 0.5rem 1rem;
}
.app.green .breadcrumb a {
  color: #3E50B4;
}
.app.green .btn-primary {
  color: #fff;
  background-color: #3E50B4;
  border-color: #3E50B4;
}
.app.green .btn-primary:hover, .app.green .btn-primary:active, .app.green .btn-primary.active {
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.green .btn-primary:focus, .app.green .btn-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.green .show > .btn-primary.dropdown-toggle {
  background-color: #2F3E9E;
}
.app.green .btn-success {
  color: #fff;
  background-color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.green .btn-success:hover, .app.green .btn-success:active, .app.green .btn-success.active {
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.green .btn-success:focus, .app.green .btn-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.green .show > .btn-success.dropdown-toggle {
  background-color: #378D3B;
}
.app.green .btn-info {
  color: #fff;
  background-color: #00BBD3;
  border-color: #00BBD3;
}
.app.green .btn-info:hover, .app.green .btn-info:active, .app.green .btn-info.active {
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.green .btn-info:focus, .app.green .btn-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.green .show > .btn-info.dropdown-toggle {
  background-color: #0096A6;
}
.app.green .btn-warning {
  color: #fff;
  background-color: #FE9700;
  border-color: #FE9700;
}
.app.green .btn-warning:hover, .app.green .btn-warning:active, .app.green .btn-warning.active {
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.green .btn-warning:focus, .app.green .btn-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.green .show > .btn-warning.dropdown-toggle {
  background-color: #F47B00;
}
.app.green .btn-danger {
  color: #fff;
  background-color: #F34235;
  border-color: #F34235;
}
.app.green .btn-danger:hover, .app.green .btn-danger:active, .app.green .btn-danger.active {
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.green .btn-danger:focus, .app.green .btn-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.green .show > .btn-danger.dropdown-toggle {
  background-color: #D22E2E;
}
.app.green .btn-gray {
  color: #fff;
  background-color: #9D9D9D;
}
.app.green .btn-gray:hover, .app.green .btn-gray:active, .app.green .btn-gray.active {
  background-color: #606060;
}
.app.green .btn-gray:focus, .app.green .btn-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.green .show > .btn-gray.dropdown-toggle {
  background-color: #606060;
}
.app.green .btn-inverse {
  color: #fff;
  background-color: #292B2C;
}
.app.green .btn-inverse:hover, .app.green .btn-inverse:active, .app.green .btn-inverse.active {
  background-color: #1C1E1F;
}
.app.green .btn-inverse:focus, .app.green .btn-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.green .show > .btn-inverse.dropdown-toggle {
  background-color: #1C1E1F;
}
.app.green .btn-rounded {
  border-radius: 5rem;
}
.app.green .btn-outline-primary {
  color: #3E50B4;
  border-color: #3E50B4;
}
.app.green .btn-outline-primary:hover, .app.green .btn-outline-primary:active, .app.green .btn-outline-primary.active {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.green .btn-outline-primary:focus, .app.green .btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.green .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.green .btn-outline-success {
  color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.green .btn-outline-success:hover, .app.green .btn-outline-success:active, .app.green .btn-outline-success.active {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.green .btn-outline-success:focus, .app.green .btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.green .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.green .btn-outline-info {
  color: #00BBD3;
  border-color: #00BBD3;
}
.app.green .btn-outline-info:hover, .app.green .btn-outline-info:active, .app.green .btn-outline-info.active {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.green .btn-outline-info:focus, .app.green .btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.green .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.green .btn-outline-warning {
  color: #FE9700;
  border-color: #FE9700;
}
.app.green .btn-outline-warning:hover, .app.green .btn-outline-warning:active, .app.green .btn-outline-warning.active {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.green .btn-outline-warning:focus, .app.green .btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.green .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.green .btn-outline-danger {
  color: #F34235;
  border-color: #F34235;
}
.app.green .btn-outline-danger:hover, .app.green .btn-outline-danger:active, .app.green .btn-outline-danger.active {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.green .btn-outline-danger:focus, .app.green .btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.green .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.green .btn-outline-gray {
  color: #9D9D9D;
  border-color: #9D9D9D;
  background-color: transparent;
}
.app.green .btn-outline-gray:hover, .app.green .btn-outline-gray:active, .app.green .btn-outline-gray.active {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.green .btn-outline-gray:focus, .app.green .btn-outline-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.green .show > .btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.green .btn-outline-inverse {
  color: #292B2C;
  border-color: #292B2C;
  background-color: transparent;
}
.app.green .btn-outline-inverse:hover, .app.green .btn-outline-inverse:active, .app.green .btn-outline-inverse.active {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.green .btn-outline-inverse:focus, .app.green .btn-outline-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.green .show > .btn-outline-inverse.dropdown-toggle {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.green .btn-link {
  border: 1px solid transparent;
}
.app.green .badge {
  padding: 4px 6px;
}
.app.green .badge-primary {
  background-color: #3E50B4;
}
.app.green .badge-success {
  background-color: #4BAE4F;
}
.app.green .badge-info {
  background-color: #00BBD3;
}
.app.green .badge-warning {
  background-color: #FE9700;
}
.app.green .badge-danger {
  background-color: #F34235;
}
.app.green .badge-gray {
  background-color: #9D9D9D;
}
.app.green .badge-white {
  background-color: #fff;
}
.app.green .alert-primary {
  background-color: #b1c3ff;
  border-color: #97a9ff;
  color: #3E50B4;
}
.app.green .alert-success {
  background-color: #beffc2;
  border-color: #8bee8f;
  color: #4BAE4F;
}
.app.green .alert-info {
  background-color: #a6ffff;
  border-color: #40fbff;
  color: #00BBD3;
}
.app.green .alert-warning {
  background-color: #ffffa6;
  border-color: #fff059;
  color: #FE9700;
}
.app.green .alert-danger {
  background-color: #ffb5a8;
  border-color: #ff9b8e;
  color: #F34235;
}
.app.green .alert-gray {
  background-color: #f3f3f3;
  border-color: #dddddd;
  color: #9D9D9D;
}
.app.green .modal {
  z-index: 999999;
  padding-top: 5%;
}
.app.green .modal-dialog .modal-content .modal-header {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  padding: 10px 15px;
}
.app.green .modal-dialog .modal-content .modal-header.modal-primary {
  color: #fff;
  background-color: #3E50B4;
}
.app.green .modal-dialog .modal-content .modal-header.modal-success {
  color: #fff;
  background-color: #4BAE4F;
}
.app.green .modal-dialog .modal-content .modal-header.modal-info {
  color: #fff;
  background-color: #00BBD3;
}
.app.green .modal-dialog .modal-content .modal-header.modal-warning {
  color: #fff;
  background-color: #FE9700;
}
.app.green .modal-dialog .modal-content .modal-header.modal-danger {
  color: #fff;
  background-color: #F34235;
}
.app.green .modal-dialog .modal-content .modal-header i {
  margin-right: 10px;
}
.app.green .bg-primary {
  background-color: #3E50B4 !important;
}
.app.green .bg-success {
  background-color: #4BAE4F !important;
}
.app.green .bg-info {
  background-color: #00BBD3 !important;
}
.app.green .bg-warning {
  background-color: #FE9700 !important;
}
.app.green .bg-danger {
  background-color: #F34235 !important;
}
.app.green .bg-gray {
  background-color: #9D9D9D !important;
}
.app.green .bg-light {
  background-color: #f5f5f5 !important;
}
.app.green .progress-xl {
  height: 2rem;
}
.app.green .progress-lg {
  height: 1.5rem;
}
.app.green .progress-md {
  height: 1rem;
}
.app.green .progress-sm {
  height: 0.5rem;
}
.app.green .progress-xs {
  height: 0.25rem;
}
.app.green .nav-tabs.custom {
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
  width: 100%;
}
.app.green .nav-tabs.custom .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border: none;
  cursor: default;
}
.app.green .nav-tabs.custom .nav-item .nav-link.active {
  background-color: #fff;
  color: #606060;
}
.app.green .nav-tabs.custom .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.green .nav-tabs.custom.top {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.app.green .nav-tabs.custom.bottom {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.app.green .nav-tabs.custom.bottom .nav-item {
  margin-bottom: 0;
  margin-top: -1px;
}
.app.green .nav-tabs.custom.bottom .nav-item .nav-link {
  border-radius: 0 0 0.3rem 0.3rem;
}
.app.green .nav-tabs.custom.tabs-primary {
  background-color: rgba(62, 80, 180, 0.4);
  border: 1px solid #3E50B4;
}
.app.green .nav-tabs.custom.tabs-primary .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.green .nav-tabs.custom.tabs-primary .nav-item .nav-link.active {
  background-color: #3E50B4;
  color: #fff;
}
.app.green .nav-tabs.custom.tabs-primary .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.green .nav-tabs.custom.tabs-success {
  background-color: rgba(75, 174, 79, 0.4);
  border: 1px solid #4BAE4F;
}
.app.green .nav-tabs.custom.tabs-success .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.green .nav-tabs.custom.tabs-success .nav-item .nav-link.active {
  background-color: #4BAE4F;
  color: #fff;
}
.app.green .nav-tabs.custom.tabs-success .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.green .nav-tabs.custom.tabs-info {
  background-color: rgba(0, 187, 211, 0.4);
  border: 1px solid #00BBD3;
}
.app.green .nav-tabs.custom.tabs-info .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.green .nav-tabs.custom.tabs-info .nav-item .nav-link.active {
  background-color: #00BBD3;
  color: #fff;
}
.app.green .nav-tabs.custom.tabs-info .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.green .nav-tabs.custom.tabs-warning {
  background-color: rgba(254, 151, 0, 0.4);
  border: 1px solid #FE9700;
}
.app.green .nav-tabs.custom.tabs-warning .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.green .nav-tabs.custom.tabs-warning .nav-item .nav-link.active {
  background-color: #FE9700;
  color: #fff;
}
.app.green .nav-tabs.custom.tabs-warning .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.green .nav-tabs.custom.tabs-danger {
  background-color: rgba(243, 66, 53, 0.4);
  border: 1px solid #F34235;
}
.app.green .nav-tabs.custom.tabs-danger .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.green .nav-tabs.custom.tabs-danger .nav-item .nav-link.active {
  background-color: #F34235;
  color: #fff;
}
.app.green .nav-tabs.custom.tabs-danger .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.green .tab-content {
  position: relative;
  z-index: 1;
  border: 1px solid #9D9D9D;
  background: #fff;
}
.app.green .tab-content .tab-pane {
  padding: 1rem;
}
.app.green .tab-content.tab-content-primary {
  background-color: #3E50B4;
  color: #fff;
  border-color: #3E50B4;
}
.app.green .tab-content.tab-content-success {
  background-color: #4BAE4F;
  color: #fff;
  border-color: #4BAE4F;
}
.app.green .tab-content.tab-content-info {
  background-color: #00BBD3;
  color: #fff;
  border-color: #00BBD3;
}
.app.green .tab-content.tab-content-warning {
  background-color: #FE9700;
  color: #fff;
  border-color: #FE9700;
}
.app.green .tab-content.tab-content-danger {
  background-color: #F34235;
  color: #fff;
  border-color: #F34235;
}
.app.green .tab-content.top {
  border-top: none;
}
.app.green .tab-content.bottom {
  border-bottom: none;
}
.app.green .vertical-tabs .nav {
  padding-right: 0;
  overflow: hidden;
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
}
.app.green .vertical-tabs .nav.left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-right: none;
  margin-right: -1px;
  z-index: 2;
}
.app.green .vertical-tabs .nav.right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-left: none;
  margin-left: -1px;
  z-index: 2;
}
.app.green .vertical-tabs .nav .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border-radius: 0;
  cursor: default;
}
.app.green .vertical-tabs .nav .nav-item .nav-link.active {
  background-color: #fff;
}
.app.green .vertical-tabs .nav .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.green .vertical-tabs .nav .nav-item .nav-link.disabled {
  color: rgba(96, 96, 96, 0.6);
  cursor: not-allowed !important;
}
.app.green .list-group-item-primary {
  color: #3E50B4;
  background-color: #b1c3ff;
}
.app.green .list-group-item-success {
  color: #4BAE4F;
  background-color: #beffc2;
}
.app.green .list-group-item-info {
  color: #00BBD3;
  background-color: #a6ffff;
}
.app.green .list-group-item-warning {
  color: #FE9700;
  background-color: #ffffa6;
}
.app.green .list-group-item-danger {
  color: #F34235;
  background-color: #ffb5a8;
}
.app.green .no-caret.dropdown-toggle::after {
  content: none;
}
.app.green .dropdown-item.active, .app.green .dropdown-item:active {
  background-color: #ccc;
}
.app.green .dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.app.green .dropdown.show .dropdown-menu {
  max-height: 300px;
  opacity: 1;
  pointer-events: auto;
}
.app.green .dropdown.open .dropdown-menu {
  opacity: 1;
  pointer-events: auto;
}
.app.green .dropdown-menu-right {
  right: 0;
  left: auto;
}
.app.green .form-group label {
  margin-bottom: 0.2rem;
}
.app.green .form-control-rounded {
  border-radius: 16px;
}
.app.green .form-control:focus {
  border-color: rgba(0, 150, 136, 0.6);
}
.app.green input[type=color].form-control {
  padding: 0;
}
.app.green .has-success .form-control {
  border-color: #4BAE4F;
}
.app.green .has-success .form-control:focus {
  border-color: #378D3B;
}
.app.green .has-success .form-control-feedback, .app.green .has-success .form-control-label, .app.green .has-success .form-check-label, .app.green .has-success .form-check-inline, .app.green .has-success .custom-control {
  color: #4BAE4F;
}
.app.green .has-warning .form-control {
  border-color: #FE9700;
}
.app.green .has-warning .form-control:focus {
  border-color: #F47B00;
}
.app.green .has-warning .form-control-feedback, .app.green .has-warning .form-control-label, .app.green .has-warning .form-check-label, .app.green .has-warning .form-check-inline, .app.green .has-warning .custom-control {
  color: #FE9700;
}
.app.green .has-danger .form-control {
  border-color: #F34235;
}
.app.green .has-danger .form-control:focus {
  border-color: #D22E2E;
}
.app.green .has-danger .form-control-feedback, .app.green .has-danger .form-control-label, .app.green .has-danger .form-check-label, .app.green .has-danger .form-check-inline, .app.green .has-danger .custom-control {
  color: #F34235;
}
.app.green .has-success .input-group-prepend,
.app.green .has-success .input-group-append,
.app.green .has-success .input-group-prepend .input-group-text,
.app.green .has-success .input-group-append .input-group-text {
  color: #4BAE4F;
  border-color: #4BAE4F;
  background-color: #eaf6ea;
}
.app.green .has-danger .input-group-prepend,
.app.green .has-danger .input-group-append,
.app.green .has-danger .input-group-prepend .input-group-text,
.app.green .has-danger .input-group-append .input-group-text {
  color: #F34235;
  border-color: #F34235;
  background-color: #fdf7f7;
}
.app.green .form-control.validation-field.ng-touched {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.625rem;
  background-size: 1.25rem 1.25rem;
}
.app.green .form-control.validation-field.ng-touched.ng-invalid {
  border-color: #F34235;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23F34235' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
}
.app.green .form-control.validation-field.ng-touched.ng-valid {
  border-color: #4BAE4F;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%234BAE4F' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}
.app.green .custom-control-label::before {
  width: 14px;
  height: 14px;
  top: 0.3rem;
}
.app.green .custom-control-label::after {
  width: 14px;
  height: 14px;
  top: 0.3rem;
  background-size: 60% 60%;
}
.app.green .custom-checkbox.checkbox-circle .custom-control-label::before,
.app.green .custom-checkbox.checkbox-circle .custom-control-label::after {
  border-radius: 50%;
}
.app.green .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.green .custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.green .custom-checkbox .custom-control-input.checkbox-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.green .custom-checkbox .custom-control-input.checkbox-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.green .custom-checkbox .custom-control-input.checkbox-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.green .custom-checkbox .custom-control-input.checkbox-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.green .custom-checkbox .custom-control-input.checkbox-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.green .custom-checkbox .custom-control-input.checkbox-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.green .custom-checkbox .custom-control-input.checkbox-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.green .custom-checkbox .custom-control-input.checkbox-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.green .custom-checkbox .custom-control-input.checkbox-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.green .custom-checkbox .custom-control-input.checkbox-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.green .custom-checkbox .custom-control-input.checkbox-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.green .custom-checkbox .custom-control-input.checkbox-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.green .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.green .custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.green .custom-radio .custom-control-input.radio-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.green .custom-radio .custom-control-input.radio-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.green .custom-radio .custom-control-input.radio-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.green .custom-radio .custom-control-input.radio-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.green .custom-radio .custom-control-input.radio-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.green .custom-radio .custom-control-input.radio-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.green .custom-radio .custom-control-input.radio-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.green .custom-radio .custom-control-input.radio-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.green .custom-radio .custom-control-input.radio-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.green .custom-radio .custom-control-input.radio-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.green .custom-radio .custom-control-input.radio-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.green .custom-radio .custom-control-input.radio-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.green .table-success,
.app.green .table-success > th,
.app.green .table-success > td {
  background-color: #4BAE4F;
}
.app.green .table-hover .table-success:hover {
  background-color: #439c47;
}
.app.green .table-hover .table-success:hover > td,
.app.green .table-hover .table-success:hover > th {
  background-color: #439c47;
}
.app.green .table-info,
.app.green .table-info > th,
.app.green .table-info > td {
  background-color: #00BBD3;
}
.app.green .table-hover .table-info:hover {
  background-color: #00a4ba;
}
.app.green .table-hover .table-info:hover > td,
.app.green .table-hover .table-info:hover > th {
  background-color: #00a4ba;
}
.app.green .table-warning,
.app.green .table-warning > th,
.app.green .table-warning > td {
  background-color: #FE9700;
}
.app.green .table-hover .table-warning:hover {
  background-color: #e58800;
}
.app.green .table-hover .table-warning:hover > td,
.app.green .table-hover .table-warning:hover > th {
  background-color: #e58800;
}
.app.green .table-danger,
.app.green .table-danger > th,
.app.green .table-danger > td {
  background-color: #F34235;
}
.app.green .table-hover .table-danger:hover {
  background-color: #f22b1d;
}
.app.green .table-hover .table-danger:hover > td,
.app.green .table-hover .table-danger:hover > th {
  background-color: #f22b1d;
}
.app.green .rounded-0 {
  border-radius: 0 !important;
}
.app.green .popover.bs-popover-top .arrow,
.app.green .popover.bs-popover-auto[x-placement^=top] .arrow,
.app.green .popover.bs-popover-auto[x-placement^=bottom] .arrow,
.app.green .popover.bs-popover-bottom .arrow {
  left: 50%;
}
.app.green .popover.bs-popover-auto[x-placement^=right] .arrow,
.app.green .popover.bs-popover-right .arrow,
.app.green .popover.bs-popover-auto[x-placement^=left] .arrow,
.app.green .popover.bs-popover-left .arrow {
  top: 50%;
}
.app.green .popover.bs-popover-bottom .arrow::after,
.app.green .popover.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #f7f7f7;
}
.app.green .top-navbar {
  background-color: #00786A;
  color: #B1DEDA;
}
.app.green .top-navbar .app-search {
  border-bottom: 1px solid #B1DEDA;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.green .top-navbar .app-search .form-control {
  color: #B1DEDA;
}
.app.green .top-navbar .app-search input[type=text]::-webkit-input-placeholder {
  color: rgba(177, 222, 218, 0.8);
}
.app.green .top-navbar .app-search input[type=text]::-moz-placeholder {
  color: rgba(177, 222, 218, 0.8);
}
.app.green .top-navbar .app-search input[type=text]:-moz-placeholder {
  color: rgba(177, 222, 218, 0.8);
}
.app.green .top-navbar .app-search input[type=text]:-ms-input-placeholder {
  color: rgba(177, 222, 218, 0.8);
}
.app.green .top-navbar .app-search textarea::-webkit-input-placeholder {
  color: rgba(177, 222, 218, 0.8);
}
.app.green .top-navbar .app-search textarea::-moz-placeholder {
  color: rgba(177, 222, 218, 0.8);
}
.app.green .top-navbar .app-search textarea:-moz-placeholder {
  color: rgba(177, 222, 218, 0.8);
}
.app.green .top-navbar .app-search textarea:-ms-input-placeholder {
  color: rgba(177, 222, 218, 0.8);
}
.app.green .top-navbar .app-search i {
  color: #B1DEDA;
}
.app.green .top-navbar a {
  color: #B1DEDA;
}
.app.green .logo {
  color: #fff !important;
}
.app.green .logo .ng {
  color: #fff;
}
.app.green .menu-icon .line {
  background-color: #B1DEDA;
}
.app.green #info-content-icon .triangle {
  border-color: #B1DEDA transparent transparent transparent;
}
.app.green #info-content-icon.show {
  background: #009688;
  box-shadow: 0px 0px 0px 8px #009688, 0px -16px 0px 8px #009688;
}
.app.green #info-content-icon.show .triangle {
  border-color: #B1DEDA transparent transparent transparent;
}
.app.green .menu-wrapper .card a {
  color: #B1DEDA;
  border-bottom: 1px solid rgba(0, 120, 106, 0.5);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1);
}
.app.green .menu-wrapper .card a:hover {
  color: #fff;
  background-color: #00786A !important;
}
.app.green .menu-wrapper .card a.active-link {
  background-color: #1bc3b4 !important;
  color: #fff;
}
.app.green .menu-wrapper .card .card a {
  padding-left: 15px;
  background-color: rgba(0, 0, 0, 0.3);
}
.app.green .menu-wrapper .card .card .card a {
  padding-left: 30px;
  background-color: rgba(0, 0, 0, 0.4);
}
.app.green .menu-wrapper .card .card .card .card a {
  padding-left: 45px;
  background-color: rgba(0, 0, 0, 0.5);
}
.app.green .menu-wrapper .card .card .card .card .card a {
  padding-left: 60px;
  background-color: rgba(0, 0, 0, 0.6);
}
.app.green .menu-wrapper .card .card .card .card .card .card a {
  padding-left: 75px;
  background-color: rgba(0, 0, 0, 0.7);
}
.app.green .menu-wrapper .card .card .card .card .card .card .card a {
  padding-left: 90px;
  background-color: rgba(0, 0, 0, 0.8);
}
.app.green .menu-wrapper .card .card .card .card .card .card .card .card a {
  padding-left: 105px;
  background-color: rgba(0, 0, 0, 0.9);
}
.app.green .menu-wrapper .card .card .card .card .card .card .card .card .card a {
  padding-left: 120px;
  background-color: black;
}
.app.green .menu-wrapper .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 135px;
  background-color: black;
}
.app.green .menu-wrapper .card .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 150px;
  background-color: black;
}
.app.green .wrapper, .app.green .wrapper-inner {
  background-color: #f5f5f5;
  color: #565656;
}
.app.green #info-content {
  background-color: #009688;
}
.app.green #info-content a.close {
  color: #B1DEDA;
}
.app.green #info-content .info {
  color: #B1DEDA;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.green #info-content .info .form-control {
  background-color: #009688;
  border-color: rgba(177, 222, 218, 0.4);
  color: #B1DEDA;
}
.app.green #info-content .info input[type=text]::-webkit-input-placeholder {
  color: rgba(177, 222, 218, 0.7);
}
.app.green #info-content .info input[type=text]::-moz-placeholder {
  color: rgba(177, 222, 218, 0.7);
}
.app.green #info-content .info input[type=text]:-moz-placeholder {
  color: rgba(177, 222, 218, 0.7);
}
.app.green #info-content .info input[type=text]:-ms-input-placeholder {
  color: rgba(177, 222, 218, 0.7);
}
.app.green #info-content .info textarea::-webkit-input-placeholder {
  color: rgba(177, 222, 218, 0.7);
}
.app.green #info-content .info textarea::-moz-placeholder {
  color: rgba(177, 222, 218, 0.7);
}
.app.green #info-content .info textarea:-moz-placeholder {
  color: rgba(177, 222, 218, 0.7);
}
.app.green #info-content .info textarea:-ms-input-placeholder {
  color: rgba(177, 222, 218, 0.7);
}
.app.green #info-content .left-border {
  border-left: 1px solid rgba(177, 222, 218, 0.3);
}
.app.green #messagesDropDown .dropdown-menu .nav-link.active {
  border-bottom: 4px solid #00786A;
}
.app.green .dropdown-menu .user-menu .user-info {
  background-color: #00786A;
  color: #B1DEDA;
}
.app.green .favorites-container {
  border-left: 1px solid rgba(177, 222, 218, 0.4);
  border-right: 1px solid rgba(177, 222, 218, 0.4);
}
.app.green #favorites .dropdown .btn-block {
  background-color: #00786A;
  color: #B1DEDA;
}
.app.green .side-chat .media {
  color: #B1DEDA;
  background-color: #009688;
  border-bottom: 1px solid #f5f5f5;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.green .side-chat .media input[type=text]::-webkit-input-placeholder {
  color: rgba(177, 222, 218, 0.8);
}
.app.green .side-chat .media input[type=text]::-moz-placeholder {
  color: rgba(177, 222, 218, 0.8);
}
.app.green .side-chat .media input[type=text]:-moz-placeholder {
  color: rgba(177, 222, 218, 0.8);
}
.app.green .side-chat .media input[type=text]:-ms-input-placeholder {
  color: rgba(177, 222, 218, 0.8);
}
.app.green .side-chat .media textarea::-webkit-input-placeholder {
  color: rgba(177, 222, 218, 0.8);
}
.app.green .side-chat .media textarea::-moz-placeholder {
  color: rgba(177, 222, 218, 0.8);
}
.app.green .side-chat .media textarea:-moz-placeholder {
  color: rgba(177, 222, 218, 0.8);
}
.app.green .side-chat .media textarea:-ms-input-placeholder {
  color: rgba(177, 222, 218, 0.8);
}
.app.green .side-chat .chat-window {
  color: #B1DEDA;
  background-color: #009688;
}
.app.green .side-chat .chat-window .header {
  border-bottom: 1px solid #B1DEDA;
}
.app.green .sidebar-outer {
  background-color: #009688;
}
.app.green .sidebar-outer .logo {
  background-color: #00786A;
}
.app.green .vertical-menu-tooltip-place .tooltip {
  opacity: 1;
}
.app.green .vertical-menu-tooltip-place .tooltip.bs-tooltip-right .arrow::before, .app.green .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #009688;
}
.app.green .vertical-menu-tooltip-place .tooltip.bs-tooltip-bottom .arrow::before, .app.green .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #009688;
}
.app.green .vertical-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #009688;
}
.app.green .horizontal-menu-outer {
  background-color: #009688;
}
.app.green #navigation .menu li {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.app.green #navigation .menu li a {
  color: #B1DEDA;
}
.app.green #navigation .menu li a:hover {
  color: #fff;
  background-color: #00786A !important;
}
.app.green #navigation .menu li a.active-link {
  background-color: #1bc3b4 !important;
  color: #fff;
}
.app.green #navigation .menu li:first-child {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.app.green #navigation .menu .sub-menu {
  background-color: #009688;
}
.app.green #navigation ul.menu ul a,
.app.green #navigation .menu ul ul a {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
}
.app.green .horizontal-menu-tooltip-place .tooltip {
  opacity: 1;
  z-index: 999999;
}
.app.green .horizontal-menu-tooltip-place .tooltip.bs-tooltip-top .arrow::before, .app.green .horizontal-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #000;
}
.app.green .horizontal-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #000 !important;
}
.app.green .app-footer {
  background-color: #00786A;
  color: #B1DEDA;
}
.app.green .login-container .card, .app.green .register-container .card, .app.green .error-container .card {
  background-color: #1bc3b4;
  color: #F5F5F5;
}
.app.green .login-container .card .card-header, .app.green .register-container .card .card-header, .app.green .error-container .card .card-header {
  background-color: #00786A;
}
.app.green .login-container .card .btn, .app.green .register-container .card .btn, .app.green .error-container .card .btn {
  background-color: #00786A;
  color: #F5F5F5;
}
.app.green .box-shadow {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 150, 136, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 150, 136, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(0, 150, 136, 0.3);
}
.app.green .widget-controls a {
  color: #a6a6a6;
}
.app.green .widget-controls a:hover {
  color: rgba(166, 166, 166, 0.7);
}
.app.combined {
  /*Card*/
  /*Text*/
  /*Breadcrumb*/
  /*Buttons*/
  /*Badge*/
  /*Alerts*/
  /* MODALS */
  /*BG*/
  /*Progress*/
  /*Tabs*/
  /*List group*/
  /*DropDown*/
  /* actual dropdown animation */
  /*Forms*/
  /*Tables*/
  /*Popovers*/
  /*Top Navbar*/
  /*Side-Chat*/
  /*Sidebar*/
  /*Horizontal-menu*/
  /*Footer*/
  /*Login & register & error*/
  /*Global classes*/
  /*Libs */
}
.app.combined .card .card-header {
  background-color: #f3f3f3;
}
.app.combined .card .card-header.transparent {
  background-color: transparent;
}
.app.combined .card.card-primary {
  background-color: #3E50B4;
  border-color: #2F3E9E;
}
.app.combined .card.card-primary .card-header {
  background-color: #2F3E9E;
}
.app.combined .card.card-primary .card-footer {
  background-color: rgba(47, 62, 158, 0.5);
}
.app.combined .card.card-success {
  background-color: #4BAE4F;
  border-color: #378D3B;
}
.app.combined .card.card-success .card-header {
  background-color: #378D3B;
}
.app.combined .card.card-success .card-footer {
  background-color: rgba(55, 141, 59, 0.5);
}
.app.combined .card.card-info {
  background-color: #00BBD3;
  border-color: #0096A6;
}
.app.combined .card.card-info .card-header {
  background-color: #0096A6;
}
.app.combined .card.card-info .card-footer {
  background-color: rgba(0, 150, 166, 0.5);
}
.app.combined .card.card-warning {
  background-color: #FE9700;
  border-color: #F47B00;
}
.app.combined .card.card-warning .card-header {
  background-color: #F47B00;
}
.app.combined .card.card-warning .card-footer {
  background-color: rgba(244, 123, 0, 0.5);
}
.app.combined .card.card-danger {
  background-color: #F34235;
  border-color: #D22E2E;
}
.app.combined .card.card-danger .card-header {
  background-color: #D22E2E;
}
.app.combined .card.card-danger .card-footer {
  background-color: rgba(210, 46, 46, 0.5);
}
.app.combined .card.card-outline-primary {
  background-color: transparent;
  border: 1px solid #3E50B4;
}
.app.combined .card.card-outline-success {
  background-color: transparent;
  border: 1px solid #4BAE4F;
}
.app.combined .card.card-outline-info {
  background-color: transparent;
  border: 1px solid #00BBD3;
}
.app.combined .card.card-outline-warning {
  background-color: transparent;
  border: 1px solid #FE9700;
}
.app.combined .card.card-outline-danger {
  background-color: transparent;
  border: 1px solid #F34235;
}
.app.combined .card-img-overlay {
  color: #262626;
}
.app.combined .card-inverse .card-link,
.app.combined .card-inverse .card-text,
.app.combined .card-inverse .card-subtitle,
.app.combined .card-inverse .card-blockquote .blockquote-footer {
  color: #f5f5f5;
}
.app.combined .overlay-primary {
  background-color: rgba(62, 80, 180, 0.8);
}
.app.combined .overlay-success {
  background-color: rgba(75, 174, 79, 0.8);
}
.app.combined .overlay-info {
  background-color: rgba(0, 187, 211, 0.8);
}
.app.combined .overlay-warning {
  background-color: rgba(254, 151, 0, 0.8);
}
.app.combined .overlay-danger {
  background-color: rgba(243, 66, 53, 0.8);
}
.app.combined .text-primary {
  color: #3E50B4 !important;
}
.app.combined a.text-primary:focus, .app.combined a.text-primary:hover {
  color: #2F3E9E !important;
}
.app.combined .text-success {
  color: #4BAE4F !important;
}
.app.combined a.text-success:focus, .app.combined a.text-success:hover {
  color: #378D3B !important;
}
.app.combined .text-info {
  color: #00BBD3 !important;
}
.app.combined a.text-info:focus, .app.combined a.text-info:hover {
  color: #0096A6 !important;
}
.app.combined .text-warning {
  color: #FE9700 !important;
}
.app.combined a.text-warning:focus, .app.combined a.text-warning:hover {
  color: #F47B00 !important;
}
.app.combined .text-danger {
  color: #F34235 !important;
}
.app.combined a.text-danger:focus, .app.combined a.text-danger:hover {
  color: #D22E2E !important;
}
.app.combined .text-gray {
  color: #9D9D9D !important;
}
.app.combined a.text-gray:focus, .app.combined a.text-gray:hover {
  color: #606060 !important;
}
.app.combined .text-inverse {
  color: #292B2C !important;
}
.app.combined a.text-inverse:focus, .app.combined a.text-inverse:hover {
  color: #1C1E1F !important;
}
.app.combined .breadcrumb {
  padding: 0.5rem 1rem;
}
.app.combined .breadcrumb a {
  color: #3E50B4;
}
.app.combined .btn-primary {
  color: #fff;
  background-color: #3E50B4;
  border-color: #3E50B4;
}
.app.combined .btn-primary:hover, .app.combined .btn-primary:active, .app.combined .btn-primary.active {
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.combined .btn-primary:focus, .app.combined .btn-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.combined .show > .btn-primary.dropdown-toggle {
  background-color: #2F3E9E;
}
.app.combined .btn-success {
  color: #fff;
  background-color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.combined .btn-success:hover, .app.combined .btn-success:active, .app.combined .btn-success.active {
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.combined .btn-success:focus, .app.combined .btn-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.combined .show > .btn-success.dropdown-toggle {
  background-color: #378D3B;
}
.app.combined .btn-info {
  color: #fff;
  background-color: #00BBD3;
  border-color: #00BBD3;
}
.app.combined .btn-info:hover, .app.combined .btn-info:active, .app.combined .btn-info.active {
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.combined .btn-info:focus, .app.combined .btn-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.combined .show > .btn-info.dropdown-toggle {
  background-color: #0096A6;
}
.app.combined .btn-warning {
  color: #fff;
  background-color: #FE9700;
  border-color: #FE9700;
}
.app.combined .btn-warning:hover, .app.combined .btn-warning:active, .app.combined .btn-warning.active {
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.combined .btn-warning:focus, .app.combined .btn-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.combined .show > .btn-warning.dropdown-toggle {
  background-color: #F47B00;
}
.app.combined .btn-danger {
  color: #fff;
  background-color: #F34235;
  border-color: #F34235;
}
.app.combined .btn-danger:hover, .app.combined .btn-danger:active, .app.combined .btn-danger.active {
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.combined .btn-danger:focus, .app.combined .btn-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.combined .show > .btn-danger.dropdown-toggle {
  background-color: #D22E2E;
}
.app.combined .btn-gray {
  color: #fff;
  background-color: #9D9D9D;
}
.app.combined .btn-gray:hover, .app.combined .btn-gray:active, .app.combined .btn-gray.active {
  background-color: #606060;
}
.app.combined .btn-gray:focus, .app.combined .btn-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.combined .show > .btn-gray.dropdown-toggle {
  background-color: #606060;
}
.app.combined .btn-inverse {
  color: #fff;
  background-color: #292B2C;
}
.app.combined .btn-inverse:hover, .app.combined .btn-inverse:active, .app.combined .btn-inverse.active {
  background-color: #1C1E1F;
}
.app.combined .btn-inverse:focus, .app.combined .btn-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.combined .show > .btn-inverse.dropdown-toggle {
  background-color: #1C1E1F;
}
.app.combined .btn-rounded {
  border-radius: 5rem;
}
.app.combined .btn-outline-primary {
  color: #3E50B4;
  border-color: #3E50B4;
}
.app.combined .btn-outline-primary:hover, .app.combined .btn-outline-primary:active, .app.combined .btn-outline-primary.active {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.combined .btn-outline-primary:focus, .app.combined .btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.combined .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.combined .btn-outline-success {
  color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.combined .btn-outline-success:hover, .app.combined .btn-outline-success:active, .app.combined .btn-outline-success.active {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.combined .btn-outline-success:focus, .app.combined .btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.combined .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.combined .btn-outline-info {
  color: #00BBD3;
  border-color: #00BBD3;
}
.app.combined .btn-outline-info:hover, .app.combined .btn-outline-info:active, .app.combined .btn-outline-info.active {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.combined .btn-outline-info:focus, .app.combined .btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.combined .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.combined .btn-outline-warning {
  color: #FE9700;
  border-color: #FE9700;
}
.app.combined .btn-outline-warning:hover, .app.combined .btn-outline-warning:active, .app.combined .btn-outline-warning.active {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.combined .btn-outline-warning:focus, .app.combined .btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.combined .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.combined .btn-outline-danger {
  color: #F34235;
  border-color: #F34235;
}
.app.combined .btn-outline-danger:hover, .app.combined .btn-outline-danger:active, .app.combined .btn-outline-danger.active {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.combined .btn-outline-danger:focus, .app.combined .btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.combined .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.combined .btn-outline-gray {
  color: #9D9D9D;
  border-color: #9D9D9D;
  background-color: transparent;
}
.app.combined .btn-outline-gray:hover, .app.combined .btn-outline-gray:active, .app.combined .btn-outline-gray.active {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.combined .btn-outline-gray:focus, .app.combined .btn-outline-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.combined .show > .btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.combined .btn-outline-inverse {
  color: #292B2C;
  border-color: #292B2C;
  background-color: transparent;
}
.app.combined .btn-outline-inverse:hover, .app.combined .btn-outline-inverse:active, .app.combined .btn-outline-inverse.active {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.combined .btn-outline-inverse:focus, .app.combined .btn-outline-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.combined .show > .btn-outline-inverse.dropdown-toggle {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.combined .btn-link {
  border: 1px solid transparent;
}
.app.combined .badge {
  padding: 4px 6px;
}
.app.combined .badge-primary {
  background-color: #3E50B4;
}
.app.combined .badge-success {
  background-color: #4BAE4F;
}
.app.combined .badge-info {
  background-color: #00BBD3;
}
.app.combined .badge-warning {
  background-color: #FE9700;
}
.app.combined .badge-danger {
  background-color: #F34235;
}
.app.combined .badge-gray {
  background-color: #9D9D9D;
}
.app.combined .badge-white {
  background-color: #fff;
}
.app.combined .alert-primary {
  background-color: #b1c3ff;
  border-color: #97a9ff;
  color: #3E50B4;
}
.app.combined .alert-success {
  background-color: #beffc2;
  border-color: #8bee8f;
  color: #4BAE4F;
}
.app.combined .alert-info {
  background-color: #a6ffff;
  border-color: #40fbff;
  color: #00BBD3;
}
.app.combined .alert-warning {
  background-color: #ffffa6;
  border-color: #fff059;
  color: #FE9700;
}
.app.combined .alert-danger {
  background-color: #ffb5a8;
  border-color: #ff9b8e;
  color: #F34235;
}
.app.combined .alert-gray {
  background-color: #f3f3f3;
  border-color: #dddddd;
  color: #9D9D9D;
}
.app.combined .modal {
  z-index: 999999;
  padding-top: 5%;
}
.app.combined .modal-dialog .modal-content .modal-header {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  padding: 10px 15px;
}
.app.combined .modal-dialog .modal-content .modal-header.modal-primary {
  color: #fff;
  background-color: #3E50B4;
}
.app.combined .modal-dialog .modal-content .modal-header.modal-success {
  color: #fff;
  background-color: #4BAE4F;
}
.app.combined .modal-dialog .modal-content .modal-header.modal-info {
  color: #fff;
  background-color: #00BBD3;
}
.app.combined .modal-dialog .modal-content .modal-header.modal-warning {
  color: #fff;
  background-color: #FE9700;
}
.app.combined .modal-dialog .modal-content .modal-header.modal-danger {
  color: #fff;
  background-color: #F34235;
}
.app.combined .modal-dialog .modal-content .modal-header i {
  margin-right: 10px;
}
.app.combined .bg-primary {
  background-color: #3E50B4 !important;
}
.app.combined .bg-success {
  background-color: #4BAE4F !important;
}
.app.combined .bg-info {
  background-color: #00BBD3 !important;
}
.app.combined .bg-warning {
  background-color: #FE9700 !important;
}
.app.combined .bg-danger {
  background-color: #F34235 !important;
}
.app.combined .bg-gray {
  background-color: #9D9D9D !important;
}
.app.combined .bg-light {
  background-color: #f5f5f5 !important;
}
.app.combined .progress-xl {
  height: 2rem;
}
.app.combined .progress-lg {
  height: 1.5rem;
}
.app.combined .progress-md {
  height: 1rem;
}
.app.combined .progress-sm {
  height: 0.5rem;
}
.app.combined .progress-xs {
  height: 0.25rem;
}
.app.combined .nav-tabs.custom {
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
  width: 100%;
}
.app.combined .nav-tabs.custom .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border: none;
  cursor: default;
}
.app.combined .nav-tabs.custom .nav-item .nav-link.active {
  background-color: #fff;
  color: #606060;
}
.app.combined .nav-tabs.custom .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.combined .nav-tabs.custom.top {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.app.combined .nav-tabs.custom.bottom {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.app.combined .nav-tabs.custom.bottom .nav-item {
  margin-bottom: 0;
  margin-top: -1px;
}
.app.combined .nav-tabs.custom.bottom .nav-item .nav-link {
  border-radius: 0 0 0.3rem 0.3rem;
}
.app.combined .nav-tabs.custom.tabs-primary {
  background-color: rgba(62, 80, 180, 0.4);
  border: 1px solid #3E50B4;
}
.app.combined .nav-tabs.custom.tabs-primary .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.combined .nav-tabs.custom.tabs-primary .nav-item .nav-link.active {
  background-color: #3E50B4;
  color: #fff;
}
.app.combined .nav-tabs.custom.tabs-primary .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.combined .nav-tabs.custom.tabs-success {
  background-color: rgba(75, 174, 79, 0.4);
  border: 1px solid #4BAE4F;
}
.app.combined .nav-tabs.custom.tabs-success .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.combined .nav-tabs.custom.tabs-success .nav-item .nav-link.active {
  background-color: #4BAE4F;
  color: #fff;
}
.app.combined .nav-tabs.custom.tabs-success .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.combined .nav-tabs.custom.tabs-info {
  background-color: rgba(0, 187, 211, 0.4);
  border: 1px solid #00BBD3;
}
.app.combined .nav-tabs.custom.tabs-info .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.combined .nav-tabs.custom.tabs-info .nav-item .nav-link.active {
  background-color: #00BBD3;
  color: #fff;
}
.app.combined .nav-tabs.custom.tabs-info .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.combined .nav-tabs.custom.tabs-warning {
  background-color: rgba(254, 151, 0, 0.4);
  border: 1px solid #FE9700;
}
.app.combined .nav-tabs.custom.tabs-warning .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.combined .nav-tabs.custom.tabs-warning .nav-item .nav-link.active {
  background-color: #FE9700;
  color: #fff;
}
.app.combined .nav-tabs.custom.tabs-warning .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.combined .nav-tabs.custom.tabs-danger {
  background-color: rgba(243, 66, 53, 0.4);
  border: 1px solid #F34235;
}
.app.combined .nav-tabs.custom.tabs-danger .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.combined .nav-tabs.custom.tabs-danger .nav-item .nav-link.active {
  background-color: #F34235;
  color: #fff;
}
.app.combined .nav-tabs.custom.tabs-danger .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.combined .tab-content {
  position: relative;
  z-index: 1;
  border: 1px solid #9D9D9D;
  background: #fff;
}
.app.combined .tab-content .tab-pane {
  padding: 1rem;
}
.app.combined .tab-content.tab-content-primary {
  background-color: #3E50B4;
  color: #fff;
  border-color: #3E50B4;
}
.app.combined .tab-content.tab-content-success {
  background-color: #4BAE4F;
  color: #fff;
  border-color: #4BAE4F;
}
.app.combined .tab-content.tab-content-info {
  background-color: #00BBD3;
  color: #fff;
  border-color: #00BBD3;
}
.app.combined .tab-content.tab-content-warning {
  background-color: #FE9700;
  color: #fff;
  border-color: #FE9700;
}
.app.combined .tab-content.tab-content-danger {
  background-color: #F34235;
  color: #fff;
  border-color: #F34235;
}
.app.combined .tab-content.top {
  border-top: none;
}
.app.combined .tab-content.bottom {
  border-bottom: none;
}
.app.combined .vertical-tabs .nav {
  padding-right: 0;
  overflow: hidden;
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
}
.app.combined .vertical-tabs .nav.left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-right: none;
  margin-right: -1px;
  z-index: 2;
}
.app.combined .vertical-tabs .nav.right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-left: none;
  margin-left: -1px;
  z-index: 2;
}
.app.combined .vertical-tabs .nav .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border-radius: 0;
  cursor: default;
}
.app.combined .vertical-tabs .nav .nav-item .nav-link.active {
  background-color: #fff;
}
.app.combined .vertical-tabs .nav .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.combined .vertical-tabs .nav .nav-item .nav-link.disabled {
  color: rgba(96, 96, 96, 0.6);
  cursor: not-allowed !important;
}
.app.combined .list-group-item-primary {
  color: #3E50B4;
  background-color: #b1c3ff;
}
.app.combined .list-group-item-success {
  color: #4BAE4F;
  background-color: #beffc2;
}
.app.combined .list-group-item-info {
  color: #00BBD3;
  background-color: #a6ffff;
}
.app.combined .list-group-item-warning {
  color: #FE9700;
  background-color: #ffffa6;
}
.app.combined .list-group-item-danger {
  color: #F34235;
  background-color: #ffb5a8;
}
.app.combined .no-caret.dropdown-toggle::after {
  content: none;
}
.app.combined .dropdown-item.active, .app.combined .dropdown-item:active {
  background-color: #ccc;
}
.app.combined .dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.app.combined .dropdown.show .dropdown-menu {
  max-height: 300px;
  opacity: 1;
  pointer-events: auto;
}
.app.combined .dropdown.open .dropdown-menu {
  opacity: 1;
  pointer-events: auto;
}
.app.combined .dropdown-menu-right {
  right: 0;
  left: auto;
}
.app.combined .form-group label {
  margin-bottom: 0.2rem;
}
.app.combined .form-control-rounded {
  border-radius: 16px;
}
.app.combined .form-control:focus {
  border-color: rgba(38, 38, 38, 0.6);
}
.app.combined input[type=color].form-control {
  padding: 0;
}
.app.combined .has-success .form-control {
  border-color: #4BAE4F;
}
.app.combined .has-success .form-control:focus {
  border-color: #378D3B;
}
.app.combined .has-success .form-control-feedback, .app.combined .has-success .form-control-label, .app.combined .has-success .form-check-label, .app.combined .has-success .form-check-inline, .app.combined .has-success .custom-control {
  color: #4BAE4F;
}
.app.combined .has-warning .form-control {
  border-color: #FE9700;
}
.app.combined .has-warning .form-control:focus {
  border-color: #F47B00;
}
.app.combined .has-warning .form-control-feedback, .app.combined .has-warning .form-control-label, .app.combined .has-warning .form-check-label, .app.combined .has-warning .form-check-inline, .app.combined .has-warning .custom-control {
  color: #FE9700;
}
.app.combined .has-danger .form-control {
  border-color: #F34235;
}
.app.combined .has-danger .form-control:focus {
  border-color: #D22E2E;
}
.app.combined .has-danger .form-control-feedback, .app.combined .has-danger .form-control-label, .app.combined .has-danger .form-check-label, .app.combined .has-danger .form-check-inline, .app.combined .has-danger .custom-control {
  color: #F34235;
}
.app.combined .has-success .input-group-prepend,
.app.combined .has-success .input-group-append,
.app.combined .has-success .input-group-prepend .input-group-text,
.app.combined .has-success .input-group-append .input-group-text {
  color: #4BAE4F;
  border-color: #4BAE4F;
  background-color: #eaf6ea;
}
.app.combined .has-danger .input-group-prepend,
.app.combined .has-danger .input-group-append,
.app.combined .has-danger .input-group-prepend .input-group-text,
.app.combined .has-danger .input-group-append .input-group-text {
  color: #F34235;
  border-color: #F34235;
  background-color: #fdf7f7;
}
.app.combined .form-control.validation-field.ng-touched {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.625rem;
  background-size: 1.25rem 1.25rem;
}
.app.combined .form-control.validation-field.ng-touched.ng-invalid {
  border-color: #F34235;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23F34235' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
}
.app.combined .form-control.validation-field.ng-touched.ng-valid {
  border-color: #4BAE4F;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%234BAE4F' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}
.app.combined .custom-control-label::before {
  width: 14px;
  height: 14px;
  top: 0.3rem;
}
.app.combined .custom-control-label::after {
  width: 14px;
  height: 14px;
  top: 0.3rem;
  background-size: 60% 60%;
}
.app.combined .custom-checkbox.checkbox-circle .custom-control-label::before,
.app.combined .custom-checkbox.checkbox-circle .custom-control-label::after {
  border-radius: 50%;
}
.app.combined .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.combined .custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.combined .custom-checkbox .custom-control-input.checkbox-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.combined .custom-checkbox .custom-control-input.checkbox-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.combined .custom-checkbox .custom-control-input.checkbox-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.combined .custom-checkbox .custom-control-input.checkbox-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.combined .custom-checkbox .custom-control-input.checkbox-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.combined .custom-checkbox .custom-control-input.checkbox-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.combined .custom-checkbox .custom-control-input.checkbox-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.combined .custom-checkbox .custom-control-input.checkbox-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.combined .custom-checkbox .custom-control-input.checkbox-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.combined .custom-checkbox .custom-control-input.checkbox-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.combined .custom-checkbox .custom-control-input.checkbox-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.combined .custom-checkbox .custom-control-input.checkbox-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.combined .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.combined .custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.combined .custom-radio .custom-control-input.radio-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.combined .custom-radio .custom-control-input.radio-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.combined .custom-radio .custom-control-input.radio-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.combined .custom-radio .custom-control-input.radio-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.combined .custom-radio .custom-control-input.radio-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.combined .custom-radio .custom-control-input.radio-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.combined .custom-radio .custom-control-input.radio-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.combined .custom-radio .custom-control-input.radio-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.combined .custom-radio .custom-control-input.radio-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.combined .custom-radio .custom-control-input.radio-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.combined .custom-radio .custom-control-input.radio-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.combined .custom-radio .custom-control-input.radio-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.combined .table-success,
.app.combined .table-success > th,
.app.combined .table-success > td {
  background-color: #4BAE4F;
}
.app.combined .table-hover .table-success:hover {
  background-color: #439c47;
}
.app.combined .table-hover .table-success:hover > td,
.app.combined .table-hover .table-success:hover > th {
  background-color: #439c47;
}
.app.combined .table-info,
.app.combined .table-info > th,
.app.combined .table-info > td {
  background-color: #00BBD3;
}
.app.combined .table-hover .table-info:hover {
  background-color: #00a4ba;
}
.app.combined .table-hover .table-info:hover > td,
.app.combined .table-hover .table-info:hover > th {
  background-color: #00a4ba;
}
.app.combined .table-warning,
.app.combined .table-warning > th,
.app.combined .table-warning > td {
  background-color: #FE9700;
}
.app.combined .table-hover .table-warning:hover {
  background-color: #e58800;
}
.app.combined .table-hover .table-warning:hover > td,
.app.combined .table-hover .table-warning:hover > th {
  background-color: #e58800;
}
.app.combined .table-danger,
.app.combined .table-danger > th,
.app.combined .table-danger > td {
  background-color: #F34235;
}
.app.combined .table-hover .table-danger:hover {
  background-color: #f22b1d;
}
.app.combined .table-hover .table-danger:hover > td,
.app.combined .table-hover .table-danger:hover > th {
  background-color: #f22b1d;
}
.app.combined .rounded-0 {
  border-radius: 0 !important;
}
.app.combined .popover.bs-popover-top .arrow,
.app.combined .popover.bs-popover-auto[x-placement^=top] .arrow,
.app.combined .popover.bs-popover-auto[x-placement^=bottom] .arrow,
.app.combined .popover.bs-popover-bottom .arrow {
  left: 50%;
}
.app.combined .popover.bs-popover-auto[x-placement^=right] .arrow,
.app.combined .popover.bs-popover-right .arrow,
.app.combined .popover.bs-popover-auto[x-placement^=left] .arrow,
.app.combined .popover.bs-popover-left .arrow {
  top: 50%;
}
.app.combined .popover.bs-popover-bottom .arrow::after,
.app.combined .popover.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #f7f7f7;
}
.app.combined .top-navbar {
  background-color: #fff;
  color: #a6a6a6;
}
.app.combined .top-navbar .app-search {
  border-bottom: 1px solid #a6a6a6;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.combined .top-navbar .app-search .form-control {
  color: #a6a6a6;
}
.app.combined .top-navbar .app-search input[type=text]::-webkit-input-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.combined .top-navbar .app-search input[type=text]::-moz-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.combined .top-navbar .app-search input[type=text]:-moz-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.combined .top-navbar .app-search input[type=text]:-ms-input-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.combined .top-navbar .app-search textarea::-webkit-input-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.combined .top-navbar .app-search textarea::-moz-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.combined .top-navbar .app-search textarea:-moz-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.combined .top-navbar .app-search textarea:-ms-input-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.combined .top-navbar .app-search i {
  color: #a6a6a6;
}
.app.combined .top-navbar a {
  color: #a6a6a6;
}
.app.combined .logo {
  color: #000 !important;
}
.app.combined .logo .ng {
  color: #DD1B16;
}
.app.combined .menu-icon .line {
  background-color: #a6a6a6;
}
.app.combined #info-content-icon .triangle {
  border-color: #a6a6a6 transparent transparent transparent;
}
.app.combined #info-content-icon.show {
  background: #262626;
  box-shadow: 0px 0px 0px 8px #262626, 0px -16px 0px 8px #262626;
}
.app.combined #info-content-icon.show .triangle {
  border-color: #fff transparent transparent transparent;
}
.app.combined .menu-wrapper .card a {
  color: #979797;
  border-bottom: 1px solid rgba(23, 23, 23, 0.5);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1);
}
.app.combined .menu-wrapper .card a:hover {
  color: #fff;
  background-color: #171717 !important;
}
.app.combined .menu-wrapper .card a.active-link {
  background-color: #dd1b16 !important;
  color: #fff;
}
.app.combined .menu-wrapper .card .card a {
  padding-left: 15px;
  background-color: rgba(0, 0, 0, 0.3);
}
.app.combined .menu-wrapper .card .card .card a {
  padding-left: 30px;
  background-color: rgba(0, 0, 0, 0.4);
}
.app.combined .menu-wrapper .card .card .card .card a {
  padding-left: 45px;
  background-color: rgba(0, 0, 0, 0.5);
}
.app.combined .menu-wrapper .card .card .card .card .card a {
  padding-left: 60px;
  background-color: rgba(0, 0, 0, 0.6);
}
.app.combined .menu-wrapper .card .card .card .card .card .card a {
  padding-left: 75px;
  background-color: rgba(0, 0, 0, 0.7);
}
.app.combined .menu-wrapper .card .card .card .card .card .card .card a {
  padding-left: 90px;
  background-color: rgba(0, 0, 0, 0.8);
}
.app.combined .menu-wrapper .card .card .card .card .card .card .card .card a {
  padding-left: 105px;
  background-color: rgba(0, 0, 0, 0.9);
}
.app.combined .menu-wrapper .card .card .card .card .card .card .card .card .card a {
  padding-left: 120px;
  background-color: black;
}
.app.combined .menu-wrapper .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 135px;
  background-color: black;
}
.app.combined .menu-wrapper .card .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 150px;
  background-color: black;
}
.app.combined .wrapper, .app.combined .wrapper-inner {
  background-color: #f5f5f5;
  color: #565656;
}
.app.combined #info-content {
  background-color: #262626;
}
.app.combined #info-content a.close {
  color: #a6a6a6;
}
.app.combined #info-content .info {
  color: #a6a6a6;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.combined #info-content .info .form-control {
  background-color: #262626;
  border-color: rgba(166, 166, 166, 0.4);
  color: #a6a6a6;
}
.app.combined #info-content .info input[type=text]::-webkit-input-placeholder {
  color: rgba(166, 166, 166, 0.7);
}
.app.combined #info-content .info input[type=text]::-moz-placeholder {
  color: rgba(166, 166, 166, 0.7);
}
.app.combined #info-content .info input[type=text]:-moz-placeholder {
  color: rgba(166, 166, 166, 0.7);
}
.app.combined #info-content .info input[type=text]:-ms-input-placeholder {
  color: rgba(166, 166, 166, 0.7);
}
.app.combined #info-content .info textarea::-webkit-input-placeholder {
  color: rgba(166, 166, 166, 0.7);
}
.app.combined #info-content .info textarea::-moz-placeholder {
  color: rgba(166, 166, 166, 0.7);
}
.app.combined #info-content .info textarea:-moz-placeholder {
  color: rgba(166, 166, 166, 0.7);
}
.app.combined #info-content .info textarea:-ms-input-placeholder {
  color: rgba(166, 166, 166, 0.7);
}
.app.combined #info-content .left-border {
  border-left: 1px solid rgba(166, 166, 166, 0.3);
}
.app.combined #messagesDropDown .dropdown-menu .nav-link.active {
  border-bottom: 4px solid #ccc;
}
.app.combined .dropdown-menu .user-menu .user-info {
  background-color: #eceeef;
  color: #262626;
}
.app.combined .favorites-container {
  border-left: 1px solid rgba(166, 166, 166, 0.4);
  border-right: 1px solid rgba(166, 166, 166, 0.4);
}
.app.combined #favorites .dropdown .btn-block {
  background-color: #fff;
  color: #a6a6a6;
}
.app.combined .side-chat .media {
  color: #979797;
  background-color: #262626;
  border-bottom: 1px solid #f5f5f5;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.combined .side-chat .media input[type=text]::-webkit-input-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.combined .side-chat .media input[type=text]::-moz-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.combined .side-chat .media input[type=text]:-moz-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.combined .side-chat .media input[type=text]:-ms-input-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.combined .side-chat .media textarea::-webkit-input-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.combined .side-chat .media textarea::-moz-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.combined .side-chat .media textarea:-moz-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.combined .side-chat .media textarea:-ms-input-placeholder {
  color: rgba(166, 166, 166, 0.8);
}
.app.combined .side-chat .chat-window {
  color: #979797;
  background-color: #262626;
}
.app.combined .side-chat .chat-window .header {
  border-bottom: 1px solid #979797;
}
.app.combined .sidebar-outer {
  background-color: #262626;
}
.app.combined .sidebar-outer .logo {
  background-color: #fff;
}
.app.combined .vertical-menu-tooltip-place .tooltip {
  opacity: 1;
}
.app.combined .vertical-menu-tooltip-place .tooltip.bs-tooltip-right .arrow::before, .app.combined .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #262626;
}
.app.combined .vertical-menu-tooltip-place .tooltip.bs-tooltip-bottom .arrow::before, .app.combined .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #262626;
}
.app.combined .vertical-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #262626;
}
.app.combined .horizontal-menu-outer {
  background-color: #262626;
}
.app.combined #navigation .menu li {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.app.combined #navigation .menu li a {
  color: #979797;
}
.app.combined #navigation .menu li a:hover {
  color: #fff;
  background-color: #171717 !important;
}
.app.combined #navigation .menu li a.active-link {
  background-color: #dd1b16 !important;
  color: #fff;
}
.app.combined #navigation .menu li:first-child {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.app.combined #navigation .menu .sub-menu {
  background-color: #262626;
}
.app.combined #navigation ul.menu ul a,
.app.combined #navigation .menu ul ul a {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
}
.app.combined .horizontal-menu-tooltip-place .tooltip {
  opacity: 1;
  z-index: 999999;
}
.app.combined .horizontal-menu-tooltip-place .tooltip.bs-tooltip-top .arrow::before, .app.combined .horizontal-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #000;
}
.app.combined .horizontal-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #000 !important;
}
.app.combined .app-footer {
  background-color: #171717;
  color: #a6a6a6;
}
.app.combined .login-container .card, .app.combined .register-container .card, .app.combined .error-container .card {
  background-color: #262626;
  color: #efefef;
}
.app.combined .login-container .card .card-header, .app.combined .register-container .card .card-header, .app.combined .error-container .card .card-header {
  background-color: #DD1B16;
}
.app.combined .login-container .card .btn, .app.combined .register-container .card .btn, .app.combined .error-container .card .btn {
  background-color: #DD1B16;
  color: #efefef;
}
.app.combined .box-shadow {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(38, 38, 38, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(38, 38, 38, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(38, 38, 38, 0.3);
}
.app.combined .widget-controls a {
  color: #a6a6a6;
}
.app.combined .widget-controls a:hover {
  color: rgba(166, 166, 166, 0.7);
}
.app.purple {
  /*Card*/
  /*Text*/
  /*Breadcrumb*/
  /*Buttons*/
  /*Badge*/
  /*Alerts*/
  /* MODALS */
  /*BG*/
  /*Progress*/
  /*Tabs*/
  /*List group*/
  /*DropDown*/
  /* actual dropdown animation */
  /*Forms*/
  /*Tables*/
  /*Popovers*/
  /*Top Navbar*/
  /*Side-Chat*/
  /*Sidebar*/
  /*Horizontal-menu*/
  /*Footer*/
  /*Login & register & error*/
  /*Global classes*/
  /*Libs */
}
.app.purple .card .card-header {
  background-color: #f3f3f3;
}
.app.purple .card .card-header.transparent {
  background-color: transparent;
}
.app.purple .card.card-primary {
  background-color: #3E50B4;
  border-color: #2F3E9E;
}
.app.purple .card.card-primary .card-header {
  background-color: #2F3E9E;
}
.app.purple .card.card-primary .card-footer {
  background-color: rgba(47, 62, 158, 0.5);
}
.app.purple .card.card-success {
  background-color: #4BAE4F;
  border-color: #378D3B;
}
.app.purple .card.card-success .card-header {
  background-color: #378D3B;
}
.app.purple .card.card-success .card-footer {
  background-color: rgba(55, 141, 59, 0.5);
}
.app.purple .card.card-info {
  background-color: #00BBD3;
  border-color: #0096A6;
}
.app.purple .card.card-info .card-header {
  background-color: #0096A6;
}
.app.purple .card.card-info .card-footer {
  background-color: rgba(0, 150, 166, 0.5);
}
.app.purple .card.card-warning {
  background-color: #FE9700;
  border-color: #F47B00;
}
.app.purple .card.card-warning .card-header {
  background-color: #F47B00;
}
.app.purple .card.card-warning .card-footer {
  background-color: rgba(244, 123, 0, 0.5);
}
.app.purple .card.card-danger {
  background-color: #F34235;
  border-color: #D22E2E;
}
.app.purple .card.card-danger .card-header {
  background-color: #D22E2E;
}
.app.purple .card.card-danger .card-footer {
  background-color: rgba(210, 46, 46, 0.5);
}
.app.purple .card.card-outline-primary {
  background-color: transparent;
  border: 1px solid #3E50B4;
}
.app.purple .card.card-outline-success {
  background-color: transparent;
  border: 1px solid #4BAE4F;
}
.app.purple .card.card-outline-info {
  background-color: transparent;
  border: 1px solid #00BBD3;
}
.app.purple .card.card-outline-warning {
  background-color: transparent;
  border: 1px solid #FE9700;
}
.app.purple .card.card-outline-danger {
  background-color: transparent;
  border: 1px solid #F34235;
}
.app.purple .card-img-overlay {
  color: #262626;
}
.app.purple .card-inverse .card-link,
.app.purple .card-inverse .card-text,
.app.purple .card-inverse .card-subtitle,
.app.purple .card-inverse .card-blockquote .blockquote-footer {
  color: #f5f5f5;
}
.app.purple .overlay-primary {
  background-color: rgba(62, 80, 180, 0.8);
}
.app.purple .overlay-success {
  background-color: rgba(75, 174, 79, 0.8);
}
.app.purple .overlay-info {
  background-color: rgba(0, 187, 211, 0.8);
}
.app.purple .overlay-warning {
  background-color: rgba(254, 151, 0, 0.8);
}
.app.purple .overlay-danger {
  background-color: rgba(243, 66, 53, 0.8);
}
.app.purple .text-primary {
  color: #3E50B4 !important;
}
.app.purple a.text-primary:focus, .app.purple a.text-primary:hover {
  color: #2F3E9E !important;
}
.app.purple .text-success {
  color: #4BAE4F !important;
}
.app.purple a.text-success:focus, .app.purple a.text-success:hover {
  color: #378D3B !important;
}
.app.purple .text-info {
  color: #00BBD3 !important;
}
.app.purple a.text-info:focus, .app.purple a.text-info:hover {
  color: #0096A6 !important;
}
.app.purple .text-warning {
  color: #FE9700 !important;
}
.app.purple a.text-warning:focus, .app.purple a.text-warning:hover {
  color: #F47B00 !important;
}
.app.purple .text-danger {
  color: #F34235 !important;
}
.app.purple a.text-danger:focus, .app.purple a.text-danger:hover {
  color: #D22E2E !important;
}
.app.purple .text-gray {
  color: #9D9D9D !important;
}
.app.purple a.text-gray:focus, .app.purple a.text-gray:hover {
  color: #606060 !important;
}
.app.purple .text-inverse {
  color: #292B2C !important;
}
.app.purple a.text-inverse:focus, .app.purple a.text-inverse:hover {
  color: #1C1E1F !important;
}
.app.purple .breadcrumb {
  padding: 0.5rem 1rem;
}
.app.purple .breadcrumb a {
  color: #3E50B4;
}
.app.purple .btn-primary {
  color: #fff;
  background-color: #3E50B4;
  border-color: #3E50B4;
}
.app.purple .btn-primary:hover, .app.purple .btn-primary:active, .app.purple .btn-primary.active {
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.purple .btn-primary:focus, .app.purple .btn-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.purple .show > .btn-primary.dropdown-toggle {
  background-color: #2F3E9E;
}
.app.purple .btn-success {
  color: #fff;
  background-color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.purple .btn-success:hover, .app.purple .btn-success:active, .app.purple .btn-success.active {
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.purple .btn-success:focus, .app.purple .btn-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.purple .show > .btn-success.dropdown-toggle {
  background-color: #378D3B;
}
.app.purple .btn-info {
  color: #fff;
  background-color: #00BBD3;
  border-color: #00BBD3;
}
.app.purple .btn-info:hover, .app.purple .btn-info:active, .app.purple .btn-info.active {
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.purple .btn-info:focus, .app.purple .btn-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.purple .show > .btn-info.dropdown-toggle {
  background-color: #0096A6;
}
.app.purple .btn-warning {
  color: #fff;
  background-color: #FE9700;
  border-color: #FE9700;
}
.app.purple .btn-warning:hover, .app.purple .btn-warning:active, .app.purple .btn-warning.active {
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.purple .btn-warning:focus, .app.purple .btn-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.purple .show > .btn-warning.dropdown-toggle {
  background-color: #F47B00;
}
.app.purple .btn-danger {
  color: #fff;
  background-color: #F34235;
  border-color: #F34235;
}
.app.purple .btn-danger:hover, .app.purple .btn-danger:active, .app.purple .btn-danger.active {
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.purple .btn-danger:focus, .app.purple .btn-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.purple .show > .btn-danger.dropdown-toggle {
  background-color: #D22E2E;
}
.app.purple .btn-gray {
  color: #fff;
  background-color: #9D9D9D;
}
.app.purple .btn-gray:hover, .app.purple .btn-gray:active, .app.purple .btn-gray.active {
  background-color: #606060;
}
.app.purple .btn-gray:focus, .app.purple .btn-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.purple .show > .btn-gray.dropdown-toggle {
  background-color: #606060;
}
.app.purple .btn-inverse {
  color: #fff;
  background-color: #292B2C;
}
.app.purple .btn-inverse:hover, .app.purple .btn-inverse:active, .app.purple .btn-inverse.active {
  background-color: #1C1E1F;
}
.app.purple .btn-inverse:focus, .app.purple .btn-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.purple .show > .btn-inverse.dropdown-toggle {
  background-color: #1C1E1F;
}
.app.purple .btn-rounded {
  border-radius: 5rem;
}
.app.purple .btn-outline-primary {
  color: #3E50B4;
  border-color: #3E50B4;
}
.app.purple .btn-outline-primary:hover, .app.purple .btn-outline-primary:active, .app.purple .btn-outline-primary.active {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.purple .btn-outline-primary:focus, .app.purple .btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.purple .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.purple .btn-outline-success {
  color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.purple .btn-outline-success:hover, .app.purple .btn-outline-success:active, .app.purple .btn-outline-success.active {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.purple .btn-outline-success:focus, .app.purple .btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.purple .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.purple .btn-outline-info {
  color: #00BBD3;
  border-color: #00BBD3;
}
.app.purple .btn-outline-info:hover, .app.purple .btn-outline-info:active, .app.purple .btn-outline-info.active {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.purple .btn-outline-info:focus, .app.purple .btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.purple .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.purple .btn-outline-warning {
  color: #FE9700;
  border-color: #FE9700;
}
.app.purple .btn-outline-warning:hover, .app.purple .btn-outline-warning:active, .app.purple .btn-outline-warning.active {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.purple .btn-outline-warning:focus, .app.purple .btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.purple .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.purple .btn-outline-danger {
  color: #F34235;
  border-color: #F34235;
}
.app.purple .btn-outline-danger:hover, .app.purple .btn-outline-danger:active, .app.purple .btn-outline-danger.active {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.purple .btn-outline-danger:focus, .app.purple .btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.purple .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.purple .btn-outline-gray {
  color: #9D9D9D;
  border-color: #9D9D9D;
  background-color: transparent;
}
.app.purple .btn-outline-gray:hover, .app.purple .btn-outline-gray:active, .app.purple .btn-outline-gray.active {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.purple .btn-outline-gray:focus, .app.purple .btn-outline-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.purple .show > .btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.purple .btn-outline-inverse {
  color: #292B2C;
  border-color: #292B2C;
  background-color: transparent;
}
.app.purple .btn-outline-inverse:hover, .app.purple .btn-outline-inverse:active, .app.purple .btn-outline-inverse.active {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.purple .btn-outline-inverse:focus, .app.purple .btn-outline-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.purple .show > .btn-outline-inverse.dropdown-toggle {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.purple .btn-link {
  border: 1px solid transparent;
}
.app.purple .badge {
  padding: 4px 6px;
}
.app.purple .badge-primary {
  background-color: #3E50B4;
}
.app.purple .badge-success {
  background-color: #4BAE4F;
}
.app.purple .badge-info {
  background-color: #00BBD3;
}
.app.purple .badge-warning {
  background-color: #FE9700;
}
.app.purple .badge-danger {
  background-color: #F34235;
}
.app.purple .badge-gray {
  background-color: #9D9D9D;
}
.app.purple .badge-white {
  background-color: #fff;
}
.app.purple .alert-primary {
  background-color: #b1c3ff;
  border-color: #97a9ff;
  color: #3E50B4;
}
.app.purple .alert-success {
  background-color: #beffc2;
  border-color: #8bee8f;
  color: #4BAE4F;
}
.app.purple .alert-info {
  background-color: #a6ffff;
  border-color: #40fbff;
  color: #00BBD3;
}
.app.purple .alert-warning {
  background-color: #ffffa6;
  border-color: #fff059;
  color: #FE9700;
}
.app.purple .alert-danger {
  background-color: #ffb5a8;
  border-color: #ff9b8e;
  color: #F34235;
}
.app.purple .alert-gray {
  background-color: #f3f3f3;
  border-color: #dddddd;
  color: #9D9D9D;
}
.app.purple .modal {
  z-index: 999999;
  padding-top: 5%;
}
.app.purple .modal-dialog .modal-content .modal-header {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  padding: 10px 15px;
}
.app.purple .modal-dialog .modal-content .modal-header.modal-primary {
  color: #fff;
  background-color: #3E50B4;
}
.app.purple .modal-dialog .modal-content .modal-header.modal-success {
  color: #fff;
  background-color: #4BAE4F;
}
.app.purple .modal-dialog .modal-content .modal-header.modal-info {
  color: #fff;
  background-color: #00BBD3;
}
.app.purple .modal-dialog .modal-content .modal-header.modal-warning {
  color: #fff;
  background-color: #FE9700;
}
.app.purple .modal-dialog .modal-content .modal-header.modal-danger {
  color: #fff;
  background-color: #F34235;
}
.app.purple .modal-dialog .modal-content .modal-header i {
  margin-right: 10px;
}
.app.purple .bg-primary {
  background-color: #3E50B4 !important;
}
.app.purple .bg-success {
  background-color: #4BAE4F !important;
}
.app.purple .bg-info {
  background-color: #00BBD3 !important;
}
.app.purple .bg-warning {
  background-color: #FE9700 !important;
}
.app.purple .bg-danger {
  background-color: #F34235 !important;
}
.app.purple .bg-gray {
  background-color: #9D9D9D !important;
}
.app.purple .bg-light {
  background-color: #f5f5f5 !important;
}
.app.purple .progress-xl {
  height: 2rem;
}
.app.purple .progress-lg {
  height: 1.5rem;
}
.app.purple .progress-md {
  height: 1rem;
}
.app.purple .progress-sm {
  height: 0.5rem;
}
.app.purple .progress-xs {
  height: 0.25rem;
}
.app.purple .nav-tabs.custom {
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
  width: 100%;
}
.app.purple .nav-tabs.custom .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border: none;
  cursor: default;
}
.app.purple .nav-tabs.custom .nav-item .nav-link.active {
  background-color: #fff;
  color: #606060;
}
.app.purple .nav-tabs.custom .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.purple .nav-tabs.custom.top {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.app.purple .nav-tabs.custom.bottom {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.app.purple .nav-tabs.custom.bottom .nav-item {
  margin-bottom: 0;
  margin-top: -1px;
}
.app.purple .nav-tabs.custom.bottom .nav-item .nav-link {
  border-radius: 0 0 0.3rem 0.3rem;
}
.app.purple .nav-tabs.custom.tabs-primary {
  background-color: rgba(62, 80, 180, 0.4);
  border: 1px solid #3E50B4;
}
.app.purple .nav-tabs.custom.tabs-primary .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.purple .nav-tabs.custom.tabs-primary .nav-item .nav-link.active {
  background-color: #3E50B4;
  color: #fff;
}
.app.purple .nav-tabs.custom.tabs-primary .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.purple .nav-tabs.custom.tabs-success {
  background-color: rgba(75, 174, 79, 0.4);
  border: 1px solid #4BAE4F;
}
.app.purple .nav-tabs.custom.tabs-success .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.purple .nav-tabs.custom.tabs-success .nav-item .nav-link.active {
  background-color: #4BAE4F;
  color: #fff;
}
.app.purple .nav-tabs.custom.tabs-success .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.purple .nav-tabs.custom.tabs-info {
  background-color: rgba(0, 187, 211, 0.4);
  border: 1px solid #00BBD3;
}
.app.purple .nav-tabs.custom.tabs-info .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.purple .nav-tabs.custom.tabs-info .nav-item .nav-link.active {
  background-color: #00BBD3;
  color: #fff;
}
.app.purple .nav-tabs.custom.tabs-info .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.purple .nav-tabs.custom.tabs-warning {
  background-color: rgba(254, 151, 0, 0.4);
  border: 1px solid #FE9700;
}
.app.purple .nav-tabs.custom.tabs-warning .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.purple .nav-tabs.custom.tabs-warning .nav-item .nav-link.active {
  background-color: #FE9700;
  color: #fff;
}
.app.purple .nav-tabs.custom.tabs-warning .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.purple .nav-tabs.custom.tabs-danger {
  background-color: rgba(243, 66, 53, 0.4);
  border: 1px solid #F34235;
}
.app.purple .nav-tabs.custom.tabs-danger .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.purple .nav-tabs.custom.tabs-danger .nav-item .nav-link.active {
  background-color: #F34235;
  color: #fff;
}
.app.purple .nav-tabs.custom.tabs-danger .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.purple .tab-content {
  position: relative;
  z-index: 1;
  border: 1px solid #9D9D9D;
  background: #fff;
}
.app.purple .tab-content .tab-pane {
  padding: 1rem;
}
.app.purple .tab-content.tab-content-primary {
  background-color: #3E50B4;
  color: #fff;
  border-color: #3E50B4;
}
.app.purple .tab-content.tab-content-success {
  background-color: #4BAE4F;
  color: #fff;
  border-color: #4BAE4F;
}
.app.purple .tab-content.tab-content-info {
  background-color: #00BBD3;
  color: #fff;
  border-color: #00BBD3;
}
.app.purple .tab-content.tab-content-warning {
  background-color: #FE9700;
  color: #fff;
  border-color: #FE9700;
}
.app.purple .tab-content.tab-content-danger {
  background-color: #F34235;
  color: #fff;
  border-color: #F34235;
}
.app.purple .tab-content.top {
  border-top: none;
}
.app.purple .tab-content.bottom {
  border-bottom: none;
}
.app.purple .vertical-tabs .nav {
  padding-right: 0;
  overflow: hidden;
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
}
.app.purple .vertical-tabs .nav.left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-right: none;
  margin-right: -1px;
  z-index: 2;
}
.app.purple .vertical-tabs .nav.right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-left: none;
  margin-left: -1px;
  z-index: 2;
}
.app.purple .vertical-tabs .nav .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border-radius: 0;
  cursor: default;
}
.app.purple .vertical-tabs .nav .nav-item .nav-link.active {
  background-color: #fff;
}
.app.purple .vertical-tabs .nav .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.purple .vertical-tabs .nav .nav-item .nav-link.disabled {
  color: rgba(96, 96, 96, 0.6);
  cursor: not-allowed !important;
}
.app.purple .list-group-item-primary {
  color: #3E50B4;
  background-color: #b1c3ff;
}
.app.purple .list-group-item-success {
  color: #4BAE4F;
  background-color: #beffc2;
}
.app.purple .list-group-item-info {
  color: #00BBD3;
  background-color: #a6ffff;
}
.app.purple .list-group-item-warning {
  color: #FE9700;
  background-color: #ffffa6;
}
.app.purple .list-group-item-danger {
  color: #F34235;
  background-color: #ffb5a8;
}
.app.purple .no-caret.dropdown-toggle::after {
  content: none;
}
.app.purple .dropdown-item.active, .app.purple .dropdown-item:active {
  background-color: #ccc;
}
.app.purple .dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.app.purple .dropdown.show .dropdown-menu {
  max-height: 300px;
  opacity: 1;
  pointer-events: auto;
}
.app.purple .dropdown.open .dropdown-menu {
  opacity: 1;
  pointer-events: auto;
}
.app.purple .dropdown-menu-right {
  right: 0;
  left: auto;
}
.app.purple .form-group label {
  margin-bottom: 0.2rem;
}
.app.purple .form-control-rounded {
  border-radius: 16px;
}
.app.purple .form-control:focus {
  border-color: rgba(155, 38, 175, 0.6);
}
.app.purple input[type=color].form-control {
  padding: 0;
}
.app.purple .has-success .form-control {
  border-color: #4BAE4F;
}
.app.purple .has-success .form-control:focus {
  border-color: #378D3B;
}
.app.purple .has-success .form-control-feedback, .app.purple .has-success .form-control-label, .app.purple .has-success .form-check-label, .app.purple .has-success .form-check-inline, .app.purple .has-success .custom-control {
  color: #4BAE4F;
}
.app.purple .has-warning .form-control {
  border-color: #FE9700;
}
.app.purple .has-warning .form-control:focus {
  border-color: #F47B00;
}
.app.purple .has-warning .form-control-feedback, .app.purple .has-warning .form-control-label, .app.purple .has-warning .form-check-label, .app.purple .has-warning .form-check-inline, .app.purple .has-warning .custom-control {
  color: #FE9700;
}
.app.purple .has-danger .form-control {
  border-color: #F34235;
}
.app.purple .has-danger .form-control:focus {
  border-color: #D22E2E;
}
.app.purple .has-danger .form-control-feedback, .app.purple .has-danger .form-control-label, .app.purple .has-danger .form-check-label, .app.purple .has-danger .form-check-inline, .app.purple .has-danger .custom-control {
  color: #F34235;
}
.app.purple .has-success .input-group-prepend,
.app.purple .has-success .input-group-append,
.app.purple .has-success .input-group-prepend .input-group-text,
.app.purple .has-success .input-group-append .input-group-text {
  color: #4BAE4F;
  border-color: #4BAE4F;
  background-color: #eaf6ea;
}
.app.purple .has-danger .input-group-prepend,
.app.purple .has-danger .input-group-append,
.app.purple .has-danger .input-group-prepend .input-group-text,
.app.purple .has-danger .input-group-append .input-group-text {
  color: #F34235;
  border-color: #F34235;
  background-color: #fdf7f7;
}
.app.purple .form-control.validation-field.ng-touched {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.625rem;
  background-size: 1.25rem 1.25rem;
}
.app.purple .form-control.validation-field.ng-touched.ng-invalid {
  border-color: #F34235;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23F34235' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
}
.app.purple .form-control.validation-field.ng-touched.ng-valid {
  border-color: #4BAE4F;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%234BAE4F' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}
.app.purple .custom-control-label::before {
  width: 14px;
  height: 14px;
  top: 0.3rem;
}
.app.purple .custom-control-label::after {
  width: 14px;
  height: 14px;
  top: 0.3rem;
  background-size: 60% 60%;
}
.app.purple .custom-checkbox.checkbox-circle .custom-control-label::before,
.app.purple .custom-checkbox.checkbox-circle .custom-control-label::after {
  border-radius: 50%;
}
.app.purple .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.purple .custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.purple .custom-checkbox .custom-control-input.checkbox-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.purple .custom-checkbox .custom-control-input.checkbox-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.purple .custom-checkbox .custom-control-input.checkbox-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.purple .custom-checkbox .custom-control-input.checkbox-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.purple .custom-checkbox .custom-control-input.checkbox-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.purple .custom-checkbox .custom-control-input.checkbox-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.purple .custom-checkbox .custom-control-input.checkbox-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.purple .custom-checkbox .custom-control-input.checkbox-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.purple .custom-checkbox .custom-control-input.checkbox-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.purple .custom-checkbox .custom-control-input.checkbox-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.purple .custom-checkbox .custom-control-input.checkbox-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.purple .custom-checkbox .custom-control-input.checkbox-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.purple .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.purple .custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.purple .custom-radio .custom-control-input.radio-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.purple .custom-radio .custom-control-input.radio-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.purple .custom-radio .custom-control-input.radio-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.purple .custom-radio .custom-control-input.radio-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.purple .custom-radio .custom-control-input.radio-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.purple .custom-radio .custom-control-input.radio-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.purple .custom-radio .custom-control-input.radio-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.purple .custom-radio .custom-control-input.radio-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.purple .custom-radio .custom-control-input.radio-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.purple .custom-radio .custom-control-input.radio-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.purple .custom-radio .custom-control-input.radio-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.purple .custom-radio .custom-control-input.radio-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.purple .table-success,
.app.purple .table-success > th,
.app.purple .table-success > td {
  background-color: #4BAE4F;
}
.app.purple .table-hover .table-success:hover {
  background-color: #439c47;
}
.app.purple .table-hover .table-success:hover > td,
.app.purple .table-hover .table-success:hover > th {
  background-color: #439c47;
}
.app.purple .table-info,
.app.purple .table-info > th,
.app.purple .table-info > td {
  background-color: #00BBD3;
}
.app.purple .table-hover .table-info:hover {
  background-color: #00a4ba;
}
.app.purple .table-hover .table-info:hover > td,
.app.purple .table-hover .table-info:hover > th {
  background-color: #00a4ba;
}
.app.purple .table-warning,
.app.purple .table-warning > th,
.app.purple .table-warning > td {
  background-color: #FE9700;
}
.app.purple .table-hover .table-warning:hover {
  background-color: #e58800;
}
.app.purple .table-hover .table-warning:hover > td,
.app.purple .table-hover .table-warning:hover > th {
  background-color: #e58800;
}
.app.purple .table-danger,
.app.purple .table-danger > th,
.app.purple .table-danger > td {
  background-color: #F34235;
}
.app.purple .table-hover .table-danger:hover {
  background-color: #f22b1d;
}
.app.purple .table-hover .table-danger:hover > td,
.app.purple .table-hover .table-danger:hover > th {
  background-color: #f22b1d;
}
.app.purple .rounded-0 {
  border-radius: 0 !important;
}
.app.purple .popover.bs-popover-top .arrow,
.app.purple .popover.bs-popover-auto[x-placement^=top] .arrow,
.app.purple .popover.bs-popover-auto[x-placement^=bottom] .arrow,
.app.purple .popover.bs-popover-bottom .arrow {
  left: 50%;
}
.app.purple .popover.bs-popover-auto[x-placement^=right] .arrow,
.app.purple .popover.bs-popover-right .arrow,
.app.purple .popover.bs-popover-auto[x-placement^=left] .arrow,
.app.purple .popover.bs-popover-left .arrow {
  top: 50%;
}
.app.purple .popover.bs-popover-bottom .arrow::after,
.app.purple .popover.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #f7f7f7;
}
.app.purple .top-navbar {
  background-color: #7A1EA1;
  color: #E0BDE6;
}
.app.purple .top-navbar .app-search {
  border-bottom: 1px solid #E0BDE6;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.purple .top-navbar .app-search .form-control {
  color: #E0BDE6;
}
.app.purple .top-navbar .app-search input[type=text]::-webkit-input-placeholder {
  color: rgba(224, 189, 230, 0.8);
}
.app.purple .top-navbar .app-search input[type=text]::-moz-placeholder {
  color: rgba(224, 189, 230, 0.8);
}
.app.purple .top-navbar .app-search input[type=text]:-moz-placeholder {
  color: rgba(224, 189, 230, 0.8);
}
.app.purple .top-navbar .app-search input[type=text]:-ms-input-placeholder {
  color: rgba(224, 189, 230, 0.8);
}
.app.purple .top-navbar .app-search textarea::-webkit-input-placeholder {
  color: rgba(224, 189, 230, 0.8);
}
.app.purple .top-navbar .app-search textarea::-moz-placeholder {
  color: rgba(224, 189, 230, 0.8);
}
.app.purple .top-navbar .app-search textarea:-moz-placeholder {
  color: rgba(224, 189, 230, 0.8);
}
.app.purple .top-navbar .app-search textarea:-ms-input-placeholder {
  color: rgba(224, 189, 230, 0.8);
}
.app.purple .top-navbar .app-search i {
  color: #E0BDE6;
}
.app.purple .top-navbar a {
  color: #E0BDE6;
}
.app.purple .logo {
  color: #fff !important;
}
.app.purple .logo .ng {
  color: #fff;
}
.app.purple .menu-icon .line {
  background-color: #E0BDE6;
}
.app.purple #info-content-icon .triangle {
  border-color: #E0BDE6 transparent transparent transparent;
}
.app.purple #info-content-icon.show {
  background: #9B26AF;
  box-shadow: 0px 0px 0px 8px #9B26AF, 0px -16px 0px 8px #9B26AF;
}
.app.purple #info-content-icon.show .triangle {
  border-color: #E0BDE6 transparent transparent transparent;
}
.app.purple .menu-wrapper .card a {
  color: #C4C9E8;
  border-bottom: 1px solid rgba(122, 30, 161, 0.5);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1);
}
.app.purple .menu-wrapper .card a:hover {
  color: #fff;
  background-color: #7A1EA1 !important;
}
.app.purple .menu-wrapper .card a.active-link {
  background-color: #DF3FFA !important;
  color: #fff;
}
.app.purple .menu-wrapper .card .card a {
  padding-left: 15px;
  background-color: rgba(0, 0, 0, 0.3);
}
.app.purple .menu-wrapper .card .card .card a {
  padding-left: 30px;
  background-color: rgba(0, 0, 0, 0.4);
}
.app.purple .menu-wrapper .card .card .card .card a {
  padding-left: 45px;
  background-color: rgba(0, 0, 0, 0.5);
}
.app.purple .menu-wrapper .card .card .card .card .card a {
  padding-left: 60px;
  background-color: rgba(0, 0, 0, 0.6);
}
.app.purple .menu-wrapper .card .card .card .card .card .card a {
  padding-left: 75px;
  background-color: rgba(0, 0, 0, 0.7);
}
.app.purple .menu-wrapper .card .card .card .card .card .card .card a {
  padding-left: 90px;
  background-color: rgba(0, 0, 0, 0.8);
}
.app.purple .menu-wrapper .card .card .card .card .card .card .card .card a {
  padding-left: 105px;
  background-color: rgba(0, 0, 0, 0.9);
}
.app.purple .menu-wrapper .card .card .card .card .card .card .card .card .card a {
  padding-left: 120px;
  background-color: black;
}
.app.purple .menu-wrapper .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 135px;
  background-color: black;
}
.app.purple .menu-wrapper .card .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 150px;
  background-color: black;
}
.app.purple .wrapper, .app.purple .wrapper-inner {
  background-color: #f5f5f5;
  color: #565656;
}
.app.purple #info-content {
  background-color: #9B26AF;
}
.app.purple #info-content a.close {
  color: #E0BDE6;
}
.app.purple #info-content .info {
  color: #E0BDE6;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.purple #info-content .info .form-control {
  background-color: #9B26AF;
  border-color: rgba(224, 189, 230, 0.4);
  color: #E0BDE6;
}
.app.purple #info-content .info input[type=text]::-webkit-input-placeholder {
  color: rgba(224, 189, 230, 0.7);
}
.app.purple #info-content .info input[type=text]::-moz-placeholder {
  color: rgba(224, 189, 230, 0.7);
}
.app.purple #info-content .info input[type=text]:-moz-placeholder {
  color: rgba(224, 189, 230, 0.7);
}
.app.purple #info-content .info input[type=text]:-ms-input-placeholder {
  color: rgba(224, 189, 230, 0.7);
}
.app.purple #info-content .info textarea::-webkit-input-placeholder {
  color: rgba(224, 189, 230, 0.7);
}
.app.purple #info-content .info textarea::-moz-placeholder {
  color: rgba(224, 189, 230, 0.7);
}
.app.purple #info-content .info textarea:-moz-placeholder {
  color: rgba(224, 189, 230, 0.7);
}
.app.purple #info-content .info textarea:-ms-input-placeholder {
  color: rgba(224, 189, 230, 0.7);
}
.app.purple #info-content .left-border {
  border-left: 1px solid rgba(224, 189, 230, 0.3);
}
.app.purple #messagesDropDown .dropdown-menu .nav-link.active {
  border-bottom: 4px solid #7A1EA1;
}
.app.purple .dropdown-menu .user-menu .user-info {
  background-color: #7A1EA1;
  color: #E0BDE6;
}
.app.purple .favorites-container {
  border-left: 1px solid rgba(224, 189, 230, 0.4);
  border-right: 1px solid rgba(224, 189, 230, 0.4);
}
.app.purple #favorites .dropdown .btn-block {
  background-color: #7A1EA1;
  color: #E0BDE6;
}
.app.purple .side-chat .media {
  color: #E0BDE6;
  background-color: #9B26AF;
  border-bottom: 1px solid #f5f5f5;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.purple .side-chat .media input[type=text]::-webkit-input-placeholder {
  color: rgba(224, 189, 230, 0.8);
}
.app.purple .side-chat .media input[type=text]::-moz-placeholder {
  color: rgba(224, 189, 230, 0.8);
}
.app.purple .side-chat .media input[type=text]:-moz-placeholder {
  color: rgba(224, 189, 230, 0.8);
}
.app.purple .side-chat .media input[type=text]:-ms-input-placeholder {
  color: rgba(224, 189, 230, 0.8);
}
.app.purple .side-chat .media textarea::-webkit-input-placeholder {
  color: rgba(224, 189, 230, 0.8);
}
.app.purple .side-chat .media textarea::-moz-placeholder {
  color: rgba(224, 189, 230, 0.8);
}
.app.purple .side-chat .media textarea:-moz-placeholder {
  color: rgba(224, 189, 230, 0.8);
}
.app.purple .side-chat .media textarea:-ms-input-placeholder {
  color: rgba(224, 189, 230, 0.8);
}
.app.purple .side-chat .chat-window {
  color: #E0BDE6;
  background-color: #9B26AF;
}
.app.purple .side-chat .chat-window .header {
  border-bottom: 1px solid #E0BDE6;
}
.app.purple .sidebar-outer {
  background-color: #9B26AF;
}
.app.purple .sidebar-outer .logo {
  background-color: #7A1EA1;
}
.app.purple .vertical-menu-tooltip-place .tooltip {
  opacity: 1;
}
.app.purple .vertical-menu-tooltip-place .tooltip.bs-tooltip-right .arrow::before, .app.purple .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #9B26AF;
}
.app.purple .vertical-menu-tooltip-place .tooltip.bs-tooltip-bottom .arrow::before, .app.purple .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #9B26AF;
}
.app.purple .vertical-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #9B26AF;
}
.app.purple .horizontal-menu-outer {
  background-color: #9B26AF;
}
.app.purple #navigation .menu li {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.app.purple #navigation .menu li a {
  color: #C4C9E8;
}
.app.purple #navigation .menu li a:hover {
  color: #fff;
  background-color: #7A1EA1 !important;
}
.app.purple #navigation .menu li a.active-link {
  background-color: #DF3FFA !important;
  color: #fff;
}
.app.purple #navigation .menu li:first-child {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.app.purple #navigation .menu .sub-menu {
  background-color: #9B26AF;
}
.app.purple #navigation ul.menu ul a,
.app.purple #navigation .menu ul ul a {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
}
.app.purple .horizontal-menu-tooltip-place .tooltip {
  opacity: 1;
  z-index: 999999;
}
.app.purple .horizontal-menu-tooltip-place .tooltip.bs-tooltip-top .arrow::before, .app.purple .horizontal-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #000;
}
.app.purple .horizontal-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #000 !important;
}
.app.purple .app-footer {
  background-color: #7A1EA1;
  color: #E0BDE6;
}
.app.purple .login-container .card, .app.purple .register-container .card, .app.purple .error-container .card {
  background-color: #DF3FFA;
  color: #F5F5F5;
}
.app.purple .login-container .card .card-header, .app.purple .register-container .card .card-header, .app.purple .error-container .card .card-header {
  background-color: #7A1EA1;
}
.app.purple .login-container .card .btn, .app.purple .register-container .card .btn, .app.purple .error-container .card .btn {
  background-color: #7A1EA1;
  color: #F5F5F5;
}
.app.purple .box-shadow {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(155, 38, 175, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(155, 38, 175, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(155, 38, 175, 0.3);
}
.app.purple .widget-controls a {
  color: #a6a6a6;
}
.app.purple .widget-controls a:hover {
  color: rgba(166, 166, 166, 0.7);
}
.app.orange {
  /*Card*/
  /*Text*/
  /*Breadcrumb*/
  /*Buttons*/
  /*Badge*/
  /*Alerts*/
  /* MODALS */
  /*BG*/
  /*Progress*/
  /*Tabs*/
  /*List group*/
  /*DropDown*/
  /* actual dropdown animation */
  /*Forms*/
  /*Tables*/
  /*Popovers*/
  /*Top Navbar*/
  /*Side-Chat*/
  /*Sidebar*/
  /*Horizontal-menu*/
  /*Footer*/
  /*Login & register & error*/
  /*Global classes*/
  /*Libs */
}
.app.orange .card .card-header {
  background-color: #f3f3f3;
}
.app.orange .card .card-header.transparent {
  background-color: transparent;
}
.app.orange .card.card-primary {
  background-color: #3E50B4;
  border-color: #2F3E9E;
}
.app.orange .card.card-primary .card-header {
  background-color: #2F3E9E;
}
.app.orange .card.card-primary .card-footer {
  background-color: rgba(47, 62, 158, 0.5);
}
.app.orange .card.card-success {
  background-color: #4BAE4F;
  border-color: #378D3B;
}
.app.orange .card.card-success .card-header {
  background-color: #378D3B;
}
.app.orange .card.card-success .card-footer {
  background-color: rgba(55, 141, 59, 0.5);
}
.app.orange .card.card-info {
  background-color: #00BBD3;
  border-color: #0096A6;
}
.app.orange .card.card-info .card-header {
  background-color: #0096A6;
}
.app.orange .card.card-info .card-footer {
  background-color: rgba(0, 150, 166, 0.5);
}
.app.orange .card.card-warning {
  background-color: #FE9700;
  border-color: #F47B00;
}
.app.orange .card.card-warning .card-header {
  background-color: #F47B00;
}
.app.orange .card.card-warning .card-footer {
  background-color: rgba(244, 123, 0, 0.5);
}
.app.orange .card.card-danger {
  background-color: #F34235;
  border-color: #D22E2E;
}
.app.orange .card.card-danger .card-header {
  background-color: #D22E2E;
}
.app.orange .card.card-danger .card-footer {
  background-color: rgba(210, 46, 46, 0.5);
}
.app.orange .card.card-outline-primary {
  background-color: transparent;
  border: 1px solid #3E50B4;
}
.app.orange .card.card-outline-success {
  background-color: transparent;
  border: 1px solid #4BAE4F;
}
.app.orange .card.card-outline-info {
  background-color: transparent;
  border: 1px solid #00BBD3;
}
.app.orange .card.card-outline-warning {
  background-color: transparent;
  border: 1px solid #FE9700;
}
.app.orange .card.card-outline-danger {
  background-color: transparent;
  border: 1px solid #F34235;
}
.app.orange .card-img-overlay {
  color: #262626;
}
.app.orange .card-inverse .card-link,
.app.orange .card-inverse .card-text,
.app.orange .card-inverse .card-subtitle,
.app.orange .card-inverse .card-blockquote .blockquote-footer {
  color: #f5f5f5;
}
.app.orange .overlay-primary {
  background-color: rgba(62, 80, 180, 0.8);
}
.app.orange .overlay-success {
  background-color: rgba(75, 174, 79, 0.8);
}
.app.orange .overlay-info {
  background-color: rgba(0, 187, 211, 0.8);
}
.app.orange .overlay-warning {
  background-color: rgba(254, 151, 0, 0.8);
}
.app.orange .overlay-danger {
  background-color: rgba(243, 66, 53, 0.8);
}
.app.orange .text-primary {
  color: #3E50B4 !important;
}
.app.orange a.text-primary:focus, .app.orange a.text-primary:hover {
  color: #2F3E9E !important;
}
.app.orange .text-success {
  color: #4BAE4F !important;
}
.app.orange a.text-success:focus, .app.orange a.text-success:hover {
  color: #378D3B !important;
}
.app.orange .text-info {
  color: #00BBD3 !important;
}
.app.orange a.text-info:focus, .app.orange a.text-info:hover {
  color: #0096A6 !important;
}
.app.orange .text-warning {
  color: #FE9700 !important;
}
.app.orange a.text-warning:focus, .app.orange a.text-warning:hover {
  color: #F47B00 !important;
}
.app.orange .text-danger {
  color: #F34235 !important;
}
.app.orange a.text-danger:focus, .app.orange a.text-danger:hover {
  color: #D22E2E !important;
}
.app.orange .text-gray {
  color: #9D9D9D !important;
}
.app.orange a.text-gray:focus, .app.orange a.text-gray:hover {
  color: #606060 !important;
}
.app.orange .text-inverse {
  color: #292B2C !important;
}
.app.orange a.text-inverse:focus, .app.orange a.text-inverse:hover {
  color: #1C1E1F !important;
}
.app.orange .breadcrumb {
  padding: 0.5rem 1rem;
}
.app.orange .breadcrumb a {
  color: #3E50B4;
}
.app.orange .btn-primary {
  color: #fff;
  background-color: #3E50B4;
  border-color: #3E50B4;
}
.app.orange .btn-primary:hover, .app.orange .btn-primary:active, .app.orange .btn-primary.active {
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.orange .btn-primary:focus, .app.orange .btn-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.orange .show > .btn-primary.dropdown-toggle {
  background-color: #2F3E9E;
}
.app.orange .btn-success {
  color: #fff;
  background-color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.orange .btn-success:hover, .app.orange .btn-success:active, .app.orange .btn-success.active {
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.orange .btn-success:focus, .app.orange .btn-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.orange .show > .btn-success.dropdown-toggle {
  background-color: #378D3B;
}
.app.orange .btn-info {
  color: #fff;
  background-color: #00BBD3;
  border-color: #00BBD3;
}
.app.orange .btn-info:hover, .app.orange .btn-info:active, .app.orange .btn-info.active {
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.orange .btn-info:focus, .app.orange .btn-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.orange .show > .btn-info.dropdown-toggle {
  background-color: #0096A6;
}
.app.orange .btn-warning {
  color: #fff;
  background-color: #FE9700;
  border-color: #FE9700;
}
.app.orange .btn-warning:hover, .app.orange .btn-warning:active, .app.orange .btn-warning.active {
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.orange .btn-warning:focus, .app.orange .btn-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.orange .show > .btn-warning.dropdown-toggle {
  background-color: #F47B00;
}
.app.orange .btn-danger {
  color: #fff;
  background-color: #F34235;
  border-color: #F34235;
}
.app.orange .btn-danger:hover, .app.orange .btn-danger:active, .app.orange .btn-danger.active {
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.orange .btn-danger:focus, .app.orange .btn-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.orange .show > .btn-danger.dropdown-toggle {
  background-color: #D22E2E;
}
.app.orange .btn-gray {
  color: #fff;
  background-color: #9D9D9D;
}
.app.orange .btn-gray:hover, .app.orange .btn-gray:active, .app.orange .btn-gray.active {
  background-color: #606060;
}
.app.orange .btn-gray:focus, .app.orange .btn-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.orange .show > .btn-gray.dropdown-toggle {
  background-color: #606060;
}
.app.orange .btn-inverse {
  color: #fff;
  background-color: #292B2C;
}
.app.orange .btn-inverse:hover, .app.orange .btn-inverse:active, .app.orange .btn-inverse.active {
  background-color: #1C1E1F;
}
.app.orange .btn-inverse:focus, .app.orange .btn-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.orange .show > .btn-inverse.dropdown-toggle {
  background-color: #1C1E1F;
}
.app.orange .btn-rounded {
  border-radius: 5rem;
}
.app.orange .btn-outline-primary {
  color: #3E50B4;
  border-color: #3E50B4;
}
.app.orange .btn-outline-primary:hover, .app.orange .btn-outline-primary:active, .app.orange .btn-outline-primary.active {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.orange .btn-outline-primary:focus, .app.orange .btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.orange .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.orange .btn-outline-success {
  color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.orange .btn-outline-success:hover, .app.orange .btn-outline-success:active, .app.orange .btn-outline-success.active {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.orange .btn-outline-success:focus, .app.orange .btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.orange .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.orange .btn-outline-info {
  color: #00BBD3;
  border-color: #00BBD3;
}
.app.orange .btn-outline-info:hover, .app.orange .btn-outline-info:active, .app.orange .btn-outline-info.active {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.orange .btn-outline-info:focus, .app.orange .btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.orange .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.orange .btn-outline-warning {
  color: #FE9700;
  border-color: #FE9700;
}
.app.orange .btn-outline-warning:hover, .app.orange .btn-outline-warning:active, .app.orange .btn-outline-warning.active {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.orange .btn-outline-warning:focus, .app.orange .btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.orange .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.orange .btn-outline-danger {
  color: #F34235;
  border-color: #F34235;
}
.app.orange .btn-outline-danger:hover, .app.orange .btn-outline-danger:active, .app.orange .btn-outline-danger.active {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.orange .btn-outline-danger:focus, .app.orange .btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.orange .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.orange .btn-outline-gray {
  color: #9D9D9D;
  border-color: #9D9D9D;
  background-color: transparent;
}
.app.orange .btn-outline-gray:hover, .app.orange .btn-outline-gray:active, .app.orange .btn-outline-gray.active {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.orange .btn-outline-gray:focus, .app.orange .btn-outline-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.orange .show > .btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.orange .btn-outline-inverse {
  color: #292B2C;
  border-color: #292B2C;
  background-color: transparent;
}
.app.orange .btn-outline-inverse:hover, .app.orange .btn-outline-inverse:active, .app.orange .btn-outline-inverse.active {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.orange .btn-outline-inverse:focus, .app.orange .btn-outline-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.orange .show > .btn-outline-inverse.dropdown-toggle {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.orange .btn-link {
  border: 1px solid transparent;
}
.app.orange .badge {
  padding: 4px 6px;
}
.app.orange .badge-primary {
  background-color: #3E50B4;
}
.app.orange .badge-success {
  background-color: #4BAE4F;
}
.app.orange .badge-info {
  background-color: #00BBD3;
}
.app.orange .badge-warning {
  background-color: #FE9700;
}
.app.orange .badge-danger {
  background-color: #F34235;
}
.app.orange .badge-gray {
  background-color: #9D9D9D;
}
.app.orange .badge-white {
  background-color: #fff;
}
.app.orange .alert-primary {
  background-color: #b1c3ff;
  border-color: #97a9ff;
  color: #3E50B4;
}
.app.orange .alert-success {
  background-color: #beffc2;
  border-color: #8bee8f;
  color: #4BAE4F;
}
.app.orange .alert-info {
  background-color: #a6ffff;
  border-color: #40fbff;
  color: #00BBD3;
}
.app.orange .alert-warning {
  background-color: #ffffa6;
  border-color: #fff059;
  color: #FE9700;
}
.app.orange .alert-danger {
  background-color: #ffb5a8;
  border-color: #ff9b8e;
  color: #F34235;
}
.app.orange .alert-gray {
  background-color: #f3f3f3;
  border-color: #dddddd;
  color: #9D9D9D;
}
.app.orange .modal {
  z-index: 999999;
  padding-top: 5%;
}
.app.orange .modal-dialog .modal-content .modal-header {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  padding: 10px 15px;
}
.app.orange .modal-dialog .modal-content .modal-header.modal-primary {
  color: #fff;
  background-color: #3E50B4;
}
.app.orange .modal-dialog .modal-content .modal-header.modal-success {
  color: #fff;
  background-color: #4BAE4F;
}
.app.orange .modal-dialog .modal-content .modal-header.modal-info {
  color: #fff;
  background-color: #00BBD3;
}
.app.orange .modal-dialog .modal-content .modal-header.modal-warning {
  color: #fff;
  background-color: #FE9700;
}
.app.orange .modal-dialog .modal-content .modal-header.modal-danger {
  color: #fff;
  background-color: #F34235;
}
.app.orange .modal-dialog .modal-content .modal-header i {
  margin-right: 10px;
}
.app.orange .bg-primary {
  background-color: #3E50B4 !important;
}
.app.orange .bg-success {
  background-color: #4BAE4F !important;
}
.app.orange .bg-info {
  background-color: #00BBD3 !important;
}
.app.orange .bg-warning {
  background-color: #FE9700 !important;
}
.app.orange .bg-danger {
  background-color: #F34235 !important;
}
.app.orange .bg-gray {
  background-color: #9D9D9D !important;
}
.app.orange .bg-light {
  background-color: #f5f5f5 !important;
}
.app.orange .progress-xl {
  height: 2rem;
}
.app.orange .progress-lg {
  height: 1.5rem;
}
.app.orange .progress-md {
  height: 1rem;
}
.app.orange .progress-sm {
  height: 0.5rem;
}
.app.orange .progress-xs {
  height: 0.25rem;
}
.app.orange .nav-tabs.custom {
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
  width: 100%;
}
.app.orange .nav-tabs.custom .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border: none;
  cursor: default;
}
.app.orange .nav-tabs.custom .nav-item .nav-link.active {
  background-color: #fff;
  color: #606060;
}
.app.orange .nav-tabs.custom .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.orange .nav-tabs.custom.top {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.app.orange .nav-tabs.custom.bottom {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.app.orange .nav-tabs.custom.bottom .nav-item {
  margin-bottom: 0;
  margin-top: -1px;
}
.app.orange .nav-tabs.custom.bottom .nav-item .nav-link {
  border-radius: 0 0 0.3rem 0.3rem;
}
.app.orange .nav-tabs.custom.tabs-primary {
  background-color: rgba(62, 80, 180, 0.4);
  border: 1px solid #3E50B4;
}
.app.orange .nav-tabs.custom.tabs-primary .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.orange .nav-tabs.custom.tabs-primary .nav-item .nav-link.active {
  background-color: #3E50B4;
  color: #fff;
}
.app.orange .nav-tabs.custom.tabs-primary .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.orange .nav-tabs.custom.tabs-success {
  background-color: rgba(75, 174, 79, 0.4);
  border: 1px solid #4BAE4F;
}
.app.orange .nav-tabs.custom.tabs-success .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.orange .nav-tabs.custom.tabs-success .nav-item .nav-link.active {
  background-color: #4BAE4F;
  color: #fff;
}
.app.orange .nav-tabs.custom.tabs-success .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.orange .nav-tabs.custom.tabs-info {
  background-color: rgba(0, 187, 211, 0.4);
  border: 1px solid #00BBD3;
}
.app.orange .nav-tabs.custom.tabs-info .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.orange .nav-tabs.custom.tabs-info .nav-item .nav-link.active {
  background-color: #00BBD3;
  color: #fff;
}
.app.orange .nav-tabs.custom.tabs-info .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.orange .nav-tabs.custom.tabs-warning {
  background-color: rgba(254, 151, 0, 0.4);
  border: 1px solid #FE9700;
}
.app.orange .nav-tabs.custom.tabs-warning .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.orange .nav-tabs.custom.tabs-warning .nav-item .nav-link.active {
  background-color: #FE9700;
  color: #fff;
}
.app.orange .nav-tabs.custom.tabs-warning .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.orange .nav-tabs.custom.tabs-danger {
  background-color: rgba(243, 66, 53, 0.4);
  border: 1px solid #F34235;
}
.app.orange .nav-tabs.custom.tabs-danger .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.orange .nav-tabs.custom.tabs-danger .nav-item .nav-link.active {
  background-color: #F34235;
  color: #fff;
}
.app.orange .nav-tabs.custom.tabs-danger .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.orange .tab-content {
  position: relative;
  z-index: 1;
  border: 1px solid #9D9D9D;
  background: #fff;
}
.app.orange .tab-content .tab-pane {
  padding: 1rem;
}
.app.orange .tab-content.tab-content-primary {
  background-color: #3E50B4;
  color: #fff;
  border-color: #3E50B4;
}
.app.orange .tab-content.tab-content-success {
  background-color: #4BAE4F;
  color: #fff;
  border-color: #4BAE4F;
}
.app.orange .tab-content.tab-content-info {
  background-color: #00BBD3;
  color: #fff;
  border-color: #00BBD3;
}
.app.orange .tab-content.tab-content-warning {
  background-color: #FE9700;
  color: #fff;
  border-color: #FE9700;
}
.app.orange .tab-content.tab-content-danger {
  background-color: #F34235;
  color: #fff;
  border-color: #F34235;
}
.app.orange .tab-content.top {
  border-top: none;
}
.app.orange .tab-content.bottom {
  border-bottom: none;
}
.app.orange .vertical-tabs .nav {
  padding-right: 0;
  overflow: hidden;
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
}
.app.orange .vertical-tabs .nav.left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-right: none;
  margin-right: -1px;
  z-index: 2;
}
.app.orange .vertical-tabs .nav.right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-left: none;
  margin-left: -1px;
  z-index: 2;
}
.app.orange .vertical-tabs .nav .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border-radius: 0;
  cursor: default;
}
.app.orange .vertical-tabs .nav .nav-item .nav-link.active {
  background-color: #fff;
}
.app.orange .vertical-tabs .nav .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.orange .vertical-tabs .nav .nav-item .nav-link.disabled {
  color: rgba(96, 96, 96, 0.6);
  cursor: not-allowed !important;
}
.app.orange .list-group-item-primary {
  color: #3E50B4;
  background-color: #b1c3ff;
}
.app.orange .list-group-item-success {
  color: #4BAE4F;
  background-color: #beffc2;
}
.app.orange .list-group-item-info {
  color: #00BBD3;
  background-color: #a6ffff;
}
.app.orange .list-group-item-warning {
  color: #FE9700;
  background-color: #ffffa6;
}
.app.orange .list-group-item-danger {
  color: #F34235;
  background-color: #ffb5a8;
}
.app.orange .no-caret.dropdown-toggle::after {
  content: none;
}
.app.orange .dropdown-item.active, .app.orange .dropdown-item:active {
  background-color: #ccc;
}
.app.orange .dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.app.orange .dropdown.show .dropdown-menu {
  max-height: 300px;
  opacity: 1;
  pointer-events: auto;
}
.app.orange .dropdown.open .dropdown-menu {
  opacity: 1;
  pointer-events: auto;
}
.app.orange .dropdown-menu-right {
  right: 0;
  left: auto;
}
.app.orange .form-group label {
  margin-bottom: 0.2rem;
}
.app.orange .form-control-rounded {
  border-radius: 16px;
}
.app.orange .form-control:focus {
  border-color: rgba(254, 151, 0, 0.6);
}
.app.orange input[type=color].form-control {
  padding: 0;
}
.app.orange .has-success .form-control {
  border-color: #4BAE4F;
}
.app.orange .has-success .form-control:focus {
  border-color: #378D3B;
}
.app.orange .has-success .form-control-feedback, .app.orange .has-success .form-control-label, .app.orange .has-success .form-check-label, .app.orange .has-success .form-check-inline, .app.orange .has-success .custom-control {
  color: #4BAE4F;
}
.app.orange .has-warning .form-control {
  border-color: #FE9700;
}
.app.orange .has-warning .form-control:focus {
  border-color: #F47B00;
}
.app.orange .has-warning .form-control-feedback, .app.orange .has-warning .form-control-label, .app.orange .has-warning .form-check-label, .app.orange .has-warning .form-check-inline, .app.orange .has-warning .custom-control {
  color: #FE9700;
}
.app.orange .has-danger .form-control {
  border-color: #F34235;
}
.app.orange .has-danger .form-control:focus {
  border-color: #D22E2E;
}
.app.orange .has-danger .form-control-feedback, .app.orange .has-danger .form-control-label, .app.orange .has-danger .form-check-label, .app.orange .has-danger .form-check-inline, .app.orange .has-danger .custom-control {
  color: #F34235;
}
.app.orange .has-success .input-group-prepend,
.app.orange .has-success .input-group-append,
.app.orange .has-success .input-group-prepend .input-group-text,
.app.orange .has-success .input-group-append .input-group-text {
  color: #4BAE4F;
  border-color: #4BAE4F;
  background-color: #eaf6ea;
}
.app.orange .has-danger .input-group-prepend,
.app.orange .has-danger .input-group-append,
.app.orange .has-danger .input-group-prepend .input-group-text,
.app.orange .has-danger .input-group-append .input-group-text {
  color: #F34235;
  border-color: #F34235;
  background-color: #fdf7f7;
}
.app.orange .form-control.validation-field.ng-touched {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.625rem;
  background-size: 1.25rem 1.25rem;
}
.app.orange .form-control.validation-field.ng-touched.ng-invalid {
  border-color: #F34235;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23F34235' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
}
.app.orange .form-control.validation-field.ng-touched.ng-valid {
  border-color: #4BAE4F;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%234BAE4F' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}
.app.orange .custom-control-label::before {
  width: 14px;
  height: 14px;
  top: 0.3rem;
}
.app.orange .custom-control-label::after {
  width: 14px;
  height: 14px;
  top: 0.3rem;
  background-size: 60% 60%;
}
.app.orange .custom-checkbox.checkbox-circle .custom-control-label::before,
.app.orange .custom-checkbox.checkbox-circle .custom-control-label::after {
  border-radius: 50%;
}
.app.orange .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.orange .custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.orange .custom-checkbox .custom-control-input.checkbox-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.orange .custom-checkbox .custom-control-input.checkbox-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.orange .custom-checkbox .custom-control-input.checkbox-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.orange .custom-checkbox .custom-control-input.checkbox-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.orange .custom-checkbox .custom-control-input.checkbox-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.orange .custom-checkbox .custom-control-input.checkbox-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.orange .custom-checkbox .custom-control-input.checkbox-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.orange .custom-checkbox .custom-control-input.checkbox-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.orange .custom-checkbox .custom-control-input.checkbox-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.orange .custom-checkbox .custom-control-input.checkbox-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.orange .custom-checkbox .custom-control-input.checkbox-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.orange .custom-checkbox .custom-control-input.checkbox-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.orange .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.orange .custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.orange .custom-radio .custom-control-input.radio-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.orange .custom-radio .custom-control-input.radio-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.orange .custom-radio .custom-control-input.radio-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.orange .custom-radio .custom-control-input.radio-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.orange .custom-radio .custom-control-input.radio-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.orange .custom-radio .custom-control-input.radio-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.orange .custom-radio .custom-control-input.radio-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.orange .custom-radio .custom-control-input.radio-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.orange .custom-radio .custom-control-input.radio-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.orange .custom-radio .custom-control-input.radio-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.orange .custom-radio .custom-control-input.radio-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.orange .custom-radio .custom-control-input.radio-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.orange .table-success,
.app.orange .table-success > th,
.app.orange .table-success > td {
  background-color: #4BAE4F;
}
.app.orange .table-hover .table-success:hover {
  background-color: #439c47;
}
.app.orange .table-hover .table-success:hover > td,
.app.orange .table-hover .table-success:hover > th {
  background-color: #439c47;
}
.app.orange .table-info,
.app.orange .table-info > th,
.app.orange .table-info > td {
  background-color: #00BBD3;
}
.app.orange .table-hover .table-info:hover {
  background-color: #00a4ba;
}
.app.orange .table-hover .table-info:hover > td,
.app.orange .table-hover .table-info:hover > th {
  background-color: #00a4ba;
}
.app.orange .table-warning,
.app.orange .table-warning > th,
.app.orange .table-warning > td {
  background-color: #FE9700;
}
.app.orange .table-hover .table-warning:hover {
  background-color: #e58800;
}
.app.orange .table-hover .table-warning:hover > td,
.app.orange .table-hover .table-warning:hover > th {
  background-color: #e58800;
}
.app.orange .table-danger,
.app.orange .table-danger > th,
.app.orange .table-danger > td {
  background-color: #F34235;
}
.app.orange .table-hover .table-danger:hover {
  background-color: #f22b1d;
}
.app.orange .table-hover .table-danger:hover > td,
.app.orange .table-hover .table-danger:hover > th {
  background-color: #f22b1d;
}
.app.orange .rounded-0 {
  border-radius: 0 !important;
}
.app.orange .popover.bs-popover-top .arrow,
.app.orange .popover.bs-popover-auto[x-placement^=top] .arrow,
.app.orange .popover.bs-popover-auto[x-placement^=bottom] .arrow,
.app.orange .popover.bs-popover-bottom .arrow {
  left: 50%;
}
.app.orange .popover.bs-popover-auto[x-placement^=right] .arrow,
.app.orange .popover.bs-popover-right .arrow,
.app.orange .popover.bs-popover-auto[x-placement^=left] .arrow,
.app.orange .popover.bs-popover-left .arrow {
  top: 50%;
}
.app.orange .popover.bs-popover-bottom .arrow::after,
.app.orange .popover.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #f7f7f7;
}
.app.orange .top-navbar {
  background-color: #F47B00;
  color: #FEDFB1;
}
.app.orange .top-navbar .app-search {
  border-bottom: 1px solid #FEDFB1;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.orange .top-navbar .app-search .form-control {
  color: #FEDFB1;
}
.app.orange .top-navbar .app-search input[type=text]::-webkit-input-placeholder {
  color: rgba(254, 223, 177, 0.8);
}
.app.orange .top-navbar .app-search input[type=text]::-moz-placeholder {
  color: rgba(254, 223, 177, 0.8);
}
.app.orange .top-navbar .app-search input[type=text]:-moz-placeholder {
  color: rgba(254, 223, 177, 0.8);
}
.app.orange .top-navbar .app-search input[type=text]:-ms-input-placeholder {
  color: rgba(254, 223, 177, 0.8);
}
.app.orange .top-navbar .app-search textarea::-webkit-input-placeholder {
  color: rgba(254, 223, 177, 0.8);
}
.app.orange .top-navbar .app-search textarea::-moz-placeholder {
  color: rgba(254, 223, 177, 0.8);
}
.app.orange .top-navbar .app-search textarea:-moz-placeholder {
  color: rgba(254, 223, 177, 0.8);
}
.app.orange .top-navbar .app-search textarea:-ms-input-placeholder {
  color: rgba(254, 223, 177, 0.8);
}
.app.orange .top-navbar .app-search i {
  color: #FEDFB1;
}
.app.orange .top-navbar a {
  color: #FEDFB1;
}
.app.orange .logo {
  color: #fff !important;
}
.app.orange .logo .ng {
  color: #fff;
}
.app.orange .menu-icon .line {
  background-color: #FEDFB1;
}
.app.orange #info-content-icon .triangle {
  border-color: #FEDFB1 transparent transparent transparent;
}
.app.orange #info-content-icon.show {
  background: #FE9700;
  box-shadow: 0px 0px 0px 8px #FE9700, 0px -16px 0px 8px #FE9700;
}
.app.orange #info-content-icon.show .triangle {
  border-color: #FEDFB1 transparent transparent transparent;
}
.app.orange .menu-wrapper .card a {
  color: #FEDFB1;
  border-bottom: 1px solid rgba(244, 123, 0, 0.5);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1);
}
.app.orange .menu-wrapper .card a:hover {
  color: #fff;
  background-color: #F47B00 !important;
}
.app.orange .menu-wrapper .card a.active-link {
  background-color: #ffac33 !important;
  color: #fff;
}
.app.orange .menu-wrapper .card .card a {
  padding-left: 15px;
  background-color: rgba(0, 0, 0, 0.3);
}
.app.orange .menu-wrapper .card .card .card a {
  padding-left: 30px;
  background-color: rgba(0, 0, 0, 0.4);
}
.app.orange .menu-wrapper .card .card .card .card a {
  padding-left: 45px;
  background-color: rgba(0, 0, 0, 0.5);
}
.app.orange .menu-wrapper .card .card .card .card .card a {
  padding-left: 60px;
  background-color: rgba(0, 0, 0, 0.6);
}
.app.orange .menu-wrapper .card .card .card .card .card .card a {
  padding-left: 75px;
  background-color: rgba(0, 0, 0, 0.7);
}
.app.orange .menu-wrapper .card .card .card .card .card .card .card a {
  padding-left: 90px;
  background-color: rgba(0, 0, 0, 0.8);
}
.app.orange .menu-wrapper .card .card .card .card .card .card .card .card a {
  padding-left: 105px;
  background-color: rgba(0, 0, 0, 0.9);
}
.app.orange .menu-wrapper .card .card .card .card .card .card .card .card .card a {
  padding-left: 120px;
  background-color: black;
}
.app.orange .menu-wrapper .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 135px;
  background-color: black;
}
.app.orange .menu-wrapper .card .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 150px;
  background-color: black;
}
.app.orange .wrapper, .app.orange .wrapper-inner {
  background-color: #f5f5f5;
  color: #565656;
}
.app.orange #info-content {
  background-color: #FE9700;
}
.app.orange #info-content a.close {
  color: #FEDFB1;
}
.app.orange #info-content .info {
  color: #FEDFB1;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.orange #info-content .info .form-control {
  background-color: #FE9700;
  border-color: rgba(254, 223, 177, 0.4);
  color: #FEDFB1;
}
.app.orange #info-content .info input[type=text]::-webkit-input-placeholder {
  color: rgba(254, 223, 177, 0.7);
}
.app.orange #info-content .info input[type=text]::-moz-placeholder {
  color: rgba(254, 223, 177, 0.7);
}
.app.orange #info-content .info input[type=text]:-moz-placeholder {
  color: rgba(254, 223, 177, 0.7);
}
.app.orange #info-content .info input[type=text]:-ms-input-placeholder {
  color: rgba(254, 223, 177, 0.7);
}
.app.orange #info-content .info textarea::-webkit-input-placeholder {
  color: rgba(254, 223, 177, 0.7);
}
.app.orange #info-content .info textarea::-moz-placeholder {
  color: rgba(254, 223, 177, 0.7);
}
.app.orange #info-content .info textarea:-moz-placeholder {
  color: rgba(254, 223, 177, 0.7);
}
.app.orange #info-content .info textarea:-ms-input-placeholder {
  color: rgba(254, 223, 177, 0.7);
}
.app.orange #info-content .left-border {
  border-left: 1px solid rgba(254, 223, 177, 0.3);
}
.app.orange #messagesDropDown .dropdown-menu .nav-link.active {
  border-bottom: 4px solid #F47B00;
}
.app.orange .dropdown-menu .user-menu .user-info {
  background-color: #F47B00;
  color: #FEDFB1;
}
.app.orange .favorites-container {
  border-left: 1px solid rgba(254, 223, 177, 0.4);
  border-right: 1px solid rgba(254, 223, 177, 0.4);
}
.app.orange #favorites .dropdown .btn-block {
  background-color: #F47B00;
  color: #FEDFB1;
}
.app.orange .side-chat .media {
  color: #FEDFB1;
  background-color: #FE9700;
  border-bottom: 1px solid #f5f5f5;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.orange .side-chat .media input[type=text]::-webkit-input-placeholder {
  color: rgba(254, 223, 177, 0.8);
}
.app.orange .side-chat .media input[type=text]::-moz-placeholder {
  color: rgba(254, 223, 177, 0.8);
}
.app.orange .side-chat .media input[type=text]:-moz-placeholder {
  color: rgba(254, 223, 177, 0.8);
}
.app.orange .side-chat .media input[type=text]:-ms-input-placeholder {
  color: rgba(254, 223, 177, 0.8);
}
.app.orange .side-chat .media textarea::-webkit-input-placeholder {
  color: rgba(254, 223, 177, 0.8);
}
.app.orange .side-chat .media textarea::-moz-placeholder {
  color: rgba(254, 223, 177, 0.8);
}
.app.orange .side-chat .media textarea:-moz-placeholder {
  color: rgba(254, 223, 177, 0.8);
}
.app.orange .side-chat .media textarea:-ms-input-placeholder {
  color: rgba(254, 223, 177, 0.8);
}
.app.orange .side-chat .chat-window {
  color: #FEDFB1;
  background-color: #FE9700;
}
.app.orange .side-chat .chat-window .header {
  border-bottom: 1px solid #FEDFB1;
}
.app.orange .sidebar-outer {
  background-color: #FE9700;
}
.app.orange .sidebar-outer .logo {
  background-color: #F47B00;
}
.app.orange .vertical-menu-tooltip-place .tooltip {
  opacity: 1;
}
.app.orange .vertical-menu-tooltip-place .tooltip.bs-tooltip-right .arrow::before, .app.orange .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #FE9700;
}
.app.orange .vertical-menu-tooltip-place .tooltip.bs-tooltip-bottom .arrow::before, .app.orange .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #FE9700;
}
.app.orange .vertical-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #FE9700;
}
.app.orange .horizontal-menu-outer {
  background-color: #FE9700;
}
.app.orange #navigation .menu li {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.app.orange #navigation .menu li a {
  color: #FEDFB1;
}
.app.orange #navigation .menu li a:hover {
  color: #fff;
  background-color: #F47B00 !important;
}
.app.orange #navigation .menu li a.active-link {
  background-color: #ffac33 !important;
  color: #fff;
}
.app.orange #navigation .menu li:first-child {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.app.orange #navigation .menu .sub-menu {
  background-color: #FE9700;
}
.app.orange #navigation ul.menu ul a,
.app.orange #navigation .menu ul ul a {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
}
.app.orange .horizontal-menu-tooltip-place .tooltip {
  opacity: 1;
  z-index: 999999;
}
.app.orange .horizontal-menu-tooltip-place .tooltip.bs-tooltip-top .arrow::before, .app.orange .horizontal-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #000;
}
.app.orange .horizontal-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #000 !important;
}
.app.orange .app-footer {
  background-color: #F47B00;
  color: #FEDFB1;
}
.app.orange .login-container .card, .app.orange .register-container .card, .app.orange .error-container .card {
  background-color: #FE9700;
  color: #F5F5F5;
}
.app.orange .login-container .card .card-header, .app.orange .register-container .card .card-header, .app.orange .error-container .card .card-header {
  background-color: #F47B00;
}
.app.orange .login-container .card .btn, .app.orange .register-container .card .btn, .app.orange .error-container .card .btn {
  background-color: #F47B00;
  color: #F5F5F5;
}
.app.orange .box-shadow {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(254, 151, 0, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(254, 151, 0, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(254, 151, 0, 0.3);
}
.app.orange .widget-controls a {
  color: #a6a6a6;
}
.app.orange .widget-controls a:hover {
  color: rgba(166, 166, 166, 0.7);
}
.app.brown {
  /*Card*/
  /*Text*/
  /*Breadcrumb*/
  /*Buttons*/
  /*Badge*/
  /*Alerts*/
  /* MODALS */
  /*BG*/
  /*Progress*/
  /*Tabs*/
  /*List group*/
  /*DropDown*/
  /* actual dropdown animation */
  /*Forms*/
  /*Tables*/
  /*Popovers*/
  /*Top Navbar*/
  /*Side-Chat*/
  /*Sidebar*/
  /*Horizontal-menu*/
  /*Footer*/
  /*Login & register & error*/
  /*Global classes*/
  /*Libs */
}
.app.brown .card .card-header {
  background-color: #f3f3f3;
}
.app.brown .card .card-header.transparent {
  background-color: transparent;
}
.app.brown .card.card-primary {
  background-color: #3E50B4;
  border-color: #2F3E9E;
}
.app.brown .card.card-primary .card-header {
  background-color: #2F3E9E;
}
.app.brown .card.card-primary .card-footer {
  background-color: rgba(47, 62, 158, 0.5);
}
.app.brown .card.card-success {
  background-color: #4BAE4F;
  border-color: #378D3B;
}
.app.brown .card.card-success .card-header {
  background-color: #378D3B;
}
.app.brown .card.card-success .card-footer {
  background-color: rgba(55, 141, 59, 0.5);
}
.app.brown .card.card-info {
  background-color: #00BBD3;
  border-color: #0096A6;
}
.app.brown .card.card-info .card-header {
  background-color: #0096A6;
}
.app.brown .card.card-info .card-footer {
  background-color: rgba(0, 150, 166, 0.5);
}
.app.brown .card.card-warning {
  background-color: #FE9700;
  border-color: #F47B00;
}
.app.brown .card.card-warning .card-header {
  background-color: #F47B00;
}
.app.brown .card.card-warning .card-footer {
  background-color: rgba(244, 123, 0, 0.5);
}
.app.brown .card.card-danger {
  background-color: #F34235;
  border-color: #D22E2E;
}
.app.brown .card.card-danger .card-header {
  background-color: #D22E2E;
}
.app.brown .card.card-danger .card-footer {
  background-color: rgba(210, 46, 46, 0.5);
}
.app.brown .card.card-outline-primary {
  background-color: transparent;
  border: 1px solid #3E50B4;
}
.app.brown .card.card-outline-success {
  background-color: transparent;
  border: 1px solid #4BAE4F;
}
.app.brown .card.card-outline-info {
  background-color: transparent;
  border: 1px solid #00BBD3;
}
.app.brown .card.card-outline-warning {
  background-color: transparent;
  border: 1px solid #FE9700;
}
.app.brown .card.card-outline-danger {
  background-color: transparent;
  border: 1px solid #F34235;
}
.app.brown .card-img-overlay {
  color: #262626;
}
.app.brown .card-inverse .card-link,
.app.brown .card-inverse .card-text,
.app.brown .card-inverse .card-subtitle,
.app.brown .card-inverse .card-blockquote .blockquote-footer {
  color: #f5f5f5;
}
.app.brown .overlay-primary {
  background-color: rgba(62, 80, 180, 0.8);
}
.app.brown .overlay-success {
  background-color: rgba(75, 174, 79, 0.8);
}
.app.brown .overlay-info {
  background-color: rgba(0, 187, 211, 0.8);
}
.app.brown .overlay-warning {
  background-color: rgba(254, 151, 0, 0.8);
}
.app.brown .overlay-danger {
  background-color: rgba(243, 66, 53, 0.8);
}
.app.brown .text-primary {
  color: #3E50B4 !important;
}
.app.brown a.text-primary:focus, .app.brown a.text-primary:hover {
  color: #2F3E9E !important;
}
.app.brown .text-success {
  color: #4BAE4F !important;
}
.app.brown a.text-success:focus, .app.brown a.text-success:hover {
  color: #378D3B !important;
}
.app.brown .text-info {
  color: #00BBD3 !important;
}
.app.brown a.text-info:focus, .app.brown a.text-info:hover {
  color: #0096A6 !important;
}
.app.brown .text-warning {
  color: #FE9700 !important;
}
.app.brown a.text-warning:focus, .app.brown a.text-warning:hover {
  color: #F47B00 !important;
}
.app.brown .text-danger {
  color: #F34235 !important;
}
.app.brown a.text-danger:focus, .app.brown a.text-danger:hover {
  color: #D22E2E !important;
}
.app.brown .text-gray {
  color: #9D9D9D !important;
}
.app.brown a.text-gray:focus, .app.brown a.text-gray:hover {
  color: #606060 !important;
}
.app.brown .text-inverse {
  color: #292B2C !important;
}
.app.brown a.text-inverse:focus, .app.brown a.text-inverse:hover {
  color: #1C1E1F !important;
}
.app.brown .breadcrumb {
  padding: 0.5rem 1rem;
}
.app.brown .breadcrumb a {
  color: #3E50B4;
}
.app.brown .btn-primary {
  color: #fff;
  background-color: #3E50B4;
  border-color: #3E50B4;
}
.app.brown .btn-primary:hover, .app.brown .btn-primary:active, .app.brown .btn-primary.active {
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.brown .btn-primary:focus, .app.brown .btn-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.brown .show > .btn-primary.dropdown-toggle {
  background-color: #2F3E9E;
}
.app.brown .btn-success {
  color: #fff;
  background-color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.brown .btn-success:hover, .app.brown .btn-success:active, .app.brown .btn-success.active {
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.brown .btn-success:focus, .app.brown .btn-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.brown .show > .btn-success.dropdown-toggle {
  background-color: #378D3B;
}
.app.brown .btn-info {
  color: #fff;
  background-color: #00BBD3;
  border-color: #00BBD3;
}
.app.brown .btn-info:hover, .app.brown .btn-info:active, .app.brown .btn-info.active {
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.brown .btn-info:focus, .app.brown .btn-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.brown .show > .btn-info.dropdown-toggle {
  background-color: #0096A6;
}
.app.brown .btn-warning {
  color: #fff;
  background-color: #FE9700;
  border-color: #FE9700;
}
.app.brown .btn-warning:hover, .app.brown .btn-warning:active, .app.brown .btn-warning.active {
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.brown .btn-warning:focus, .app.brown .btn-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.brown .show > .btn-warning.dropdown-toggle {
  background-color: #F47B00;
}
.app.brown .btn-danger {
  color: #fff;
  background-color: #F34235;
  border-color: #F34235;
}
.app.brown .btn-danger:hover, .app.brown .btn-danger:active, .app.brown .btn-danger.active {
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.brown .btn-danger:focus, .app.brown .btn-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.brown .show > .btn-danger.dropdown-toggle {
  background-color: #D22E2E;
}
.app.brown .btn-gray {
  color: #fff;
  background-color: #9D9D9D;
}
.app.brown .btn-gray:hover, .app.brown .btn-gray:active, .app.brown .btn-gray.active {
  background-color: #606060;
}
.app.brown .btn-gray:focus, .app.brown .btn-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.brown .show > .btn-gray.dropdown-toggle {
  background-color: #606060;
}
.app.brown .btn-inverse {
  color: #fff;
  background-color: #292B2C;
}
.app.brown .btn-inverse:hover, .app.brown .btn-inverse:active, .app.brown .btn-inverse.active {
  background-color: #1C1E1F;
}
.app.brown .btn-inverse:focus, .app.brown .btn-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.brown .show > .btn-inverse.dropdown-toggle {
  background-color: #1C1E1F;
}
.app.brown .btn-rounded {
  border-radius: 5rem;
}
.app.brown .btn-outline-primary {
  color: #3E50B4;
  border-color: #3E50B4;
}
.app.brown .btn-outline-primary:hover, .app.brown .btn-outline-primary:active, .app.brown .btn-outline-primary.active {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.brown .btn-outline-primary:focus, .app.brown .btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.brown .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.brown .btn-outline-success {
  color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.brown .btn-outline-success:hover, .app.brown .btn-outline-success:active, .app.brown .btn-outline-success.active {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.brown .btn-outline-success:focus, .app.brown .btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.brown .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.brown .btn-outline-info {
  color: #00BBD3;
  border-color: #00BBD3;
}
.app.brown .btn-outline-info:hover, .app.brown .btn-outline-info:active, .app.brown .btn-outline-info.active {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.brown .btn-outline-info:focus, .app.brown .btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.brown .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.brown .btn-outline-warning {
  color: #FE9700;
  border-color: #FE9700;
}
.app.brown .btn-outline-warning:hover, .app.brown .btn-outline-warning:active, .app.brown .btn-outline-warning.active {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.brown .btn-outline-warning:focus, .app.brown .btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.brown .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.brown .btn-outline-danger {
  color: #F34235;
  border-color: #F34235;
}
.app.brown .btn-outline-danger:hover, .app.brown .btn-outline-danger:active, .app.brown .btn-outline-danger.active {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.brown .btn-outline-danger:focus, .app.brown .btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.brown .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.brown .btn-outline-gray {
  color: #9D9D9D;
  border-color: #9D9D9D;
  background-color: transparent;
}
.app.brown .btn-outline-gray:hover, .app.brown .btn-outline-gray:active, .app.brown .btn-outline-gray.active {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.brown .btn-outline-gray:focus, .app.brown .btn-outline-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.brown .show > .btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.brown .btn-outline-inverse {
  color: #292B2C;
  border-color: #292B2C;
  background-color: transparent;
}
.app.brown .btn-outline-inverse:hover, .app.brown .btn-outline-inverse:active, .app.brown .btn-outline-inverse.active {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.brown .btn-outline-inverse:focus, .app.brown .btn-outline-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.brown .show > .btn-outline-inverse.dropdown-toggle {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.brown .btn-link {
  border: 1px solid transparent;
}
.app.brown .badge {
  padding: 4px 6px;
}
.app.brown .badge-primary {
  background-color: #3E50B4;
}
.app.brown .badge-success {
  background-color: #4BAE4F;
}
.app.brown .badge-info {
  background-color: #00BBD3;
}
.app.brown .badge-warning {
  background-color: #FE9700;
}
.app.brown .badge-danger {
  background-color: #F34235;
}
.app.brown .badge-gray {
  background-color: #9D9D9D;
}
.app.brown .badge-white {
  background-color: #fff;
}
.app.brown .alert-primary {
  background-color: #b1c3ff;
  border-color: #97a9ff;
  color: #3E50B4;
}
.app.brown .alert-success {
  background-color: #beffc2;
  border-color: #8bee8f;
  color: #4BAE4F;
}
.app.brown .alert-info {
  background-color: #a6ffff;
  border-color: #40fbff;
  color: #00BBD3;
}
.app.brown .alert-warning {
  background-color: #ffffa6;
  border-color: #fff059;
  color: #FE9700;
}
.app.brown .alert-danger {
  background-color: #ffb5a8;
  border-color: #ff9b8e;
  color: #F34235;
}
.app.brown .alert-gray {
  background-color: #f3f3f3;
  border-color: #dddddd;
  color: #9D9D9D;
}
.app.brown .modal {
  z-index: 999999;
  padding-top: 5%;
}
.app.brown .modal-dialog .modal-content .modal-header {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  padding: 10px 15px;
}
.app.brown .modal-dialog .modal-content .modal-header.modal-primary {
  color: #fff;
  background-color: #3E50B4;
}
.app.brown .modal-dialog .modal-content .modal-header.modal-success {
  color: #fff;
  background-color: #4BAE4F;
}
.app.brown .modal-dialog .modal-content .modal-header.modal-info {
  color: #fff;
  background-color: #00BBD3;
}
.app.brown .modal-dialog .modal-content .modal-header.modal-warning {
  color: #fff;
  background-color: #FE9700;
}
.app.brown .modal-dialog .modal-content .modal-header.modal-danger {
  color: #fff;
  background-color: #F34235;
}
.app.brown .modal-dialog .modal-content .modal-header i {
  margin-right: 10px;
}
.app.brown .bg-primary {
  background-color: #3E50B4 !important;
}
.app.brown .bg-success {
  background-color: #4BAE4F !important;
}
.app.brown .bg-info {
  background-color: #00BBD3 !important;
}
.app.brown .bg-warning {
  background-color: #FE9700 !important;
}
.app.brown .bg-danger {
  background-color: #F34235 !important;
}
.app.brown .bg-gray {
  background-color: #9D9D9D !important;
}
.app.brown .bg-light {
  background-color: #f5f5f5 !important;
}
.app.brown .progress-xl {
  height: 2rem;
}
.app.brown .progress-lg {
  height: 1.5rem;
}
.app.brown .progress-md {
  height: 1rem;
}
.app.brown .progress-sm {
  height: 0.5rem;
}
.app.brown .progress-xs {
  height: 0.25rem;
}
.app.brown .nav-tabs.custom {
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
  width: 100%;
}
.app.brown .nav-tabs.custom .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border: none;
  cursor: default;
}
.app.brown .nav-tabs.custom .nav-item .nav-link.active {
  background-color: #fff;
  color: #606060;
}
.app.brown .nav-tabs.custom .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.brown .nav-tabs.custom.top {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.app.brown .nav-tabs.custom.bottom {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.app.brown .nav-tabs.custom.bottom .nav-item {
  margin-bottom: 0;
  margin-top: -1px;
}
.app.brown .nav-tabs.custom.bottom .nav-item .nav-link {
  border-radius: 0 0 0.3rem 0.3rem;
}
.app.brown .nav-tabs.custom.tabs-primary {
  background-color: rgba(62, 80, 180, 0.4);
  border: 1px solid #3E50B4;
}
.app.brown .nav-tabs.custom.tabs-primary .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.brown .nav-tabs.custom.tabs-primary .nav-item .nav-link.active {
  background-color: #3E50B4;
  color: #fff;
}
.app.brown .nav-tabs.custom.tabs-primary .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.brown .nav-tabs.custom.tabs-success {
  background-color: rgba(75, 174, 79, 0.4);
  border: 1px solid #4BAE4F;
}
.app.brown .nav-tabs.custom.tabs-success .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.brown .nav-tabs.custom.tabs-success .nav-item .nav-link.active {
  background-color: #4BAE4F;
  color: #fff;
}
.app.brown .nav-tabs.custom.tabs-success .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.brown .nav-tabs.custom.tabs-info {
  background-color: rgba(0, 187, 211, 0.4);
  border: 1px solid #00BBD3;
}
.app.brown .nav-tabs.custom.tabs-info .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.brown .nav-tabs.custom.tabs-info .nav-item .nav-link.active {
  background-color: #00BBD3;
  color: #fff;
}
.app.brown .nav-tabs.custom.tabs-info .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.brown .nav-tabs.custom.tabs-warning {
  background-color: rgba(254, 151, 0, 0.4);
  border: 1px solid #FE9700;
}
.app.brown .nav-tabs.custom.tabs-warning .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.brown .nav-tabs.custom.tabs-warning .nav-item .nav-link.active {
  background-color: #FE9700;
  color: #fff;
}
.app.brown .nav-tabs.custom.tabs-warning .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.brown .nav-tabs.custom.tabs-danger {
  background-color: rgba(243, 66, 53, 0.4);
  border: 1px solid #F34235;
}
.app.brown .nav-tabs.custom.tabs-danger .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.brown .nav-tabs.custom.tabs-danger .nav-item .nav-link.active {
  background-color: #F34235;
  color: #fff;
}
.app.brown .nav-tabs.custom.tabs-danger .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.brown .tab-content {
  position: relative;
  z-index: 1;
  border: 1px solid #9D9D9D;
  background: #fff;
}
.app.brown .tab-content .tab-pane {
  padding: 1rem;
}
.app.brown .tab-content.tab-content-primary {
  background-color: #3E50B4;
  color: #fff;
  border-color: #3E50B4;
}
.app.brown .tab-content.tab-content-success {
  background-color: #4BAE4F;
  color: #fff;
  border-color: #4BAE4F;
}
.app.brown .tab-content.tab-content-info {
  background-color: #00BBD3;
  color: #fff;
  border-color: #00BBD3;
}
.app.brown .tab-content.tab-content-warning {
  background-color: #FE9700;
  color: #fff;
  border-color: #FE9700;
}
.app.brown .tab-content.tab-content-danger {
  background-color: #F34235;
  color: #fff;
  border-color: #F34235;
}
.app.brown .tab-content.top {
  border-top: none;
}
.app.brown .tab-content.bottom {
  border-bottom: none;
}
.app.brown .vertical-tabs .nav {
  padding-right: 0;
  overflow: hidden;
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
}
.app.brown .vertical-tabs .nav.left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-right: none;
  margin-right: -1px;
  z-index: 2;
}
.app.brown .vertical-tabs .nav.right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-left: none;
  margin-left: -1px;
  z-index: 2;
}
.app.brown .vertical-tabs .nav .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border-radius: 0;
  cursor: default;
}
.app.brown .vertical-tabs .nav .nav-item .nav-link.active {
  background-color: #fff;
}
.app.brown .vertical-tabs .nav .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.brown .vertical-tabs .nav .nav-item .nav-link.disabled {
  color: rgba(96, 96, 96, 0.6);
  cursor: not-allowed !important;
}
.app.brown .list-group-item-primary {
  color: #3E50B4;
  background-color: #b1c3ff;
}
.app.brown .list-group-item-success {
  color: #4BAE4F;
  background-color: #beffc2;
}
.app.brown .list-group-item-info {
  color: #00BBD3;
  background-color: #a6ffff;
}
.app.brown .list-group-item-warning {
  color: #FE9700;
  background-color: #ffffa6;
}
.app.brown .list-group-item-danger {
  color: #F34235;
  background-color: #ffb5a8;
}
.app.brown .no-caret.dropdown-toggle::after {
  content: none;
}
.app.brown .dropdown-item.active, .app.brown .dropdown-item:active {
  background-color: #ccc;
}
.app.brown .dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.app.brown .dropdown.show .dropdown-menu {
  max-height: 300px;
  opacity: 1;
  pointer-events: auto;
}
.app.brown .dropdown.open .dropdown-menu {
  opacity: 1;
  pointer-events: auto;
}
.app.brown .dropdown-menu-right {
  right: 0;
  left: auto;
}
.app.brown .form-group label {
  margin-bottom: 0.2rem;
}
.app.brown .form-control-rounded {
  border-radius: 16px;
}
.app.brown .form-control:focus {
  border-color: rgba(120, 84, 71, 0.6);
}
.app.brown input[type=color].form-control {
  padding: 0;
}
.app.brown .has-success .form-control {
  border-color: #4BAE4F;
}
.app.brown .has-success .form-control:focus {
  border-color: #378D3B;
}
.app.brown .has-success .form-control-feedback, .app.brown .has-success .form-control-label, .app.brown .has-success .form-check-label, .app.brown .has-success .form-check-inline, .app.brown .has-success .custom-control {
  color: #4BAE4F;
}
.app.brown .has-warning .form-control {
  border-color: #FE9700;
}
.app.brown .has-warning .form-control:focus {
  border-color: #F47B00;
}
.app.brown .has-warning .form-control-feedback, .app.brown .has-warning .form-control-label, .app.brown .has-warning .form-check-label, .app.brown .has-warning .form-check-inline, .app.brown .has-warning .custom-control {
  color: #FE9700;
}
.app.brown .has-danger .form-control {
  border-color: #F34235;
}
.app.brown .has-danger .form-control:focus {
  border-color: #D22E2E;
}
.app.brown .has-danger .form-control-feedback, .app.brown .has-danger .form-control-label, .app.brown .has-danger .form-check-label, .app.brown .has-danger .form-check-inline, .app.brown .has-danger .custom-control {
  color: #F34235;
}
.app.brown .has-success .input-group-prepend,
.app.brown .has-success .input-group-append,
.app.brown .has-success .input-group-prepend .input-group-text,
.app.brown .has-success .input-group-append .input-group-text {
  color: #4BAE4F;
  border-color: #4BAE4F;
  background-color: #eaf6ea;
}
.app.brown .has-danger .input-group-prepend,
.app.brown .has-danger .input-group-append,
.app.brown .has-danger .input-group-prepend .input-group-text,
.app.brown .has-danger .input-group-append .input-group-text {
  color: #F34235;
  border-color: #F34235;
  background-color: #fdf7f7;
}
.app.brown .form-control.validation-field.ng-touched {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.625rem;
  background-size: 1.25rem 1.25rem;
}
.app.brown .form-control.validation-field.ng-touched.ng-invalid {
  border-color: #F34235;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23F34235' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
}
.app.brown .form-control.validation-field.ng-touched.ng-valid {
  border-color: #4BAE4F;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%234BAE4F' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}
.app.brown .custom-control-label::before {
  width: 14px;
  height: 14px;
  top: 0.3rem;
}
.app.brown .custom-control-label::after {
  width: 14px;
  height: 14px;
  top: 0.3rem;
  background-size: 60% 60%;
}
.app.brown .custom-checkbox.checkbox-circle .custom-control-label::before,
.app.brown .custom-checkbox.checkbox-circle .custom-control-label::after {
  border-radius: 50%;
}
.app.brown .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.brown .custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.brown .custom-checkbox .custom-control-input.checkbox-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.brown .custom-checkbox .custom-control-input.checkbox-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.brown .custom-checkbox .custom-control-input.checkbox-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.brown .custom-checkbox .custom-control-input.checkbox-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.brown .custom-checkbox .custom-control-input.checkbox-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.brown .custom-checkbox .custom-control-input.checkbox-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.brown .custom-checkbox .custom-control-input.checkbox-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.brown .custom-checkbox .custom-control-input.checkbox-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.brown .custom-checkbox .custom-control-input.checkbox-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.brown .custom-checkbox .custom-control-input.checkbox-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.brown .custom-checkbox .custom-control-input.checkbox-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.brown .custom-checkbox .custom-control-input.checkbox-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.brown .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.brown .custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.brown .custom-radio .custom-control-input.radio-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.brown .custom-radio .custom-control-input.radio-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.brown .custom-radio .custom-control-input.radio-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.brown .custom-radio .custom-control-input.radio-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.brown .custom-radio .custom-control-input.radio-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.brown .custom-radio .custom-control-input.radio-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.brown .custom-radio .custom-control-input.radio-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.brown .custom-radio .custom-control-input.radio-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.brown .custom-radio .custom-control-input.radio-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.brown .custom-radio .custom-control-input.radio-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.brown .custom-radio .custom-control-input.radio-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.brown .custom-radio .custom-control-input.radio-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.brown .table-success,
.app.brown .table-success > th,
.app.brown .table-success > td {
  background-color: #4BAE4F;
}
.app.brown .table-hover .table-success:hover {
  background-color: #439c47;
}
.app.brown .table-hover .table-success:hover > td,
.app.brown .table-hover .table-success:hover > th {
  background-color: #439c47;
}
.app.brown .table-info,
.app.brown .table-info > th,
.app.brown .table-info > td {
  background-color: #00BBD3;
}
.app.brown .table-hover .table-info:hover {
  background-color: #00a4ba;
}
.app.brown .table-hover .table-info:hover > td,
.app.brown .table-hover .table-info:hover > th {
  background-color: #00a4ba;
}
.app.brown .table-warning,
.app.brown .table-warning > th,
.app.brown .table-warning > td {
  background-color: #FE9700;
}
.app.brown .table-hover .table-warning:hover {
  background-color: #e58800;
}
.app.brown .table-hover .table-warning:hover > td,
.app.brown .table-hover .table-warning:hover > th {
  background-color: #e58800;
}
.app.brown .table-danger,
.app.brown .table-danger > th,
.app.brown .table-danger > td {
  background-color: #F34235;
}
.app.brown .table-hover .table-danger:hover {
  background-color: #f22b1d;
}
.app.brown .table-hover .table-danger:hover > td,
.app.brown .table-hover .table-danger:hover > th {
  background-color: #f22b1d;
}
.app.brown .rounded-0 {
  border-radius: 0 !important;
}
.app.brown .popover.bs-popover-top .arrow,
.app.brown .popover.bs-popover-auto[x-placement^=top] .arrow,
.app.brown .popover.bs-popover-auto[x-placement^=bottom] .arrow,
.app.brown .popover.bs-popover-bottom .arrow {
  left: 50%;
}
.app.brown .popover.bs-popover-auto[x-placement^=right] .arrow,
.app.brown .popover.bs-popover-right .arrow,
.app.brown .popover.bs-popover-auto[x-placement^=left] .arrow,
.app.brown .popover.bs-popover-left .arrow {
  top: 50%;
}
.app.brown .popover.bs-popover-bottom .arrow::after,
.app.brown .popover.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #f7f7f7;
}
.app.brown .top-navbar {
  background-color: #5C3F36;
  color: #D6CBC7;
}
.app.brown .top-navbar .app-search {
  border-bottom: 1px solid #D6CBC7;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.brown .top-navbar .app-search .form-control {
  color: #D6CBC7;
}
.app.brown .top-navbar .app-search input[type=text]::-webkit-input-placeholder {
  color: rgba(214, 203, 199, 0.8);
}
.app.brown .top-navbar .app-search input[type=text]::-moz-placeholder {
  color: rgba(214, 203, 199, 0.8);
}
.app.brown .top-navbar .app-search input[type=text]:-moz-placeholder {
  color: rgba(214, 203, 199, 0.8);
}
.app.brown .top-navbar .app-search input[type=text]:-ms-input-placeholder {
  color: rgba(214, 203, 199, 0.8);
}
.app.brown .top-navbar .app-search textarea::-webkit-input-placeholder {
  color: rgba(214, 203, 199, 0.8);
}
.app.brown .top-navbar .app-search textarea::-moz-placeholder {
  color: rgba(214, 203, 199, 0.8);
}
.app.brown .top-navbar .app-search textarea:-moz-placeholder {
  color: rgba(214, 203, 199, 0.8);
}
.app.brown .top-navbar .app-search textarea:-ms-input-placeholder {
  color: rgba(214, 203, 199, 0.8);
}
.app.brown .top-navbar .app-search i {
  color: #D6CBC7;
}
.app.brown .top-navbar a {
  color: #D6CBC7;
}
.app.brown .logo {
  color: #fff !important;
}
.app.brown .logo .ng {
  color: #fff;
}
.app.brown .menu-icon .line {
  background-color: #D6CBC7;
}
.app.brown #info-content-icon .triangle {
  border-color: #D6CBC7 transparent transparent transparent;
}
.app.brown #info-content-icon.show {
  background: #785447;
  box-shadow: 0px 0px 0px 8px #785447, 0px -16px 0px 8px #785447;
}
.app.brown #info-content-icon.show .triangle {
  border-color: #D6CBC7 transparent transparent transparent;
}
.app.brown .menu-wrapper .card a {
  color: #D6CBC7;
  border-bottom: 1px solid rgba(92, 63, 54, 0.5);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1);
}
.app.brown .menu-wrapper .card a:hover {
  color: #fff;
  background-color: #5C3F36 !important;
}
.app.brown .menu-wrapper .card a.active-link {
  background-color: #a57e71 !important;
  color: #fff;
}
.app.brown .menu-wrapper .card .card a {
  padding-left: 15px;
  background-color: rgba(0, 0, 0, 0.3);
}
.app.brown .menu-wrapper .card .card .card a {
  padding-left: 30px;
  background-color: rgba(0, 0, 0, 0.4);
}
.app.brown .menu-wrapper .card .card .card .card a {
  padding-left: 45px;
  background-color: rgba(0, 0, 0, 0.5);
}
.app.brown .menu-wrapper .card .card .card .card .card a {
  padding-left: 60px;
  background-color: rgba(0, 0, 0, 0.6);
}
.app.brown .menu-wrapper .card .card .card .card .card .card a {
  padding-left: 75px;
  background-color: rgba(0, 0, 0, 0.7);
}
.app.brown .menu-wrapper .card .card .card .card .card .card .card a {
  padding-left: 90px;
  background-color: rgba(0, 0, 0, 0.8);
}
.app.brown .menu-wrapper .card .card .card .card .card .card .card .card a {
  padding-left: 105px;
  background-color: rgba(0, 0, 0, 0.9);
}
.app.brown .menu-wrapper .card .card .card .card .card .card .card .card .card a {
  padding-left: 120px;
  background-color: black;
}
.app.brown .menu-wrapper .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 135px;
  background-color: black;
}
.app.brown .menu-wrapper .card .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 150px;
  background-color: black;
}
.app.brown .wrapper, .app.brown .wrapper-inner {
  background-color: #f5f5f5;
  color: #565656;
}
.app.brown #info-content {
  background-color: #785447;
}
.app.brown #info-content a.close {
  color: #D6CBC7;
}
.app.brown #info-content .info {
  color: #D6CBC7;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.brown #info-content .info .form-control {
  background-color: #785447;
  border-color: rgba(214, 203, 199, 0.4);
  color: #D6CBC7;
}
.app.brown #info-content .info input[type=text]::-webkit-input-placeholder {
  color: rgba(214, 203, 199, 0.7);
}
.app.brown #info-content .info input[type=text]::-moz-placeholder {
  color: rgba(214, 203, 199, 0.7);
}
.app.brown #info-content .info input[type=text]:-moz-placeholder {
  color: rgba(214, 203, 199, 0.7);
}
.app.brown #info-content .info input[type=text]:-ms-input-placeholder {
  color: rgba(214, 203, 199, 0.7);
}
.app.brown #info-content .info textarea::-webkit-input-placeholder {
  color: rgba(214, 203, 199, 0.7);
}
.app.brown #info-content .info textarea::-moz-placeholder {
  color: rgba(214, 203, 199, 0.7);
}
.app.brown #info-content .info textarea:-moz-placeholder {
  color: rgba(214, 203, 199, 0.7);
}
.app.brown #info-content .info textarea:-ms-input-placeholder {
  color: rgba(214, 203, 199, 0.7);
}
.app.brown #info-content .left-border {
  border-left: 1px solid rgba(214, 203, 199, 0.3);
}
.app.brown #messagesDropDown .dropdown-menu .nav-link.active {
  border-bottom: 4px solid #5C3F36;
}
.app.brown .dropdown-menu .user-menu .user-info {
  background-color: #5C3F36;
  color: #D6CBC7;
}
.app.brown .favorites-container {
  border-left: 1px solid rgba(214, 203, 199, 0.4);
  border-right: 1px solid rgba(214, 203, 199, 0.4);
}
.app.brown #favorites .dropdown .btn-block {
  background-color: #5C3F36;
  color: #D6CBC7;
}
.app.brown .side-chat .media {
  color: #D6CBC7;
  background-color: #785447;
  border-bottom: 1px solid #f5f5f5;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.brown .side-chat .media input[type=text]::-webkit-input-placeholder {
  color: rgba(214, 203, 199, 0.8);
}
.app.brown .side-chat .media input[type=text]::-moz-placeholder {
  color: rgba(214, 203, 199, 0.8);
}
.app.brown .side-chat .media input[type=text]:-moz-placeholder {
  color: rgba(214, 203, 199, 0.8);
}
.app.brown .side-chat .media input[type=text]:-ms-input-placeholder {
  color: rgba(214, 203, 199, 0.8);
}
.app.brown .side-chat .media textarea::-webkit-input-placeholder {
  color: rgba(214, 203, 199, 0.8);
}
.app.brown .side-chat .media textarea::-moz-placeholder {
  color: rgba(214, 203, 199, 0.8);
}
.app.brown .side-chat .media textarea:-moz-placeholder {
  color: rgba(214, 203, 199, 0.8);
}
.app.brown .side-chat .media textarea:-ms-input-placeholder {
  color: rgba(214, 203, 199, 0.8);
}
.app.brown .side-chat .chat-window {
  color: #D6CBC7;
  background-color: #785447;
}
.app.brown .side-chat .chat-window .header {
  border-bottom: 1px solid #D6CBC7;
}
.app.brown .sidebar-outer {
  background-color: #785447;
}
.app.brown .sidebar-outer .logo {
  background-color: #5C3F36;
}
.app.brown .vertical-menu-tooltip-place .tooltip {
  opacity: 1;
}
.app.brown .vertical-menu-tooltip-place .tooltip.bs-tooltip-right .arrow::before, .app.brown .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #785447;
}
.app.brown .vertical-menu-tooltip-place .tooltip.bs-tooltip-bottom .arrow::before, .app.brown .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #785447;
}
.app.brown .vertical-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #785447;
}
.app.brown .horizontal-menu-outer {
  background-color: #785447;
}
.app.brown #navigation .menu li {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.app.brown #navigation .menu li a {
  color: #D6CBC7;
}
.app.brown #navigation .menu li a:hover {
  color: #fff;
  background-color: #5C3F36 !important;
}
.app.brown #navigation .menu li a.active-link {
  background-color: #a57e71 !important;
  color: #fff;
}
.app.brown #navigation .menu li:first-child {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.app.brown #navigation .menu .sub-menu {
  background-color: #785447;
}
.app.brown #navigation ul.menu ul a,
.app.brown #navigation .menu ul ul a {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
}
.app.brown .horizontal-menu-tooltip-place .tooltip {
  opacity: 1;
  z-index: 999999;
}
.app.brown .horizontal-menu-tooltip-place .tooltip.bs-tooltip-top .arrow::before, .app.brown .horizontal-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #000;
}
.app.brown .horizontal-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #000 !important;
}
.app.brown .app-footer {
  background-color: #5C3F36;
  color: #D6CBC7;
}
.app.brown .login-container .card, .app.brown .register-container .card, .app.brown .error-container .card {
  background-color: #a57e71;
  color: #F5F5F5;
}
.app.brown .login-container .card .card-header, .app.brown .register-container .card .card-header, .app.brown .error-container .card .card-header {
  background-color: #785447;
}
.app.brown .login-container .card .btn, .app.brown .register-container .card .btn, .app.brown .error-container .card .btn {
  background-color: #785447;
  color: #F5F5F5;
}
.app.brown .box-shadow {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(120, 84, 71, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(120, 84, 71, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(120, 84, 71, 0.3);
}
.app.brown .widget-controls a {
  color: #a6a6a6;
}
.app.brown .widget-controls a:hover {
  color: rgba(166, 166, 166, 0.7);
}
.app.grey {
  /*Card*/
  /*Text*/
  /*Breadcrumb*/
  /*Buttons*/
  /*Badge*/
  /*Alerts*/
  /* MODALS */
  /*BG*/
  /*Progress*/
  /*Tabs*/
  /*List group*/
  /*DropDown*/
  /* actual dropdown animation */
  /*Forms*/
  /*Tables*/
  /*Popovers*/
  /*Top Navbar*/
  /*Side-Chat*/
  /*Sidebar*/
  /*Horizontal-menu*/
  /*Footer*/
  /*Login & register & error*/
  /*Global classes*/
  /*Libs */
}
.app.grey .card .card-header {
  background-color: #f3f3f3;
}
.app.grey .card .card-header.transparent {
  background-color: transparent;
}
.app.grey .card.card-primary {
  background-color: #3E50B4;
  border-color: #2F3E9E;
}
.app.grey .card.card-primary .card-header {
  background-color: #2F3E9E;
}
.app.grey .card.card-primary .card-footer {
  background-color: rgba(47, 62, 158, 0.5);
}
.app.grey .card.card-success {
  background-color: #4BAE4F;
  border-color: #378D3B;
}
.app.grey .card.card-success .card-header {
  background-color: #378D3B;
}
.app.grey .card.card-success .card-footer {
  background-color: rgba(55, 141, 59, 0.5);
}
.app.grey .card.card-info {
  background-color: #00BBD3;
  border-color: #0096A6;
}
.app.grey .card.card-info .card-header {
  background-color: #0096A6;
}
.app.grey .card.card-info .card-footer {
  background-color: rgba(0, 150, 166, 0.5);
}
.app.grey .card.card-warning {
  background-color: #FE9700;
  border-color: #F47B00;
}
.app.grey .card.card-warning .card-header {
  background-color: #F47B00;
}
.app.grey .card.card-warning .card-footer {
  background-color: rgba(244, 123, 0, 0.5);
}
.app.grey .card.card-danger {
  background-color: #F34235;
  border-color: #D22E2E;
}
.app.grey .card.card-danger .card-header {
  background-color: #D22E2E;
}
.app.grey .card.card-danger .card-footer {
  background-color: rgba(210, 46, 46, 0.5);
}
.app.grey .card.card-outline-primary {
  background-color: transparent;
  border: 1px solid #3E50B4;
}
.app.grey .card.card-outline-success {
  background-color: transparent;
  border: 1px solid #4BAE4F;
}
.app.grey .card.card-outline-info {
  background-color: transparent;
  border: 1px solid #00BBD3;
}
.app.grey .card.card-outline-warning {
  background-color: transparent;
  border: 1px solid #FE9700;
}
.app.grey .card.card-outline-danger {
  background-color: transparent;
  border: 1px solid #F34235;
}
.app.grey .card-img-overlay {
  color: #262626;
}
.app.grey .card-inverse .card-link,
.app.grey .card-inverse .card-text,
.app.grey .card-inverse .card-subtitle,
.app.grey .card-inverse .card-blockquote .blockquote-footer {
  color: #f5f5f5;
}
.app.grey .overlay-primary {
  background-color: rgba(62, 80, 180, 0.8);
}
.app.grey .overlay-success {
  background-color: rgba(75, 174, 79, 0.8);
}
.app.grey .overlay-info {
  background-color: rgba(0, 187, 211, 0.8);
}
.app.grey .overlay-warning {
  background-color: rgba(254, 151, 0, 0.8);
}
.app.grey .overlay-danger {
  background-color: rgba(243, 66, 53, 0.8);
}
.app.grey .text-primary {
  color: #3E50B4 !important;
}
.app.grey a.text-primary:focus, .app.grey a.text-primary:hover {
  color: #2F3E9E !important;
}
.app.grey .text-success {
  color: #4BAE4F !important;
}
.app.grey a.text-success:focus, .app.grey a.text-success:hover {
  color: #378D3B !important;
}
.app.grey .text-info {
  color: #00BBD3 !important;
}
.app.grey a.text-info:focus, .app.grey a.text-info:hover {
  color: #0096A6 !important;
}
.app.grey .text-warning {
  color: #FE9700 !important;
}
.app.grey a.text-warning:focus, .app.grey a.text-warning:hover {
  color: #F47B00 !important;
}
.app.grey .text-danger {
  color: #F34235 !important;
}
.app.grey a.text-danger:focus, .app.grey a.text-danger:hover {
  color: #D22E2E !important;
}
.app.grey .text-gray {
  color: #9D9D9D !important;
}
.app.grey a.text-gray:focus, .app.grey a.text-gray:hover {
  color: #606060 !important;
}
.app.grey .text-inverse {
  color: #292B2C !important;
}
.app.grey a.text-inverse:focus, .app.grey a.text-inverse:hover {
  color: #1C1E1F !important;
}
.app.grey .breadcrumb {
  padding: 0.5rem 1rem;
}
.app.grey .breadcrumb a {
  color: #3E50B4;
}
.app.grey .btn-primary {
  color: #fff;
  background-color: #3E50B4;
  border-color: #3E50B4;
}
.app.grey .btn-primary:hover, .app.grey .btn-primary:active, .app.grey .btn-primary.active {
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.grey .btn-primary:focus, .app.grey .btn-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.grey .show > .btn-primary.dropdown-toggle {
  background-color: #2F3E9E;
}
.app.grey .btn-success {
  color: #fff;
  background-color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.grey .btn-success:hover, .app.grey .btn-success:active, .app.grey .btn-success.active {
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.grey .btn-success:focus, .app.grey .btn-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.grey .show > .btn-success.dropdown-toggle {
  background-color: #378D3B;
}
.app.grey .btn-info {
  color: #fff;
  background-color: #00BBD3;
  border-color: #00BBD3;
}
.app.grey .btn-info:hover, .app.grey .btn-info:active, .app.grey .btn-info.active {
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.grey .btn-info:focus, .app.grey .btn-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.grey .show > .btn-info.dropdown-toggle {
  background-color: #0096A6;
}
.app.grey .btn-warning {
  color: #fff;
  background-color: #FE9700;
  border-color: #FE9700;
}
.app.grey .btn-warning:hover, .app.grey .btn-warning:active, .app.grey .btn-warning.active {
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.grey .btn-warning:focus, .app.grey .btn-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.grey .show > .btn-warning.dropdown-toggle {
  background-color: #F47B00;
}
.app.grey .btn-danger {
  color: #fff;
  background-color: #F34235;
  border-color: #F34235;
}
.app.grey .btn-danger:hover, .app.grey .btn-danger:active, .app.grey .btn-danger.active {
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.grey .btn-danger:focus, .app.grey .btn-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.grey .show > .btn-danger.dropdown-toggle {
  background-color: #D22E2E;
}
.app.grey .btn-gray {
  color: #fff;
  background-color: #9D9D9D;
}
.app.grey .btn-gray:hover, .app.grey .btn-gray:active, .app.grey .btn-gray.active {
  background-color: #606060;
}
.app.grey .btn-gray:focus, .app.grey .btn-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.grey .show > .btn-gray.dropdown-toggle {
  background-color: #606060;
}
.app.grey .btn-inverse {
  color: #fff;
  background-color: #292B2C;
}
.app.grey .btn-inverse:hover, .app.grey .btn-inverse:active, .app.grey .btn-inverse.active {
  background-color: #1C1E1F;
}
.app.grey .btn-inverse:focus, .app.grey .btn-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.grey .show > .btn-inverse.dropdown-toggle {
  background-color: #1C1E1F;
}
.app.grey .btn-rounded {
  border-radius: 5rem;
}
.app.grey .btn-outline-primary {
  color: #3E50B4;
  border-color: #3E50B4;
}
.app.grey .btn-outline-primary:hover, .app.grey .btn-outline-primary:active, .app.grey .btn-outline-primary.active {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.grey .btn-outline-primary:focus, .app.grey .btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.grey .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.grey .btn-outline-success {
  color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.grey .btn-outline-success:hover, .app.grey .btn-outline-success:active, .app.grey .btn-outline-success.active {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.grey .btn-outline-success:focus, .app.grey .btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.grey .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.grey .btn-outline-info {
  color: #00BBD3;
  border-color: #00BBD3;
}
.app.grey .btn-outline-info:hover, .app.grey .btn-outline-info:active, .app.grey .btn-outline-info.active {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.grey .btn-outline-info:focus, .app.grey .btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.grey .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.grey .btn-outline-warning {
  color: #FE9700;
  border-color: #FE9700;
}
.app.grey .btn-outline-warning:hover, .app.grey .btn-outline-warning:active, .app.grey .btn-outline-warning.active {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.grey .btn-outline-warning:focus, .app.grey .btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.grey .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.grey .btn-outline-danger {
  color: #F34235;
  border-color: #F34235;
}
.app.grey .btn-outline-danger:hover, .app.grey .btn-outline-danger:active, .app.grey .btn-outline-danger.active {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.grey .btn-outline-danger:focus, .app.grey .btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.grey .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.grey .btn-outline-gray {
  color: #9D9D9D;
  border-color: #9D9D9D;
  background-color: transparent;
}
.app.grey .btn-outline-gray:hover, .app.grey .btn-outline-gray:active, .app.grey .btn-outline-gray.active {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.grey .btn-outline-gray:focus, .app.grey .btn-outline-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.grey .show > .btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.grey .btn-outline-inverse {
  color: #292B2C;
  border-color: #292B2C;
  background-color: transparent;
}
.app.grey .btn-outline-inverse:hover, .app.grey .btn-outline-inverse:active, .app.grey .btn-outline-inverse.active {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.grey .btn-outline-inverse:focus, .app.grey .btn-outline-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.grey .show > .btn-outline-inverse.dropdown-toggle {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.grey .btn-link {
  border: 1px solid transparent;
}
.app.grey .badge {
  padding: 4px 6px;
}
.app.grey .badge-primary {
  background-color: #3E50B4;
}
.app.grey .badge-success {
  background-color: #4BAE4F;
}
.app.grey .badge-info {
  background-color: #00BBD3;
}
.app.grey .badge-warning {
  background-color: #FE9700;
}
.app.grey .badge-danger {
  background-color: #F34235;
}
.app.grey .badge-gray {
  background-color: #9D9D9D;
}
.app.grey .badge-white {
  background-color: #fff;
}
.app.grey .alert-primary {
  background-color: #b1c3ff;
  border-color: #97a9ff;
  color: #3E50B4;
}
.app.grey .alert-success {
  background-color: #beffc2;
  border-color: #8bee8f;
  color: #4BAE4F;
}
.app.grey .alert-info {
  background-color: #a6ffff;
  border-color: #40fbff;
  color: #00BBD3;
}
.app.grey .alert-warning {
  background-color: #ffffa6;
  border-color: #fff059;
  color: #FE9700;
}
.app.grey .alert-danger {
  background-color: #ffb5a8;
  border-color: #ff9b8e;
  color: #F34235;
}
.app.grey .alert-gray {
  background-color: #f3f3f3;
  border-color: #dddddd;
  color: #9D9D9D;
}
.app.grey .modal {
  z-index: 999999;
  padding-top: 5%;
}
.app.grey .modal-dialog .modal-content .modal-header {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  padding: 10px 15px;
}
.app.grey .modal-dialog .modal-content .modal-header.modal-primary {
  color: #fff;
  background-color: #3E50B4;
}
.app.grey .modal-dialog .modal-content .modal-header.modal-success {
  color: #fff;
  background-color: #4BAE4F;
}
.app.grey .modal-dialog .modal-content .modal-header.modal-info {
  color: #fff;
  background-color: #00BBD3;
}
.app.grey .modal-dialog .modal-content .modal-header.modal-warning {
  color: #fff;
  background-color: #FE9700;
}
.app.grey .modal-dialog .modal-content .modal-header.modal-danger {
  color: #fff;
  background-color: #F34235;
}
.app.grey .modal-dialog .modal-content .modal-header i {
  margin-right: 10px;
}
.app.grey .bg-primary {
  background-color: #3E50B4 !important;
}
.app.grey .bg-success {
  background-color: #4BAE4F !important;
}
.app.grey .bg-info {
  background-color: #00BBD3 !important;
}
.app.grey .bg-warning {
  background-color: #FE9700 !important;
}
.app.grey .bg-danger {
  background-color: #F34235 !important;
}
.app.grey .bg-gray {
  background-color: #9D9D9D !important;
}
.app.grey .bg-light {
  background-color: #f5f5f5 !important;
}
.app.grey .progress-xl {
  height: 2rem;
}
.app.grey .progress-lg {
  height: 1.5rem;
}
.app.grey .progress-md {
  height: 1rem;
}
.app.grey .progress-sm {
  height: 0.5rem;
}
.app.grey .progress-xs {
  height: 0.25rem;
}
.app.grey .nav-tabs.custom {
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
  width: 100%;
}
.app.grey .nav-tabs.custom .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border: none;
  cursor: default;
}
.app.grey .nav-tabs.custom .nav-item .nav-link.active {
  background-color: #fff;
  color: #606060;
}
.app.grey .nav-tabs.custom .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.grey .nav-tabs.custom.top {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.app.grey .nav-tabs.custom.bottom {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.app.grey .nav-tabs.custom.bottom .nav-item {
  margin-bottom: 0;
  margin-top: -1px;
}
.app.grey .nav-tabs.custom.bottom .nav-item .nav-link {
  border-radius: 0 0 0.3rem 0.3rem;
}
.app.grey .nav-tabs.custom.tabs-primary {
  background-color: rgba(62, 80, 180, 0.4);
  border: 1px solid #3E50B4;
}
.app.grey .nav-tabs.custom.tabs-primary .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.grey .nav-tabs.custom.tabs-primary .nav-item .nav-link.active {
  background-color: #3E50B4;
  color: #fff;
}
.app.grey .nav-tabs.custom.tabs-primary .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.grey .nav-tabs.custom.tabs-success {
  background-color: rgba(75, 174, 79, 0.4);
  border: 1px solid #4BAE4F;
}
.app.grey .nav-tabs.custom.tabs-success .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.grey .nav-tabs.custom.tabs-success .nav-item .nav-link.active {
  background-color: #4BAE4F;
  color: #fff;
}
.app.grey .nav-tabs.custom.tabs-success .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.grey .nav-tabs.custom.tabs-info {
  background-color: rgba(0, 187, 211, 0.4);
  border: 1px solid #00BBD3;
}
.app.grey .nav-tabs.custom.tabs-info .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.grey .nav-tabs.custom.tabs-info .nav-item .nav-link.active {
  background-color: #00BBD3;
  color: #fff;
}
.app.grey .nav-tabs.custom.tabs-info .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.grey .nav-tabs.custom.tabs-warning {
  background-color: rgba(254, 151, 0, 0.4);
  border: 1px solid #FE9700;
}
.app.grey .nav-tabs.custom.tabs-warning .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.grey .nav-tabs.custom.tabs-warning .nav-item .nav-link.active {
  background-color: #FE9700;
  color: #fff;
}
.app.grey .nav-tabs.custom.tabs-warning .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.grey .nav-tabs.custom.tabs-danger {
  background-color: rgba(243, 66, 53, 0.4);
  border: 1px solid #F34235;
}
.app.grey .nav-tabs.custom.tabs-danger .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.grey .nav-tabs.custom.tabs-danger .nav-item .nav-link.active {
  background-color: #F34235;
  color: #fff;
}
.app.grey .nav-tabs.custom.tabs-danger .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.grey .tab-content {
  position: relative;
  z-index: 1;
  border: 1px solid #9D9D9D;
  background: #fff;
}
.app.grey .tab-content .tab-pane {
  padding: 1rem;
}
.app.grey .tab-content.tab-content-primary {
  background-color: #3E50B4;
  color: #fff;
  border-color: #3E50B4;
}
.app.grey .tab-content.tab-content-success {
  background-color: #4BAE4F;
  color: #fff;
  border-color: #4BAE4F;
}
.app.grey .tab-content.tab-content-info {
  background-color: #00BBD3;
  color: #fff;
  border-color: #00BBD3;
}
.app.grey .tab-content.tab-content-warning {
  background-color: #FE9700;
  color: #fff;
  border-color: #FE9700;
}
.app.grey .tab-content.tab-content-danger {
  background-color: #F34235;
  color: #fff;
  border-color: #F34235;
}
.app.grey .tab-content.top {
  border-top: none;
}
.app.grey .tab-content.bottom {
  border-bottom: none;
}
.app.grey .vertical-tabs .nav {
  padding-right: 0;
  overflow: hidden;
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
}
.app.grey .vertical-tabs .nav.left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-right: none;
  margin-right: -1px;
  z-index: 2;
}
.app.grey .vertical-tabs .nav.right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-left: none;
  margin-left: -1px;
  z-index: 2;
}
.app.grey .vertical-tabs .nav .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border-radius: 0;
  cursor: default;
}
.app.grey .vertical-tabs .nav .nav-item .nav-link.active {
  background-color: #fff;
}
.app.grey .vertical-tabs .nav .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.grey .vertical-tabs .nav .nav-item .nav-link.disabled {
  color: rgba(96, 96, 96, 0.6);
  cursor: not-allowed !important;
}
.app.grey .list-group-item-primary {
  color: #3E50B4;
  background-color: #b1c3ff;
}
.app.grey .list-group-item-success {
  color: #4BAE4F;
  background-color: #beffc2;
}
.app.grey .list-group-item-info {
  color: #00BBD3;
  background-color: #a6ffff;
}
.app.grey .list-group-item-warning {
  color: #FE9700;
  background-color: #ffffa6;
}
.app.grey .list-group-item-danger {
  color: #F34235;
  background-color: #ffb5a8;
}
.app.grey .no-caret.dropdown-toggle::after {
  content: none;
}
.app.grey .dropdown-item.active, .app.grey .dropdown-item:active {
  background-color: #ccc;
}
.app.grey .dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.app.grey .dropdown.show .dropdown-menu {
  max-height: 300px;
  opacity: 1;
  pointer-events: auto;
}
.app.grey .dropdown.open .dropdown-menu {
  opacity: 1;
  pointer-events: auto;
}
.app.grey .dropdown-menu-right {
  right: 0;
  left: auto;
}
.app.grey .form-group label {
  margin-bottom: 0.2rem;
}
.app.grey .form-control-rounded {
  border-radius: 16px;
}
.app.grey .form-control:focus {
  border-color: rgba(95, 124, 138, 0.6);
}
.app.grey input[type=color].form-control {
  padding: 0;
}
.app.grey .has-success .form-control {
  border-color: #4BAE4F;
}
.app.grey .has-success .form-control:focus {
  border-color: #378D3B;
}
.app.grey .has-success .form-control-feedback, .app.grey .has-success .form-control-label, .app.grey .has-success .form-check-label, .app.grey .has-success .form-check-inline, .app.grey .has-success .custom-control {
  color: #4BAE4F;
}
.app.grey .has-warning .form-control {
  border-color: #FE9700;
}
.app.grey .has-warning .form-control:focus {
  border-color: #F47B00;
}
.app.grey .has-warning .form-control-feedback, .app.grey .has-warning .form-control-label, .app.grey .has-warning .form-check-label, .app.grey .has-warning .form-check-inline, .app.grey .has-warning .custom-control {
  color: #FE9700;
}
.app.grey .has-danger .form-control {
  border-color: #F34235;
}
.app.grey .has-danger .form-control:focus {
  border-color: #D22E2E;
}
.app.grey .has-danger .form-control-feedback, .app.grey .has-danger .form-control-label, .app.grey .has-danger .form-check-label, .app.grey .has-danger .form-check-inline, .app.grey .has-danger .custom-control {
  color: #F34235;
}
.app.grey .has-success .input-group-prepend,
.app.grey .has-success .input-group-append,
.app.grey .has-success .input-group-prepend .input-group-text,
.app.grey .has-success .input-group-append .input-group-text {
  color: #4BAE4F;
  border-color: #4BAE4F;
  background-color: #eaf6ea;
}
.app.grey .has-danger .input-group-prepend,
.app.grey .has-danger .input-group-append,
.app.grey .has-danger .input-group-prepend .input-group-text,
.app.grey .has-danger .input-group-append .input-group-text {
  color: #F34235;
  border-color: #F34235;
  background-color: #fdf7f7;
}
.app.grey .form-control.validation-field.ng-touched {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.625rem;
  background-size: 1.25rem 1.25rem;
}
.app.grey .form-control.validation-field.ng-touched.ng-invalid {
  border-color: #F34235;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23F34235' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
}
.app.grey .form-control.validation-field.ng-touched.ng-valid {
  border-color: #4BAE4F;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%234BAE4F' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}
.app.grey .custom-control-label::before {
  width: 14px;
  height: 14px;
  top: 0.3rem;
}
.app.grey .custom-control-label::after {
  width: 14px;
  height: 14px;
  top: 0.3rem;
  background-size: 60% 60%;
}
.app.grey .custom-checkbox.checkbox-circle .custom-control-label::before,
.app.grey .custom-checkbox.checkbox-circle .custom-control-label::after {
  border-radius: 50%;
}
.app.grey .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.grey .custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.grey .custom-checkbox .custom-control-input.checkbox-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.grey .custom-checkbox .custom-control-input.checkbox-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.grey .custom-checkbox .custom-control-input.checkbox-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.grey .custom-checkbox .custom-control-input.checkbox-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.grey .custom-checkbox .custom-control-input.checkbox-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.grey .custom-checkbox .custom-control-input.checkbox-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.grey .custom-checkbox .custom-control-input.checkbox-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.grey .custom-checkbox .custom-control-input.checkbox-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.grey .custom-checkbox .custom-control-input.checkbox-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.grey .custom-checkbox .custom-control-input.checkbox-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.grey .custom-checkbox .custom-control-input.checkbox-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.grey .custom-checkbox .custom-control-input.checkbox-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.grey .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.grey .custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.grey .custom-radio .custom-control-input.radio-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.grey .custom-radio .custom-control-input.radio-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.grey .custom-radio .custom-control-input.radio-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.grey .custom-radio .custom-control-input.radio-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.grey .custom-radio .custom-control-input.radio-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.grey .custom-radio .custom-control-input.radio-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.grey .custom-radio .custom-control-input.radio-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.grey .custom-radio .custom-control-input.radio-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.grey .custom-radio .custom-control-input.radio-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.grey .custom-radio .custom-control-input.radio-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.grey .custom-radio .custom-control-input.radio-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.grey .custom-radio .custom-control-input.radio-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.grey .table-success,
.app.grey .table-success > th,
.app.grey .table-success > td {
  background-color: #4BAE4F;
}
.app.grey .table-hover .table-success:hover {
  background-color: #439c47;
}
.app.grey .table-hover .table-success:hover > td,
.app.grey .table-hover .table-success:hover > th {
  background-color: #439c47;
}
.app.grey .table-info,
.app.grey .table-info > th,
.app.grey .table-info > td {
  background-color: #00BBD3;
}
.app.grey .table-hover .table-info:hover {
  background-color: #00a4ba;
}
.app.grey .table-hover .table-info:hover > td,
.app.grey .table-hover .table-info:hover > th {
  background-color: #00a4ba;
}
.app.grey .table-warning,
.app.grey .table-warning > th,
.app.grey .table-warning > td {
  background-color: #FE9700;
}
.app.grey .table-hover .table-warning:hover {
  background-color: #e58800;
}
.app.grey .table-hover .table-warning:hover > td,
.app.grey .table-hover .table-warning:hover > th {
  background-color: #e58800;
}
.app.grey .table-danger,
.app.grey .table-danger > th,
.app.grey .table-danger > td {
  background-color: #F34235;
}
.app.grey .table-hover .table-danger:hover {
  background-color: #f22b1d;
}
.app.grey .table-hover .table-danger:hover > td,
.app.grey .table-hover .table-danger:hover > th {
  background-color: #f22b1d;
}
.app.grey .rounded-0 {
  border-radius: 0 !important;
}
.app.grey .popover.bs-popover-top .arrow,
.app.grey .popover.bs-popover-auto[x-placement^=top] .arrow,
.app.grey .popover.bs-popover-auto[x-placement^=bottom] .arrow,
.app.grey .popover.bs-popover-bottom .arrow {
  left: 50%;
}
.app.grey .popover.bs-popover-auto[x-placement^=right] .arrow,
.app.grey .popover.bs-popover-right .arrow,
.app.grey .popover.bs-popover-auto[x-placement^=left] .arrow,
.app.grey .popover.bs-popover-left .arrow {
  top: 50%;
}
.app.grey .popover.bs-popover-bottom .arrow::after,
.app.grey .popover.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #f7f7f7;
}
.app.grey .top-navbar {
  background-color: #445963;
  color: #CED7DB;
}
.app.grey .top-navbar .app-search {
  border-bottom: 1px solid #CED7DB;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.grey .top-navbar .app-search .form-control {
  color: #CED7DB;
}
.app.grey .top-navbar .app-search input[type=text]::-webkit-input-placeholder {
  color: rgba(206, 215, 219, 0.8);
}
.app.grey .top-navbar .app-search input[type=text]::-moz-placeholder {
  color: rgba(206, 215, 219, 0.8);
}
.app.grey .top-navbar .app-search input[type=text]:-moz-placeholder {
  color: rgba(206, 215, 219, 0.8);
}
.app.grey .top-navbar .app-search input[type=text]:-ms-input-placeholder {
  color: rgba(206, 215, 219, 0.8);
}
.app.grey .top-navbar .app-search textarea::-webkit-input-placeholder {
  color: rgba(206, 215, 219, 0.8);
}
.app.grey .top-navbar .app-search textarea::-moz-placeholder {
  color: rgba(206, 215, 219, 0.8);
}
.app.grey .top-navbar .app-search textarea:-moz-placeholder {
  color: rgba(206, 215, 219, 0.8);
}
.app.grey .top-navbar .app-search textarea:-ms-input-placeholder {
  color: rgba(206, 215, 219, 0.8);
}
.app.grey .top-navbar .app-search i {
  color: #CED7DB;
}
.app.grey .top-navbar a {
  color: #CED7DB;
}
.app.grey .logo {
  color: #fff !important;
}
.app.grey .logo .ng {
  color: #fff;
}
.app.grey .menu-icon .line {
  background-color: #CED7DB;
}
.app.grey #info-content-icon .triangle {
  border-color: #CED7DB transparent transparent transparent;
}
.app.grey #info-content-icon.show {
  background: #5F7C8A;
  box-shadow: 0px 0px 0px 8px #5F7C8A, 0px -16px 0px 8px #5F7C8A;
}
.app.grey #info-content-icon.show .triangle {
  border-color: #CED7DB transparent transparent transparent;
}
.app.grey .menu-wrapper .card a {
  color: #CED7DB;
  border-bottom: 1px solid rgba(68, 89, 99, 0.5);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1);
}
.app.grey .menu-wrapper .card a:hover {
  color: #fff;
  background-color: #445963 !important;
}
.app.grey .menu-wrapper .card a.active-link {
  background-color: #93a3ab !important;
  color: #fff;
}
.app.grey .menu-wrapper .card .card a {
  padding-left: 15px;
  background-color: rgba(0, 0, 0, 0.3);
}
.app.grey .menu-wrapper .card .card .card a {
  padding-left: 30px;
  background-color: rgba(0, 0, 0, 0.4);
}
.app.grey .menu-wrapper .card .card .card .card a {
  padding-left: 45px;
  background-color: rgba(0, 0, 0, 0.5);
}
.app.grey .menu-wrapper .card .card .card .card .card a {
  padding-left: 60px;
  background-color: rgba(0, 0, 0, 0.6);
}
.app.grey .menu-wrapper .card .card .card .card .card .card a {
  padding-left: 75px;
  background-color: rgba(0, 0, 0, 0.7);
}
.app.grey .menu-wrapper .card .card .card .card .card .card .card a {
  padding-left: 90px;
  background-color: rgba(0, 0, 0, 0.8);
}
.app.grey .menu-wrapper .card .card .card .card .card .card .card .card a {
  padding-left: 105px;
  background-color: rgba(0, 0, 0, 0.9);
}
.app.grey .menu-wrapper .card .card .card .card .card .card .card .card .card a {
  padding-left: 120px;
  background-color: black;
}
.app.grey .menu-wrapper .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 135px;
  background-color: black;
}
.app.grey .menu-wrapper .card .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 150px;
  background-color: black;
}
.app.grey .wrapper, .app.grey .wrapper-inner {
  background-color: #f5f5f5;
  color: #565656;
}
.app.grey #info-content {
  background-color: #5F7C8A;
}
.app.grey #info-content a.close {
  color: #CED7DB;
}
.app.grey #info-content .info {
  color: #CED7DB;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.grey #info-content .info .form-control {
  background-color: #5F7C8A;
  border-color: rgba(206, 215, 219, 0.4);
  color: #CED7DB;
}
.app.grey #info-content .info input[type=text]::-webkit-input-placeholder {
  color: rgba(206, 215, 219, 0.7);
}
.app.grey #info-content .info input[type=text]::-moz-placeholder {
  color: rgba(206, 215, 219, 0.7);
}
.app.grey #info-content .info input[type=text]:-moz-placeholder {
  color: rgba(206, 215, 219, 0.7);
}
.app.grey #info-content .info input[type=text]:-ms-input-placeholder {
  color: rgba(206, 215, 219, 0.7);
}
.app.grey #info-content .info textarea::-webkit-input-placeholder {
  color: rgba(206, 215, 219, 0.7);
}
.app.grey #info-content .info textarea::-moz-placeholder {
  color: rgba(206, 215, 219, 0.7);
}
.app.grey #info-content .info textarea:-moz-placeholder {
  color: rgba(206, 215, 219, 0.7);
}
.app.grey #info-content .info textarea:-ms-input-placeholder {
  color: rgba(206, 215, 219, 0.7);
}
.app.grey #info-content .left-border {
  border-left: 1px solid rgba(206, 215, 219, 0.3);
}
.app.grey #messagesDropDown .dropdown-menu .nav-link.active {
  border-bottom: 4px solid #445963;
}
.app.grey .dropdown-menu .user-menu .user-info {
  background-color: #445963;
  color: #CED7DB;
}
.app.grey .favorites-container {
  border-left: 1px solid rgba(206, 215, 219, 0.4);
  border-right: 1px solid rgba(206, 215, 219, 0.4);
}
.app.grey #favorites .dropdown .btn-block {
  background-color: #445963;
  color: #CED7DB;
}
.app.grey .side-chat .media {
  color: #CED7DB;
  background-color: #5F7C8A;
  border-bottom: 1px solid #f5f5f5;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.grey .side-chat .media input[type=text]::-webkit-input-placeholder {
  color: rgba(206, 215, 219, 0.8);
}
.app.grey .side-chat .media input[type=text]::-moz-placeholder {
  color: rgba(206, 215, 219, 0.8);
}
.app.grey .side-chat .media input[type=text]:-moz-placeholder {
  color: rgba(206, 215, 219, 0.8);
}
.app.grey .side-chat .media input[type=text]:-ms-input-placeholder {
  color: rgba(206, 215, 219, 0.8);
}
.app.grey .side-chat .media textarea::-webkit-input-placeholder {
  color: rgba(206, 215, 219, 0.8);
}
.app.grey .side-chat .media textarea::-moz-placeholder {
  color: rgba(206, 215, 219, 0.8);
}
.app.grey .side-chat .media textarea:-moz-placeholder {
  color: rgba(206, 215, 219, 0.8);
}
.app.grey .side-chat .media textarea:-ms-input-placeholder {
  color: rgba(206, 215, 219, 0.8);
}
.app.grey .side-chat .chat-window {
  color: #CED7DB;
  background-color: #5F7C8A;
}
.app.grey .side-chat .chat-window .header {
  border-bottom: 1px solid #CED7DB;
}
.app.grey .sidebar-outer {
  background-color: #5F7C8A;
}
.app.grey .sidebar-outer .logo {
  background-color: #445963;
}
.app.grey .vertical-menu-tooltip-place .tooltip {
  opacity: 1;
}
.app.grey .vertical-menu-tooltip-place .tooltip.bs-tooltip-right .arrow::before, .app.grey .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #5F7C8A;
}
.app.grey .vertical-menu-tooltip-place .tooltip.bs-tooltip-bottom .arrow::before, .app.grey .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #5F7C8A;
}
.app.grey .vertical-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #5F7C8A;
}
.app.grey .horizontal-menu-outer {
  background-color: #5F7C8A;
}
.app.grey #navigation .menu li {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.app.grey #navigation .menu li a {
  color: #CED7DB;
}
.app.grey #navigation .menu li a:hover {
  color: #fff;
  background-color: #445963 !important;
}
.app.grey #navigation .menu li a.active-link {
  background-color: #93a3ab !important;
  color: #fff;
}
.app.grey #navigation .menu li:first-child {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.app.grey #navigation .menu .sub-menu {
  background-color: #5F7C8A;
}
.app.grey #navigation ul.menu ul a,
.app.grey #navigation .menu ul ul a {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
}
.app.grey .horizontal-menu-tooltip-place .tooltip {
  opacity: 1;
  z-index: 999999;
}
.app.grey .horizontal-menu-tooltip-place .tooltip.bs-tooltip-top .arrow::before, .app.grey .horizontal-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #000;
}
.app.grey .horizontal-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #000 !important;
}
.app.grey .app-footer {
  background-color: #445963;
  color: #CED7DB;
}
.app.grey .login-container .card, .app.grey .register-container .card, .app.grey .error-container .card {
  background-color: #5F7C8A;
  color: #F5F5F5;
}
.app.grey .login-container .card .card-header, .app.grey .register-container .card .card-header, .app.grey .error-container .card .card-header {
  background-color: #445963;
}
.app.grey .login-container .card .btn, .app.grey .register-container .card .btn, .app.grey .error-container .card .btn {
  background-color: #445963;
  color: #F5F5F5;
}
.app.grey .box-shadow {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(95, 124, 138, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(95, 124, 138, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(95, 124, 138, 0.3);
}
.app.grey .widget-controls a {
  color: #a6a6a6;
}
.app.grey .widget-controls a:hover {
  color: rgba(166, 166, 166, 0.7);
}
.app.pink {
  /*Card*/
  /*Text*/
  /*Breadcrumb*/
  /*Buttons*/
  /*Badge*/
  /*Alerts*/
  /* MODALS */
  /*BG*/
  /*Progress*/
  /*Tabs*/
  /*List group*/
  /*DropDown*/
  /* actual dropdown animation */
  /*Forms*/
  /*Tables*/
  /*Popovers*/
  /*Top Navbar*/
  /*Side-Chat*/
  /*Sidebar*/
  /*Horizontal-menu*/
  /*Footer*/
  /*Login & register & error*/
  /*Global classes*/
  /*Libs */
}
.app.pink .card .card-header {
  background-color: #f3f3f3;
}
.app.pink .card .card-header.transparent {
  background-color: transparent;
}
.app.pink .card.card-primary {
  background-color: #3E50B4;
  border-color: #2F3E9E;
}
.app.pink .card.card-primary .card-header {
  background-color: #2F3E9E;
}
.app.pink .card.card-primary .card-footer {
  background-color: rgba(47, 62, 158, 0.5);
}
.app.pink .card.card-success {
  background-color: #4BAE4F;
  border-color: #378D3B;
}
.app.pink .card.card-success .card-header {
  background-color: #378D3B;
}
.app.pink .card.card-success .card-footer {
  background-color: rgba(55, 141, 59, 0.5);
}
.app.pink .card.card-info {
  background-color: #00BBD3;
  border-color: #0096A6;
}
.app.pink .card.card-info .card-header {
  background-color: #0096A6;
}
.app.pink .card.card-info .card-footer {
  background-color: rgba(0, 150, 166, 0.5);
}
.app.pink .card.card-warning {
  background-color: #FE9700;
  border-color: #F47B00;
}
.app.pink .card.card-warning .card-header {
  background-color: #F47B00;
}
.app.pink .card.card-warning .card-footer {
  background-color: rgba(244, 123, 0, 0.5);
}
.app.pink .card.card-danger {
  background-color: #F34235;
  border-color: #D22E2E;
}
.app.pink .card.card-danger .card-header {
  background-color: #D22E2E;
}
.app.pink .card.card-danger .card-footer {
  background-color: rgba(210, 46, 46, 0.5);
}
.app.pink .card.card-outline-primary {
  background-color: transparent;
  border: 1px solid #3E50B4;
}
.app.pink .card.card-outline-success {
  background-color: transparent;
  border: 1px solid #4BAE4F;
}
.app.pink .card.card-outline-info {
  background-color: transparent;
  border: 1px solid #00BBD3;
}
.app.pink .card.card-outline-warning {
  background-color: transparent;
  border: 1px solid #FE9700;
}
.app.pink .card.card-outline-danger {
  background-color: transparent;
  border: 1px solid #F34235;
}
.app.pink .card-img-overlay {
  color: #262626;
}
.app.pink .card-inverse .card-link,
.app.pink .card-inverse .card-text,
.app.pink .card-inverse .card-subtitle,
.app.pink .card-inverse .card-blockquote .blockquote-footer {
  color: #f5f5f5;
}
.app.pink .overlay-primary {
  background-color: rgba(62, 80, 180, 0.8);
}
.app.pink .overlay-success {
  background-color: rgba(75, 174, 79, 0.8);
}
.app.pink .overlay-info {
  background-color: rgba(0, 187, 211, 0.8);
}
.app.pink .overlay-warning {
  background-color: rgba(254, 151, 0, 0.8);
}
.app.pink .overlay-danger {
  background-color: rgba(243, 66, 53, 0.8);
}
.app.pink .text-primary {
  color: #3E50B4 !important;
}
.app.pink a.text-primary:focus, .app.pink a.text-primary:hover {
  color: #2F3E9E !important;
}
.app.pink .text-success {
  color: #4BAE4F !important;
}
.app.pink a.text-success:focus, .app.pink a.text-success:hover {
  color: #378D3B !important;
}
.app.pink .text-info {
  color: #00BBD3 !important;
}
.app.pink a.text-info:focus, .app.pink a.text-info:hover {
  color: #0096A6 !important;
}
.app.pink .text-warning {
  color: #FE9700 !important;
}
.app.pink a.text-warning:focus, .app.pink a.text-warning:hover {
  color: #F47B00 !important;
}
.app.pink .text-danger {
  color: #F34235 !important;
}
.app.pink a.text-danger:focus, .app.pink a.text-danger:hover {
  color: #D22E2E !important;
}
.app.pink .text-gray {
  color: #9D9D9D !important;
}
.app.pink a.text-gray:focus, .app.pink a.text-gray:hover {
  color: #606060 !important;
}
.app.pink .text-inverse {
  color: #292B2C !important;
}
.app.pink a.text-inverse:focus, .app.pink a.text-inverse:hover {
  color: #1C1E1F !important;
}
.app.pink .breadcrumb {
  padding: 0.5rem 1rem;
}
.app.pink .breadcrumb a {
  color: #3E50B4;
}
.app.pink .btn-primary {
  color: #fff;
  background-color: #3E50B4;
  border-color: #3E50B4;
}
.app.pink .btn-primary:hover, .app.pink .btn-primary:active, .app.pink .btn-primary.active {
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.pink .btn-primary:focus, .app.pink .btn-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.pink .show > .btn-primary.dropdown-toggle {
  background-color: #2F3E9E;
}
.app.pink .btn-success {
  color: #fff;
  background-color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.pink .btn-success:hover, .app.pink .btn-success:active, .app.pink .btn-success.active {
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.pink .btn-success:focus, .app.pink .btn-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.pink .show > .btn-success.dropdown-toggle {
  background-color: #378D3B;
}
.app.pink .btn-info {
  color: #fff;
  background-color: #00BBD3;
  border-color: #00BBD3;
}
.app.pink .btn-info:hover, .app.pink .btn-info:active, .app.pink .btn-info.active {
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.pink .btn-info:focus, .app.pink .btn-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.pink .show > .btn-info.dropdown-toggle {
  background-color: #0096A6;
}
.app.pink .btn-warning {
  color: #fff;
  background-color: #FE9700;
  border-color: #FE9700;
}
.app.pink .btn-warning:hover, .app.pink .btn-warning:active, .app.pink .btn-warning.active {
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.pink .btn-warning:focus, .app.pink .btn-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.pink .show > .btn-warning.dropdown-toggle {
  background-color: #F47B00;
}
.app.pink .btn-danger {
  color: #fff;
  background-color: #F34235;
  border-color: #F34235;
}
.app.pink .btn-danger:hover, .app.pink .btn-danger:active, .app.pink .btn-danger.active {
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.pink .btn-danger:focus, .app.pink .btn-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.pink .show > .btn-danger.dropdown-toggle {
  background-color: #D22E2E;
}
.app.pink .btn-gray {
  color: #fff;
  background-color: #9D9D9D;
}
.app.pink .btn-gray:hover, .app.pink .btn-gray:active, .app.pink .btn-gray.active {
  background-color: #606060;
}
.app.pink .btn-gray:focus, .app.pink .btn-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.pink .show > .btn-gray.dropdown-toggle {
  background-color: #606060;
}
.app.pink .btn-inverse {
  color: #fff;
  background-color: #292B2C;
}
.app.pink .btn-inverse:hover, .app.pink .btn-inverse:active, .app.pink .btn-inverse.active {
  background-color: #1C1E1F;
}
.app.pink .btn-inverse:focus, .app.pink .btn-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.pink .show > .btn-inverse.dropdown-toggle {
  background-color: #1C1E1F;
}
.app.pink .btn-rounded {
  border-radius: 5rem;
}
.app.pink .btn-outline-primary {
  color: #3E50B4;
  border-color: #3E50B4;
}
.app.pink .btn-outline-primary:hover, .app.pink .btn-outline-primary:active, .app.pink .btn-outline-primary.active {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.pink .btn-outline-primary:focus, .app.pink .btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
  box-shadow: 0 0 0 2px rgba(47, 62, 158, 0.5);
}
.app.pink .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #2F3E9E;
  border-color: #2F3E9E;
}
.app.pink .btn-outline-success {
  color: #4BAE4F;
  border-color: #4BAE4F;
}
.app.pink .btn-outline-success:hover, .app.pink .btn-outline-success:active, .app.pink .btn-outline-success.active {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.pink .btn-outline-success:focus, .app.pink .btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
  box-shadow: 0 0 0 2px rgba(55, 141, 59, 0.5);
}
.app.pink .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #378D3B;
  border-color: #378D3B;
}
.app.pink .btn-outline-info {
  color: #00BBD3;
  border-color: #00BBD3;
}
.app.pink .btn-outline-info:hover, .app.pink .btn-outline-info:active, .app.pink .btn-outline-info.active {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.pink .btn-outline-info:focus, .app.pink .btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
  box-shadow: 0 0 0 2px rgba(0, 150, 166, 0.5);
}
.app.pink .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #0096A6;
  border-color: #0096A6;
}
.app.pink .btn-outline-warning {
  color: #FE9700;
  border-color: #FE9700;
}
.app.pink .btn-outline-warning:hover, .app.pink .btn-outline-warning:active, .app.pink .btn-outline-warning.active {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.pink .btn-outline-warning:focus, .app.pink .btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
  box-shadow: 0 0 0 2px rgba(244, 123, 0, 0.5);
}
.app.pink .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #F47B00;
  border-color: #F47B00;
}
.app.pink .btn-outline-danger {
  color: #F34235;
  border-color: #F34235;
}
.app.pink .btn-outline-danger:hover, .app.pink .btn-outline-danger:active, .app.pink .btn-outline-danger.active {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.pink .btn-outline-danger:focus, .app.pink .btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
  box-shadow: 0 0 0 2px rgba(210, 46, 46, 0.5);
}
.app.pink .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #D22E2E;
  border-color: #D22E2E;
}
.app.pink .btn-outline-gray {
  color: #9D9D9D;
  border-color: #9D9D9D;
  background-color: transparent;
}
.app.pink .btn-outline-gray:hover, .app.pink .btn-outline-gray:active, .app.pink .btn-outline-gray.active {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.pink .btn-outline-gray:focus, .app.pink .btn-outline-gray.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(96, 96, 96, 0.5);
}
.app.pink .show > .btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.app.pink .btn-outline-inverse {
  color: #292B2C;
  border-color: #292B2C;
  background-color: transparent;
}
.app.pink .btn-outline-inverse:hover, .app.pink .btn-outline-inverse:active, .app.pink .btn-outline-inverse.active {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.pink .btn-outline-inverse:focus, .app.pink .btn-outline-inverse.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
  box-shadow: 0 0 0 2px rgba(28, 30, 31, 0.5);
}
.app.pink .show > .btn-outline-inverse.dropdown-toggle {
  color: #fff;
  background-color: #1C1E1F;
  border-color: #1C1E1F;
}
.app.pink .btn-link {
  border: 1px solid transparent;
}
.app.pink .badge {
  padding: 4px 6px;
}
.app.pink .badge-primary {
  background-color: #3E50B4;
}
.app.pink .badge-success {
  background-color: #4BAE4F;
}
.app.pink .badge-info {
  background-color: #00BBD3;
}
.app.pink .badge-warning {
  background-color: #FE9700;
}
.app.pink .badge-danger {
  background-color: #F34235;
}
.app.pink .badge-gray {
  background-color: #9D9D9D;
}
.app.pink .badge-white {
  background-color: #fff;
}
.app.pink .alert-primary {
  background-color: #b1c3ff;
  border-color: #97a9ff;
  color: #3E50B4;
}
.app.pink .alert-success {
  background-color: #beffc2;
  border-color: #8bee8f;
  color: #4BAE4F;
}
.app.pink .alert-info {
  background-color: #a6ffff;
  border-color: #40fbff;
  color: #00BBD3;
}
.app.pink .alert-warning {
  background-color: #ffffa6;
  border-color: #fff059;
  color: #FE9700;
}
.app.pink .alert-danger {
  background-color: #ffb5a8;
  border-color: #ff9b8e;
  color: #F34235;
}
.app.pink .alert-gray {
  background-color: #f3f3f3;
  border-color: #dddddd;
  color: #9D9D9D;
}
.app.pink .modal {
  z-index: 999999;
  padding-top: 5%;
}
.app.pink .modal-dialog .modal-content .modal-header {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  padding: 10px 15px;
}
.app.pink .modal-dialog .modal-content .modal-header.modal-primary {
  color: #fff;
  background-color: #3E50B4;
}
.app.pink .modal-dialog .modal-content .modal-header.modal-success {
  color: #fff;
  background-color: #4BAE4F;
}
.app.pink .modal-dialog .modal-content .modal-header.modal-info {
  color: #fff;
  background-color: #00BBD3;
}
.app.pink .modal-dialog .modal-content .modal-header.modal-warning {
  color: #fff;
  background-color: #FE9700;
}
.app.pink .modal-dialog .modal-content .modal-header.modal-danger {
  color: #fff;
  background-color: #F34235;
}
.app.pink .modal-dialog .modal-content .modal-header i {
  margin-right: 10px;
}
.app.pink .bg-primary {
  background-color: #3E50B4 !important;
}
.app.pink .bg-success {
  background-color: #4BAE4F !important;
}
.app.pink .bg-info {
  background-color: #00BBD3 !important;
}
.app.pink .bg-warning {
  background-color: #FE9700 !important;
}
.app.pink .bg-danger {
  background-color: #F34235 !important;
}
.app.pink .bg-gray {
  background-color: #9D9D9D !important;
}
.app.pink .bg-light {
  background-color: #f5f5f5 !important;
}
.app.pink .progress-xl {
  height: 2rem;
}
.app.pink .progress-lg {
  height: 1.5rem;
}
.app.pink .progress-md {
  height: 1rem;
}
.app.pink .progress-sm {
  height: 0.5rem;
}
.app.pink .progress-xs {
  height: 0.25rem;
}
.app.pink .nav-tabs.custom {
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
  width: 100%;
}
.app.pink .nav-tabs.custom .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border: none;
  cursor: default;
}
.app.pink .nav-tabs.custom .nav-item .nav-link.active {
  background-color: #fff;
  color: #606060;
}
.app.pink .nav-tabs.custom .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.pink .nav-tabs.custom.top {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.app.pink .nav-tabs.custom.bottom {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.app.pink .nav-tabs.custom.bottom .nav-item {
  margin-bottom: 0;
  margin-top: -1px;
}
.app.pink .nav-tabs.custom.bottom .nav-item .nav-link {
  border-radius: 0 0 0.3rem 0.3rem;
}
.app.pink .nav-tabs.custom.tabs-primary {
  background-color: rgba(62, 80, 180, 0.4);
  border: 1px solid #3E50B4;
}
.app.pink .nav-tabs.custom.tabs-primary .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.pink .nav-tabs.custom.tabs-primary .nav-item .nav-link.active {
  background-color: #3E50B4;
  color: #fff;
}
.app.pink .nav-tabs.custom.tabs-primary .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.pink .nav-tabs.custom.tabs-success {
  background-color: rgba(75, 174, 79, 0.4);
  border: 1px solid #4BAE4F;
}
.app.pink .nav-tabs.custom.tabs-success .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.pink .nav-tabs.custom.tabs-success .nav-item .nav-link.active {
  background-color: #4BAE4F;
  color: #fff;
}
.app.pink .nav-tabs.custom.tabs-success .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.pink .nav-tabs.custom.tabs-info {
  background-color: rgba(0, 187, 211, 0.4);
  border: 1px solid #00BBD3;
}
.app.pink .nav-tabs.custom.tabs-info .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.pink .nav-tabs.custom.tabs-info .nav-item .nav-link.active {
  background-color: #00BBD3;
  color: #fff;
}
.app.pink .nav-tabs.custom.tabs-info .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.pink .nav-tabs.custom.tabs-warning {
  background-color: rgba(254, 151, 0, 0.4);
  border: 1px solid #FE9700;
}
.app.pink .nav-tabs.custom.tabs-warning .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.pink .nav-tabs.custom.tabs-warning .nav-item .nav-link.active {
  background-color: #FE9700;
  color: #fff;
}
.app.pink .nav-tabs.custom.tabs-warning .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.pink .nav-tabs.custom.tabs-danger {
  background-color: rgba(243, 66, 53, 0.4);
  border: 1px solid #F34235;
}
.app.pink .nav-tabs.custom.tabs-danger .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.app.pink .nav-tabs.custom.tabs-danger .nav-item .nav-link.active {
  background-color: #F34235;
  color: #fff;
}
.app.pink .nav-tabs.custom.tabs-danger .nav-item .nav-link:not(.active):hover {
  color: #fff;
}
.app.pink .tab-content {
  position: relative;
  z-index: 1;
  border: 1px solid #9D9D9D;
  background: #fff;
}
.app.pink .tab-content .tab-pane {
  padding: 1rem;
}
.app.pink .tab-content.tab-content-primary {
  background-color: #3E50B4;
  color: #fff;
  border-color: #3E50B4;
}
.app.pink .tab-content.tab-content-success {
  background-color: #4BAE4F;
  color: #fff;
  border-color: #4BAE4F;
}
.app.pink .tab-content.tab-content-info {
  background-color: #00BBD3;
  color: #fff;
  border-color: #00BBD3;
}
.app.pink .tab-content.tab-content-warning {
  background-color: #FE9700;
  color: #fff;
  border-color: #FE9700;
}
.app.pink .tab-content.tab-content-danger {
  background-color: #F34235;
  color: #fff;
  border-color: #F34235;
}
.app.pink .tab-content.top {
  border-top: none;
}
.app.pink .tab-content.bottom {
  border-bottom: none;
}
.app.pink .vertical-tabs .nav {
  padding-right: 0;
  overflow: hidden;
  background-color: #9D9D9D;
  border: 1px solid #9D9D9D;
}
.app.pink .vertical-tabs .nav.left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-right: none;
  margin-right: -1px;
  z-index: 2;
}
.app.pink .vertical-tabs .nav.right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-left: none;
  margin-left: -1px;
  z-index: 2;
}
.app.pink .vertical-tabs .nav .nav-item .nav-link {
  color: #606060;
  background-color: transparent;
  border-radius: 0;
  cursor: default;
}
.app.pink .vertical-tabs .nav .nav-item .nav-link.active {
  background-color: #fff;
}
.app.pink .vertical-tabs .nav .nav-item .nav-link:not(.active):hover {
  background-color: transparent;
  color: #606060;
  cursor: pointer;
}
.app.pink .vertical-tabs .nav .nav-item .nav-link.disabled {
  color: rgba(96, 96, 96, 0.6);
  cursor: not-allowed !important;
}
.app.pink .list-group-item-primary {
  color: #3E50B4;
  background-color: #b1c3ff;
}
.app.pink .list-group-item-success {
  color: #4BAE4F;
  background-color: #beffc2;
}
.app.pink .list-group-item-info {
  color: #00BBD3;
  background-color: #a6ffff;
}
.app.pink .list-group-item-warning {
  color: #FE9700;
  background-color: #ffffa6;
}
.app.pink .list-group-item-danger {
  color: #F34235;
  background-color: #ffb5a8;
}
.app.pink .no-caret.dropdown-toggle::after {
  content: none;
}
.app.pink .dropdown-item.active, .app.pink .dropdown-item:active {
  background-color: #ccc;
}
.app.pink .dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.app.pink .dropdown.show .dropdown-menu {
  max-height: 300px;
  opacity: 1;
  pointer-events: auto;
}
.app.pink .dropdown.open .dropdown-menu {
  opacity: 1;
  pointer-events: auto;
}
.app.pink .dropdown-menu-right {
  right: 0;
  left: auto;
}
.app.pink .form-group label {
  margin-bottom: 0.2rem;
}
.app.pink .form-control-rounded {
  border-radius: 16px;
}
.app.pink .form-control:focus {
  border-color: rgba(232, 29, 98, 0.6);
}
.app.pink input[type=color].form-control {
  padding: 0;
}
.app.pink .has-success .form-control {
  border-color: #4BAE4F;
}
.app.pink .has-success .form-control:focus {
  border-color: #378D3B;
}
.app.pink .has-success .form-control-feedback, .app.pink .has-success .form-control-label, .app.pink .has-success .form-check-label, .app.pink .has-success .form-check-inline, .app.pink .has-success .custom-control {
  color: #4BAE4F;
}
.app.pink .has-warning .form-control {
  border-color: #FE9700;
}
.app.pink .has-warning .form-control:focus {
  border-color: #F47B00;
}
.app.pink .has-warning .form-control-feedback, .app.pink .has-warning .form-control-label, .app.pink .has-warning .form-check-label, .app.pink .has-warning .form-check-inline, .app.pink .has-warning .custom-control {
  color: #FE9700;
}
.app.pink .has-danger .form-control {
  border-color: #F34235;
}
.app.pink .has-danger .form-control:focus {
  border-color: #D22E2E;
}
.app.pink .has-danger .form-control-feedback, .app.pink .has-danger .form-control-label, .app.pink .has-danger .form-check-label, .app.pink .has-danger .form-check-inline, .app.pink .has-danger .custom-control {
  color: #F34235;
}
.app.pink .has-success .input-group-prepend,
.app.pink .has-success .input-group-append,
.app.pink .has-success .input-group-prepend .input-group-text,
.app.pink .has-success .input-group-append .input-group-text {
  color: #4BAE4F;
  border-color: #4BAE4F;
  background-color: #eaf6ea;
}
.app.pink .has-danger .input-group-prepend,
.app.pink .has-danger .input-group-append,
.app.pink .has-danger .input-group-prepend .input-group-text,
.app.pink .has-danger .input-group-append .input-group-text {
  color: #F34235;
  border-color: #F34235;
  background-color: #fdf7f7;
}
.app.pink .form-control.validation-field.ng-touched {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.625rem;
  background-size: 1.25rem 1.25rem;
}
.app.pink .form-control.validation-field.ng-touched.ng-invalid {
  border-color: #F34235;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23F34235' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
}
.app.pink .form-control.validation-field.ng-touched.ng-valid {
  border-color: #4BAE4F;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%234BAE4F' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}
.app.pink .custom-control-label::before {
  width: 14px;
  height: 14px;
  top: 0.3rem;
}
.app.pink .custom-control-label::after {
  width: 14px;
  height: 14px;
  top: 0.3rem;
  background-size: 60% 60%;
}
.app.pink .custom-checkbox.checkbox-circle .custom-control-label::before,
.app.pink .custom-checkbox.checkbox-circle .custom-control-label::after {
  border-radius: 50%;
}
.app.pink .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.pink .custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.pink .custom-checkbox .custom-control-input.checkbox-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.pink .custom-checkbox .custom-control-input.checkbox-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.pink .custom-checkbox .custom-control-input.checkbox-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.pink .custom-checkbox .custom-control-input.checkbox-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.pink .custom-checkbox .custom-control-input.checkbox-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.pink .custom-checkbox .custom-control-input.checkbox-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.pink .custom-checkbox .custom-control-input.checkbox-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.pink .custom-checkbox .custom-control-input.checkbox-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.pink .custom-checkbox .custom-control-input.checkbox-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.pink .custom-checkbox .custom-control-input.checkbox-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.pink .custom-checkbox .custom-control-input.checkbox-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.pink .custom-checkbox .custom-control-input.checkbox-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.pink .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(157, 157, 157, 0.7);
}
.app.pink .custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgba(157, 157, 157, 0.7);
}
.app.pink .custom-radio .custom-control-input.radio-primary:checked ~ .custom-control-label::before {
  background: #3E50B4;
}
.app.pink .custom-radio .custom-control-input.radio-primary:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #3E50B4;
}
.app.pink .custom-radio .custom-control-input.radio-success:checked ~ .custom-control-label::before {
  background: #4BAE4F;
}
.app.pink .custom-radio .custom-control-input.radio-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #4BAE4F;
}
.app.pink .custom-radio .custom-control-input.radio-info:checked ~ .custom-control-label::before {
  background: #00BBD3;
}
.app.pink .custom-radio .custom-control-input.radio-info:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #00BBD3;
}
.app.pink .custom-radio .custom-control-input.radio-warning:checked ~ .custom-control-label::before {
  background: #FE9700;
}
.app.pink .custom-radio .custom-control-input.radio-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FE9700;
}
.app.pink .custom-radio .custom-control-input.radio-danger:checked ~ .custom-control-label::before {
  background: #F34235;
}
.app.pink .custom-radio .custom-control-input.radio-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F34235;
}
.app.pink .custom-radio .custom-control-input.radio-dark-gray:checked ~ .custom-control-label::before {
  background: #606060;
}
.app.pink .custom-radio .custom-control-input.radio-dark-gray:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #606060;
}
.app.pink .table-success,
.app.pink .table-success > th,
.app.pink .table-success > td {
  background-color: #4BAE4F;
}
.app.pink .table-hover .table-success:hover {
  background-color: #439c47;
}
.app.pink .table-hover .table-success:hover > td,
.app.pink .table-hover .table-success:hover > th {
  background-color: #439c47;
}
.app.pink .table-info,
.app.pink .table-info > th,
.app.pink .table-info > td {
  background-color: #00BBD3;
}
.app.pink .table-hover .table-info:hover {
  background-color: #00a4ba;
}
.app.pink .table-hover .table-info:hover > td,
.app.pink .table-hover .table-info:hover > th {
  background-color: #00a4ba;
}
.app.pink .table-warning,
.app.pink .table-warning > th,
.app.pink .table-warning > td {
  background-color: #FE9700;
}
.app.pink .table-hover .table-warning:hover {
  background-color: #e58800;
}
.app.pink .table-hover .table-warning:hover > td,
.app.pink .table-hover .table-warning:hover > th {
  background-color: #e58800;
}
.app.pink .table-danger,
.app.pink .table-danger > th,
.app.pink .table-danger > td {
  background-color: #F34235;
}
.app.pink .table-hover .table-danger:hover {
  background-color: #f22b1d;
}
.app.pink .table-hover .table-danger:hover > td,
.app.pink .table-hover .table-danger:hover > th {
  background-color: #f22b1d;
}
.app.pink .rounded-0 {
  border-radius: 0 !important;
}
.app.pink .popover.bs-popover-top .arrow,
.app.pink .popover.bs-popover-auto[x-placement^=top] .arrow,
.app.pink .popover.bs-popover-auto[x-placement^=bottom] .arrow,
.app.pink .popover.bs-popover-bottom .arrow {
  left: 50%;
}
.app.pink .popover.bs-popover-auto[x-placement^=right] .arrow,
.app.pink .popover.bs-popover-right .arrow,
.app.pink .popover.bs-popover-auto[x-placement^=left] .arrow,
.app.pink .popover.bs-popover-left .arrow {
  top: 50%;
}
.app.pink .popover.bs-popover-bottom .arrow::after,
.app.pink .popover.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #f7f7f7;
}
.app.pink .top-navbar {
  background-color: #C1175A;
  color: #F7BACF;
}
.app.pink .top-navbar .app-search {
  border-bottom: 1px solid #F7BACF;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.pink .top-navbar .app-search .form-control {
  color: #F7BACF;
}
.app.pink .top-navbar .app-search input[type=text]::-webkit-input-placeholder {
  color: rgba(247, 186, 207, 0.8);
}
.app.pink .top-navbar .app-search input[type=text]::-moz-placeholder {
  color: rgba(247, 186, 207, 0.8);
}
.app.pink .top-navbar .app-search input[type=text]:-moz-placeholder {
  color: rgba(247, 186, 207, 0.8);
}
.app.pink .top-navbar .app-search input[type=text]:-ms-input-placeholder {
  color: rgba(247, 186, 207, 0.8);
}
.app.pink .top-navbar .app-search textarea::-webkit-input-placeholder {
  color: rgba(247, 186, 207, 0.8);
}
.app.pink .top-navbar .app-search textarea::-moz-placeholder {
  color: rgba(247, 186, 207, 0.8);
}
.app.pink .top-navbar .app-search textarea:-moz-placeholder {
  color: rgba(247, 186, 207, 0.8);
}
.app.pink .top-navbar .app-search textarea:-ms-input-placeholder {
  color: rgba(247, 186, 207, 0.8);
}
.app.pink .top-navbar .app-search i {
  color: #F7BACF;
}
.app.pink .top-navbar a {
  color: #F7BACF;
}
.app.pink .logo {
  color: #fff !important;
}
.app.pink .logo .ng {
  color: #fff;
}
.app.pink .menu-icon .line {
  background-color: #F7BACF;
}
.app.pink #info-content-icon .triangle {
  border-color: #F7BACF transparent transparent transparent;
}
.app.pink #info-content-icon.show {
  background: #E81D62;
  box-shadow: 0px 0px 0px 8px #E81D62, 0px -16px 0px 8px #E81D62;
}
.app.pink #info-content-icon.show .triangle {
  border-color: #F7BACF transparent transparent transparent;
}
.app.pink .menu-wrapper .card a {
  color: #F7BACF;
  border-bottom: 1px solid rgba(193, 23, 90, 0.5);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1);
}
.app.pink .menu-wrapper .card a:hover {
  color: #fff;
  background-color: #C1175A !important;
}
.app.pink .menu-wrapper .card a.active-link {
  background-color: #FE3F80 !important;
  color: #fff;
}
.app.pink .menu-wrapper .card .card a {
  padding-left: 15px;
  background-color: rgba(0, 0, 0, 0.3);
}
.app.pink .menu-wrapper .card .card .card a {
  padding-left: 30px;
  background-color: rgba(0, 0, 0, 0.4);
}
.app.pink .menu-wrapper .card .card .card .card a {
  padding-left: 45px;
  background-color: rgba(0, 0, 0, 0.5);
}
.app.pink .menu-wrapper .card .card .card .card .card a {
  padding-left: 60px;
  background-color: rgba(0, 0, 0, 0.6);
}
.app.pink .menu-wrapper .card .card .card .card .card .card a {
  padding-left: 75px;
  background-color: rgba(0, 0, 0, 0.7);
}
.app.pink .menu-wrapper .card .card .card .card .card .card .card a {
  padding-left: 90px;
  background-color: rgba(0, 0, 0, 0.8);
}
.app.pink .menu-wrapper .card .card .card .card .card .card .card .card a {
  padding-left: 105px;
  background-color: rgba(0, 0, 0, 0.9);
}
.app.pink .menu-wrapper .card .card .card .card .card .card .card .card .card a {
  padding-left: 120px;
  background-color: black;
}
.app.pink .menu-wrapper .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 135px;
  background-color: black;
}
.app.pink .menu-wrapper .card .card .card .card .card .card .card .card .card .card .card a {
  padding-left: 150px;
  background-color: black;
}
.app.pink .wrapper, .app.pink .wrapper-inner {
  background-color: #f5f5f5;
  color: #565656;
}
.app.pink #info-content {
  background-color: #E81D62;
}
.app.pink #info-content a.close {
  color: #F7BACF;
}
.app.pink #info-content .info {
  color: #F7BACF;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.pink #info-content .info .form-control {
  background-color: #E81D62;
  border-color: rgba(247, 186, 207, 0.4);
  color: #F7BACF;
}
.app.pink #info-content .info input[type=text]::-webkit-input-placeholder {
  color: rgba(247, 186, 207, 0.7);
}
.app.pink #info-content .info input[type=text]::-moz-placeholder {
  color: rgba(247, 186, 207, 0.7);
}
.app.pink #info-content .info input[type=text]:-moz-placeholder {
  color: rgba(247, 186, 207, 0.7);
}
.app.pink #info-content .info input[type=text]:-ms-input-placeholder {
  color: rgba(247, 186, 207, 0.7);
}
.app.pink #info-content .info textarea::-webkit-input-placeholder {
  color: rgba(247, 186, 207, 0.7);
}
.app.pink #info-content .info textarea::-moz-placeholder {
  color: rgba(247, 186, 207, 0.7);
}
.app.pink #info-content .info textarea:-moz-placeholder {
  color: rgba(247, 186, 207, 0.7);
}
.app.pink #info-content .info textarea:-ms-input-placeholder {
  color: rgba(247, 186, 207, 0.7);
}
.app.pink #info-content .left-border {
  border-left: 1px solid rgba(247, 186, 207, 0.3);
}
.app.pink #messagesDropDown .dropdown-menu .nav-link.active {
  border-bottom: 4px solid #C1175A;
}
.app.pink .dropdown-menu .user-menu .user-info {
  background-color: #C1175A;
  color: #F7BACF;
}
.app.pink .favorites-container {
  border-left: 1px solid rgba(247, 186, 207, 0.4);
  border-right: 1px solid rgba(247, 186, 207, 0.4);
}
.app.pink #favorites .dropdown .btn-block {
  background-color: #C1175A;
  color: #F7BACF;
}
.app.pink .side-chat .media {
  color: #F7BACF;
  background-color: #E81D62;
  border-bottom: 1px solid #f5f5f5;
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
  /* Chrome/Opera/Safari */
  /* Firefox 19+ */
  /* Firefox 18- */
  /* IE 10+ */
}
.app.pink .side-chat .media input[type=text]::-webkit-input-placeholder {
  color: rgba(247, 186, 207, 0.8);
}
.app.pink .side-chat .media input[type=text]::-moz-placeholder {
  color: rgba(247, 186, 207, 0.8);
}
.app.pink .side-chat .media input[type=text]:-moz-placeholder {
  color: rgba(247, 186, 207, 0.8);
}
.app.pink .side-chat .media input[type=text]:-ms-input-placeholder {
  color: rgba(247, 186, 207, 0.8);
}
.app.pink .side-chat .media textarea::-webkit-input-placeholder {
  color: rgba(247, 186, 207, 0.8);
}
.app.pink .side-chat .media textarea::-moz-placeholder {
  color: rgba(247, 186, 207, 0.8);
}
.app.pink .side-chat .media textarea:-moz-placeholder {
  color: rgba(247, 186, 207, 0.8);
}
.app.pink .side-chat .media textarea:-ms-input-placeholder {
  color: rgba(247, 186, 207, 0.8);
}
.app.pink .side-chat .chat-window {
  color: #F7BACF;
  background-color: #E81D62;
}
.app.pink .side-chat .chat-window .header {
  border-bottom: 1px solid #F7BACF;
}
.app.pink .sidebar-outer {
  background-color: #E81D62;
}
.app.pink .sidebar-outer .logo {
  background-color: #C1175A;
}
.app.pink .vertical-menu-tooltip-place .tooltip {
  opacity: 1;
}
.app.pink .vertical-menu-tooltip-place .tooltip.bs-tooltip-right .arrow::before, .app.pink .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #E81D62;
}
.app.pink .vertical-menu-tooltip-place .tooltip.bs-tooltip-bottom .arrow::before, .app.pink .vertical-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #E81D62;
}
.app.pink .vertical-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #E81D62;
}
.app.pink .horizontal-menu-outer {
  background-color: #E81D62;
}
.app.pink #navigation .menu li {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.app.pink #navigation .menu li a {
  color: #F7BACF;
}
.app.pink #navigation .menu li a:hover {
  color: #fff;
  background-color: #C1175A !important;
}
.app.pink #navigation .menu li a.active-link {
  background-color: #FE3F80 !important;
  color: #fff;
}
.app.pink #navigation .menu li:first-child {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.app.pink #navigation .menu .sub-menu {
  background-color: #E81D62;
}
.app.pink #navigation ul.menu ul a,
.app.pink #navigation .menu ul ul a {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
}
.app.pink .horizontal-menu-tooltip-place .tooltip {
  opacity: 1;
  z-index: 999999;
}
.app.pink .horizontal-menu-tooltip-place .tooltip.bs-tooltip-top .arrow::before, .app.pink .horizontal-menu-tooltip-place .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #000;
}
.app.pink .horizontal-menu-tooltip-place .tooltip-inner {
  color: #fff;
  background-color: #000 !important;
}
.app.pink .app-footer {
  background-color: #C1175A;
  color: #F7BACF;
}
.app.pink .login-container .card, .app.pink .register-container .card, .app.pink .error-container .card {
  background-color: #FE3F80;
  color: #F5F5F5;
}
.app.pink .login-container .card .card-header, .app.pink .register-container .card .card-header, .app.pink .error-container .card .card-header {
  background-color: #E81D62;
}
.app.pink .login-container .card .btn, .app.pink .register-container .card .btn, .app.pink .error-container .card .btn {
  background-color: #E81D62;
  color: #F5F5F5;
}
.app.pink .box-shadow {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(232, 29, 98, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(232, 29, 98, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(232, 29, 98, 0.3);
}
.app.pink .widget-controls a {
  color: #a6a6a6;
}
.app.pink .widget-controls a:hover {
  color: rgba(166, 166, 166, 0.7);
}
.app.horizontal-menu .main-content {
  min-height: calc(100vh - (46px + 38px + 28px));
}
.app.horizontal-menu .navbar.app-header .top-navbar .logo {
  width: 130px;
}
.app.horizontal-menu .side-chat {
  top: 85px;
}
.app.horizontal-menu .side-chat .chat-list {
  padding-bottom: 116px;
}
.app.horizontal-menu .side-chat .chat-window .chat-talk-list {
  height: calc(100vh - (46px + 30px + 46px + 38px));
}
.app.horizontal-menu.compact .main-content {
  min-height: calc(100vh - (46px + 52px + 28px));
}
.app.horizontal-menu.compact .side-chat {
  top: 99px;
}
.app.horizontal-menu.compact .side-chat .chat-list {
  padding-bottom: 130px;
}
.app.horizontal-menu.compact .side-chat .chat-window .chat-talk-list {
  height: calc(100vh - (46px + 30px + 46px + 52px));
}
.app.horizontal-menu.mini .main-content {
  min-height: calc(100vh - (46px + 46px + 28px));
}
.app.horizontal-menu.mini .navbar.app-header .top-navbar .logo {
  width: 130px;
}
.app.horizontal-menu.mini .navbar.app-header .top-navbar .logo .start {
  display: inline;
}
.app.horizontal-menu.mini .side-chat {
  top: 93px;
}
.app.horizontal-menu.mini .side-chat .chat-list {
  padding-bottom: 124px;
}
.app.horizontal-menu.mini .side-chat .chat-window .chat-talk-list {
  height: calc(100vh - (46px + 30px + 46px + 46px));
}
.app.horizontal-menu.mini.menu-hide .navbar.app-header .top-navbar .logo .start {
  display: none;
}
.app.navbar-fixed .wrapper-inner {
  padding-top: 46px;
}
.app.navbar-fixed.horizontal-menu .wrapper-inner {
  padding-top: 84px;
}
.app.navbar-fixed.horizontal-menu.menu-hide .wrapper-inner {
  padding-top: 46px;
}
.app.navbar-fixed.horizontal-menu.compact .wrapper-inner {
  padding-top: 98px;
}
.app.navbar-fixed.horizontal-menu.compact.menu-hide .wrapper-inner {
  padding-top: 46px;
}
.app.navbar-fixed.horizontal-menu.mini .wrapper-inner {
  padding-top: 92px;
}
.app.navbar-fixed.horizontal-menu.mini.menu-hide .wrapper-inner {
  padding-top: 46px;
}
.app.sidebar-fixed .main-content {
  padding-left: 275px;
}
.app.sidebar-fixed .app-footer {
  padding-left: 275px;
  z-index: 1;
}
.app.sidebar-fixed .sidebar-outer {
  position: fixed;
  top: 0;
  z-index: 999;
  padding-bottom: 46px;
}
.app.sidebar-fixed.compact .main-content, .app.sidebar-fixed.compact .app-footer {
  padding-left: 185px;
}
.app.sidebar-fixed.mini .main-content, .app.sidebar-fixed.mini .app-footer {
  padding-left: 95px;
}
.app.sidebar-fixed.menu-hide .main-content, .app.sidebar-fixed.menu-hide .app-footer, .app.sidebar-fixed.horizontal-menu .main-content, .app.sidebar-fixed.horizontal-menu .app-footer {
  padding-left: 25px;
}
.app.compact .navbar.app-header .top-navbar .logo {
  width: 130px;
}
.app.compact .sidebar-outer {
  width: 160px;
}
.app.compact .sidebar-outer .logo {
  width: 160px;
  padding: 0 14px !important;
}
.app.compact .sidebar-outer a.menu-item-link {
  padding-left: 0 !important;
  text-align: center;
}
.app.compact .sidebar-outer a.menu-item-link i {
  display: block;
  font-size: 28px;
}
.app.compact .sidebar-outer a.menu-item-link b {
  position: absolute;
  margin: 0;
  right: 8px;
  top: 28px;
}
.app.compact .horizontal-menu-wrapper {
  height: 52px;
}
.app.compact #navigation .menu li a {
  text-align: center;
}
.app.compact #navigation .menu li a i {
  margin: 0;
  display: block;
}
.app.compact #navigation ul.menu ul a,
.app.compact #navigation .menu ul ul a {
  text-align: left;
}
.app.compact #navigation ul.menu ul a i,
.app.compact #navigation .menu ul ul a i {
  margin-right: 10px;
  display: inline;
}
.app.mini .navbar.app-header .top-navbar .logo {
  width: 55px;
}
.app.mini .navbar.app-header .top-navbar .logo .start {
  display: none;
}
.app.mini .sidebar-outer {
  width: 70px;
}
.app.mini .sidebar-outer .logo {
  width: 70px;
}
.app.mini .sidebar-outer .logo .start {
  display: none;
}
.app.mini .sidebar-outer a.menu-item-link {
  padding-left: 0 !important;
  text-align: center;
}
.app.mini .sidebar-outer a.menu-item-link span.menu-title {
  display: none;
}
.app.mini .sidebar-outer a.menu-item-link i {
  display: block;
  font-size: 28px;
  padding: 4px 12px;
}
.app.mini .sidebar-outer a.menu-item-link b {
  position: absolute;
  margin: 0;
  right: 8px;
  top: 20px;
}
.app.mini .horizontal-menu-wrapper {
  height: 46px;
}
.app.mini #navigation .menu li a {
  width: 60px;
  text-align: center;
}
.app.mini #navigation .menu li a span.menu-title {
  display: none;
}
.app.mini #navigation .menu li a i {
  margin: 0;
  display: inline;
  font-size: 28px;
}
.app.mini #navigation .menu li.menu-item-has-children > a:after {
  content: none;
}
.app.mini #navigation ul.menu ul a,
.app.mini #navigation .menu ul ul a {
  width: 60px;
  min-width: 60px;
  text-align: center;
}
.app.mini #navigation ul.menu ul a i,
.app.mini #navigation .menu ul ul a i {
  margin: 0;
  display: inline;
}
.app.menu-hide .navbar.app-header .top-navbar .logo {
  width: 55px !important;
}
.app.menu-hide .navbar.app-header .top-navbar .logo .start {
  display: none;
}
.app.menu-hide .sidebar-outer {
  width: 0;
}
.app.menu-hide .sidebar-outer .menu-wrapper {
  display: none;
}
.app.menu-hide .sidebar-outer .logo {
  display: none !important;
}
.app.menu-hide .horizontal-menu-outer {
  height: 0;
  display: none;
}

.transition {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.text-white {
  color: #fff;
}

.bg-white {
  background-color: #fff;
}

.bg-transparent {
  background-color: transparent;
}

.box-shadow-none {
  box-shadow: none !important;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.w-50p {
  width: 50px;
}

.w-100p {
  width: 100px;
}

.h-100p {
  height: 100px;
}

.w-280p {
  width: 280px;
}

.h-300p {
  height: 300px;
}

.h-auto {
  height: auto;
}

.pt-2p {
  padding-top: 2px;
}

.card-block-scrolling {
  overflow-y: auto;
  border-top: 1px solid #ccc;
  padding-top: 15px !important;
}

.card.fullscreened {
  z-index: 999998 !important;
}

.fa-select {
  font-family: "FontAwesome", "Helvetica";
}

@media (max-width: 768px) {
  .horizontal-menu .logo {
    width: 55px !important;
  }
  .horizontal-menu .logo .start {
    display: none !important;
  }
}
@media (max-width: 575px) {
  .card-columns .card {
    margin-bottom: 20px;
  }
}
@-webkit-keyframes trans1 {
  from {
    transform: translate3d(53px, 0, 0);
  }
  to {
    transform: translate3d(-250px, 0, 0);
  }
}
@-moz-keyframes trans1 {
  from {
    transform: translate3d(53px, 0, 0);
  }
  to {
    transform: translate3d(-250px, 0, 0);
  }
}
@keyframes trans1 {
  from {
    transform: translate3d(53px, 0, 0);
  }
  to {
    transform: translate3d(-250px, 0, 0);
  }
}
@-webkit-keyframes trans2 {
  from {
    transform: translate3d(-160px, 0, 0);
  }
  to {
    transform: translate3d(53px, 0, 0);
  }
}
@-moz-keyframes trans2 {
  from {
    transform: translate3d(-160px, 0, 0);
  }
  to {
    transform: translate3d(53px, 0, 0);
  }
}
@keyframes trans2 {
  from {
    transform: translate3d(-160px, 0, 0);
  }
  to {
    transform: translate3d(53px, 0, 0);
  }
}
@-webkit-keyframes trans3 {
  from {
    transform: translate3d(53px, 0, 0);
  }
  to {
    transform: translate3d(-220px, 0, 0);
  }
}
@-moz-keyframes trans3 {
  from {
    transform: translate3d(53px, 0, 0);
  }
  to {
    transform: translate3d(-220px, 0, 0);
  }
}
@keyframes trans3 {
  from {
    transform: translate3d(53px, 0, 0);
  }
  to {
    transform: translate3d(-220px, 0, 0);
  }
}
.loader-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #fff;
  visibility: visible;
  opacity: 1;
  -webkit-transition: visibility 0.5s, opacity 0.3s linear;
  -moz-transition: visibility 0.5s, opacity 0.3s linear;
  transition: visibility 0.5s, opacity 0.3s linear;
}
.loader-outer.hide {
  visibility: hidden;
  opacity: 0;
}
.loader-outer .loader-container {
  height: 100%;
  width: 100%;
  display: table;
}
.loader-outer .loader-container .loader-inner {
  vertical-align: middle;
  height: 100%;
  display: table-cell;
}
.loader-outer .loader-container .loader-inner .loader {
  width: 80px;
  margin: auto;
  position: relative;
  height: 60px;
}
.loader-outer .loader-container .loader-inner .loader .mask {
  position: absolute;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.loader-outer .loader-container .loader-inner .loader .mask .plane {
  background: #DD1B16;
  width: 400%;
  height: 100%;
  position: absolute;
}
.loader-outer .loader-container .loader-inner .loader #top {
  width: 53px;
  height: 15px;
  left: 20px;
  -webkit-transform: skew(-15deg, 0);
  -moz-transform: skew(-15deg, 0);
  transform: skew(-15deg, 0);
}
.loader-outer .loader-container .loader-inner .loader #top .plane {
  -webkit-animation: trans1 1.3s ease-in infinite 0s backwards;
  -moz-animation: trans1 1.3s ease-in infinite 0s backwards;
  animation: trans1 1.3s ease-in infinite 0s backwards;
}
.loader-outer .loader-container .loader-inner .loader #middle {
  width: 33px;
  height: 15px;
  left: 20px;
  top: 15px;
  -webkit-transform: skew(-15deg, 40deg);
  -moz-transform: skew(-15deg, 40deg);
  transform: skew(-15deg, 40deg);
}
.loader-outer .loader-container .loader-inner .loader #middle .plane {
  transform: translate3d(0px, 0, 0);
  background: #DD1B16;
  -webkit-animation: trans2 1.3s linear infinite 0.3s backwards;
  -moz-animation: trans2 1.3s linear infinite 0.3s backwards;
  animation: trans2 1.3s linear infinite 0.3s backwards;
}
.loader-outer .loader-container .loader-inner .loader #bottom {
  width: 53px;
  height: 15px;
  top: 30px;
  -webkit-transform: skew(-15deg, 0);
  -moz-transform: skew(-15deg, 0);
  transform: skew(-15deg, 0);
}
.loader-outer .loader-container .loader-inner .loader #bottom .plane {
  -webkit-animation: trans3 1.3s ease-out infinite 0.7s backwards;
  -moz-animation: trans3 1.3s ease-out infinite 0.7s backwards;
  animation: trans3 1.3s ease-out infinite 0.7s backwards;
}
.loader-outer .loader-container .loader-inner .loader p.loader-text {
  color: #000;
  position: absolute;
  top: 42px;
  font-family: "MoonHouse";
  font-size: 16px;
}
.loader-outer .loader-container .loader-inner .loader p.loader-text span {
  color: #DD1B16;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}
::-webkit-scrollbar-thumb:active {
  background: #888888;
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

::placeholder {
  color: #808080;
  font-style: italic;
}

.imp {
  font-size: 15px;
  color: red;
}

.bttn {
  color: #fff;
  font-size: 27px;
  position: fixed;
  bottom: 40px;
  right: 20px;
  background: #666666;
  border-radius: 1px;
  border: none;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.bttn:hover {
  background: #333333;
  color: #fff;
}

.fa_edit {
  background-color: #2eb847;
  padding: 5px 7px;
  border-radius: 1px;
  color: #fff;
  margin-right: 5px;
  cursor: pointer;
}

.fa_trash {
  background-color: red;
  padding: 5px 7px;
  border-radius: 1px;
  color: #fff;
  margin-right: 5px;
  cursor: pointer;
}

.fa_eye {
  background-color: #293241;
  padding: 5px 7px;
  border-radius: 1px;
  color: #fff;
  margin-right: 5px;
  cursor: pointer;
}

.fa_address {
  background-color: #00008B;
  padding: 5px 7px;
  border-radius: 1px;
  color: #fff;
  margin-right: 5px;
  cursor: pointer;
}

.fa_plus {
  background-color: #34495E;
  padding: 5px 7px;
  border-radius: 1px;
  color: #fff;
  margin-right: 5px;
  cursor: pointer;
}

.fa_plane {
  background-color: #199B77;
  padding: 5px 7px;
  border-radius: 1px;
  color: #fff;
  margin-right: 5px;
  cursor: pointer;
}

.badge {
  cursor: pointer !important;
  width: 100px !important;
  height: auto !important;
  padding: 8px 0px !important;
}

/* --- Animated Buttons --- */
.movebtn {
  background-color: transparent;
  display: inline-block;
  width: 100px;
  background-image: none;
  padding: 8px 10px;
  margin-bottom: 20px;
  border-radius: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.5, 1.65, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.5, 1.65, 0.37, 0.66);
}

.movebtnre {
  border: 2px solid #ff5501;
  box-shadow: inset 0 0 0 0 #ff5501;
  color: #ff5501;
  cursor: pointer;
}

.movebtnsu {
  border: 2px solid #1e439b;
  box-shadow: inset 0 0 0 0 #1e439b;
  color: #1e439b;
  cursor: pointer;
}

.movebtdng {
  border: 2px solid #dd1b16;
  box-shadow: inset 0 0 0 0 #dd1b16;
  color: #dd1b16;
  cursor: pointer;
}

.movebtdng:focus,
.movebtdng:hover,
.movebtdng:active {
  background-color: transparent;
  color: #FFF;
  border-color: #dd1b16;
  box-shadow: inset 96px 0 0 0 #dd1b16;
}

.movebtnre:focus,
.movebtnre:hover,
.movebtnre:active {
  background-color: transparent;
  color: #FFF;
  border-color: #ff5501;
  box-shadow: inset 96px 0 0 0 #ff5501;
}

.movebtnre:focus,
.movebtnre:hover,
.movebtnre:active {
  background-color: transparent;
  color: #FFF;
  border-color: #ff5501;
  box-shadow: inset 96px 0 0 0 #ff5501;
}

.movebtnsu:focus,
.movebtnsu:hover,
.movebtnsu:active {
  background-color: transparent;
  color: #FFF;
  border-color: #1e439b;
  box-shadow: inset 96px 0 0 0 #1e439b;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;
  border: 15px solid rgb(33, 150, 243);
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}

.bg-custom {
  background-color: #006496;
  color: #fff;
}

/* Animation */
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}